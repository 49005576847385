/* eslint-disable */
import React from 'react';

import { Nav, NavItem } from 'reactstrap';
import { Link, NavLink } from 'react-router-dom';
import {
  Container,
  SidebarContainer,
  SidebarItensContainer,
} from './Sidebar.styled';
import LogoSeedSide from '../../assets/logo-seed.png';
import { SidebarData } from './Sidebar.data';
import { Sidebar as SidebarPrime } from 'primereact/sidebar';
import './animation.css';

import { useAuth } from '../../hooks/auth';
import { ThemeContext } from 'styled-components';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Sidebar = ({ children, showSidebar, setSidebar }: any) => {
  const [sidebarScroll, setSidebarScroll] = React.useState({
    scrollTop: 0,
    scrollTopMax: 1,
  });

  const [scrollBar, setScrollBar] = React.useState(true);

  const { user } = useAuth();
  const { colors } = React.useContext(ThemeContext);

  const activeClassName =
    'grid grid-cols-3 text-decoration-none bg-emerald-400 my-0 py-3 border-l-4 border-white hover:border-white duration-150 justify-self-center w-full';

  const desactiveClassName =
    'grid grid-cols-3 text-decoration-none my-0 py-3 border-l-4 border-transparent hover:border-white hover:bg-emerald-400 duration-150 justify-self-center w-full';

  const handlerScroll = React.useCallback((e: any) => {
    setSidebarScroll({
      scrollTop: e.target.scrollTop,
      scrollTopMax: e.target.scrollHeight - e.target.offsetHeight,
    });
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <SidebarContainer
        className="sidebar-desk"
        style={{ backgroundColor: colors.primary }}
      >
        <Nav vertical>
          <img
            src={LogoSeedSide}
            alt="Secretaria da Educação Do Amapá"
            style={{
              objectFit: 'contain',
              padding: '5px',
              width: 200,
              height: 150,
              margin: '5px',
            }}
          />
          <SidebarItensContainer
            className="grid pl-2 mt-1 sidebar-scroll"
            onScroll={handlerScroll}
          >
            {user?.profile?.functionalities
              .filter(
                (func: any) =>
                  func.active && func.title !== 'Config. Perfil de Usuário',
              )
              .map((val: any, key: any) => (
                <NavLink
                  key={key}
                  to={val?.link}
                  className="grid grid-cols-4 text-decoration-none my-0 py-3 border-l-4 border-transparent hover:border-white hover:bg-emerald-400 duration-150 justify-self-center w-full"
                  activeClassName="bg-emerald-400 border-white"
                >
                  <i
                    className={`justify-self-center align-self-center text-white text-xl ${
                      SidebarData.filter((bar) => bar.title == val.title)[0]
                        ?.icon
                    }`}
                  />
                  <span className="text-lg text-gray-200 col-start-2 col-end-4">
                    {val?.title}
                  </span>
                </NavLink>
              ))}
          </SidebarItensContainer>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px',
            }}
          >
            {sidebarScroll.scrollTop < sidebarScroll.scrollTopMax && (
              <i
                style={{ color: '#FFF', fontSize: '20px' }}
                className={`fa-solid fa-angles-down animation-down ${
                  scrollBar ? 'block' : 'hidden'
                }`}
              />
            )}
          </div>
        </Nav>
      </SidebarContainer>
      <SidebarPrime
        style={{ backgroundColor: colors.primary }}
        icons={
          <i
            onClick={() => setSidebar(false)}
            className="fa-solid fa-close"
            style={{ color: '#F7f7f7', cursor: 'pointer', fontSize: '22px' }}
          />
        }
        showCloseIcon={false}
        visible={showSidebar}
        onHide={() => setSidebar(false)}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            src={LogoSeedSide}
            alt="Secretaria da Educação Do Amapá"
            style={{
              objectFit: 'contain',
              padding: '5px',
              width: 200,
              height: 150,
              margin: '5px',
            }}
          />
        </div>
        <SidebarItensContainer className="grid mt-1">
          {user?.profile?.functionalities
            .filter(
              (func: any) =>
                func.active && func.title !== 'Config. Perfil de Usuário',
            )
            .map((val: any, key: any) => (
              <NavLink
                key={key}
                to={val.link}
                className="grid grid-cols-4 text-decoration-none my-0 py-3 border-l-4 border-transparent hover:border-white hover:bg-emerald-400 duration-150 justify-self-center w-full"
                activeClassName="bg-emerald-400 border-white"
              >
                <i
                  className={`justify-self-center align-self-center text-white text-xl ${
                    SidebarData.filter((bar) => bar.title == val.title)[0]?.icon
                  }`}
                />
                <span className="text-lg text-gray-200 col-start-2 col-end-4">
                  {val.title}
                </span>
              </NavLink>
            ))}
        </SidebarItensContainer>
      </SidebarPrime>
      <Container backgroundColor={colors.background}>{children}</Container>
    </div>
  );
};

export default Sidebar;
