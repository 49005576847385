/* eslint-disable arrow-body-style */
/* eslint-disable no-underscore-dangle */
/* eslint-disable */
/* eslint-disable react-hooks/rules-of-hooks */
// @ts-ignore
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';
import { Link, Redirect } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import {
  locale,
  addLocale,
  FilterMatchMode,
  FilterOperator,
} from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import api from '../../services/api';
import { IServiceCall } from '../../types/IServiceCall';
import {
  ServiceCallTableContainer,
  DataTableStyle,
  DropdownStyle,
  CalendarStyle,
} from './style';
import { Eye } from '../../assets/svg/Eye';
import Pagination from '../Pagination';
import { dateFormatter } from '../../utils/date_formatter';
import './ServiceCallTable.css';

import { useAuth } from '../../hooks/auth';
import { Tooltip } from 'primereact/tooltip';
import { ThemeContext } from 'styled-components';
import { Ripple } from 'primereact/ripple';
import { classNames } from 'primereact/utils';

const ServiceCallTable = ({ allowedActions }: any) => {
  const [serviceCalls, setServiceCalls] = useState<IServiceCall[]>();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const [first1, setFirst1] = React.useState(0);
  const [rows1, setRows1] = React.useState(20);
  const [filters2, setFilters2] = React.useState({
    classification: { value: null, matchMode: FilterMatchMode.CONTAINS },
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    status: { value: null, matchMode: FilterMatchMode.CONTAINS },
    'service_call_type.type': {
      value: null,
      matchMode: FilterMatchMode.CONTAINS,
    },
    resource_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
    createdAt: { value: null, matchMode: FilterMatchMode.DATE_IS },
    'school.name': { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue2, setGlobalFilterValue2] = React.useState('');

  const indexOfLastCall = currentPage * itemsPerPage;
  const indexOfFirstCall = indexOfLastCall - itemsPerPage;
  const currentCalls = serviceCalls?.slice(indexOfFirstCall, indexOfLastCall);

  const { colors } = React.useContext(ThemeContext);

  const { user, signed } = useAuth();

  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

  async function getServiceCalls() {
    try {
      setLoading(true);
      if (signed) {
        if (user.profile.name === 'MASTER' || user.profile.name === 'COREF') {
          const { data } = await api.get(`/service-call`);
          // eslint-disable-next-line arrow-body-style
          const handlingData = data.map((item: any) => {
            return {
              ...item,
              createdAt: new Date(item.createdAt),
              resource_name: item?.resource?.name || item?.allotment?.name,
            };
          });
          setServiceCalls(handlingData);
        } else if (user.profile.id === 1) {
          const { data } = await api.get(
            `/service-call/institution/${user.institution_id}`,
          );
          // eslint-disable-next-line arrow-body-style
          const handlingData = data.map((item: any) => {
            return {
              ...item,
              createdAt: new Date(item.createdAt),
              resource_name: item?.resource?.name || item?.classification,
            };
          });
          setServiceCalls(handlingData);
        } else {
          const { data } = await api.get(
            `/service-call/school/${user.school_id}`,
          );
          // eslint-disable-next-line array-callback-return, arrow-body-style, arrow-parens
          const handlingData = data.map((item: any) => {
            return {
              ...item,
              createdAt: new Date(item.createdAt),
              resource_name: item?.resource?.name || item?.classification,
            };
          });
          setServiceCalls(handlingData);
        }
      }
    } catch (error) {
      /* toast('Não foi possível carregar os chamados', {
        position: 'bottom-center',
      }); */
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getServiceCalls();
    // Pega a url e os parametros que estão na url
    const location = window.location;
    const search = new URLSearchParams(location.search);
    // Faço uma busca pelo parametro filter da queryString
    if (search.get('filter')) {
      // Se tiver o filter nos parametros da queryString pego o valor do parametro e seto na tabela
      const _filters2 = { ...filters2 };
      const value: any = search.get('filter');
      _filters2['global'].value = value;
      setFilters2(_filters2);
      setGlobalFilterValue2(value);
    }
  }, [signed]);

  const opcoesBody = (rowData: any) => (
    <Link
      to={{
        pathname: `chamados/chamado/${rowData.id}`,
        state: rowData.id,
      }}
      style={{ textDecoration: 'none', width: '100%' }}
    >
      <div className="flex justify-content-center w-full">
        <Tooltip
          content="Editar"
          position="top"
          target=".icon-visualizar"
        />
        <i className="fa-solid fa-pen-to-square text-center icon-visualizar" />
      </div>
    </Link>
  );

  const statusColor: any = {
    'Em Andamento': 'bg-yellow-400',
    Aberto: 'bg-blue-400',
    'Em Aberto': 'bg-blue-400',
    'Aguardando Retorno': 'bg-slate-400',
    Finalizado: 'bg-green-400',
  };

  const statusBody = (rowData: any) => (
    <div className="flex justify-content-center w-full">
      <span
        className={`${statusColor[rowData.status]
          } px-2 py-1 font-bold rounded-full text-white text-center`}
      >
        {rowData.status}
      </span>
    </div>
  );

  addLocale('BR', {
    firstDayOfWeek: 1,
    dayNames: [
      'domingo',
      'segunda',
      'terça',
      'quarta',
      'quinta',
      'sexta',
      'sábado',
    ],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    monthNamesShort: [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ],
    today: 'Hoje',
    clear: 'Limpar',
    apply: 'Aplicar',
    addRule: 'Adicionar filtro',
    removeRule: 'Remover filtro',
    matchAll: 'Todos corresponderem',
    matchAny: 'Qualquer um corresponder',
  });
  locale('BR');
  const localeBR: any = locale('BR');

  const formatDate = (value: any) => {
    const date = value.length != 0 ? new Date(value) : new Date();
    return date.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };
  // eslint-disable-next-line arrow-body-style
  const dateBodyTemplate = (rowData: any) => {
    // eslint-disable-next-line react/jsx-one-expression-per-line
    return (
      <span className="text-center w-full">
        {formatDate(rowData?.createdAt)}
      </span>
    );
  };

  // eslint-disable-next-line arrow-body-style
  const dateFilterTemplate = (options: any) => {
    return (
      <CalendarStyle
        value={options.value}
        onChange={(e) => options.filterApplyCallback(e.value)}
        locale="BR"
        color={colors.text}
        inputStyle={{
          backgroundColor: colors.background,
          borderRadius: '0px',
          color: colors.text,
        }}
        showButtonBar
        showIcon
        icon="fa-solid fa-calendar"
        dateFormat="dd/mm/yy"
        placeholder="Esta data"
        mask="99/99/9999"
      />
    );
  };

  function optionsType() {
    let optionsServiceType = serviceCalls?.map(
      (item: any) => item.service_call_type?.type,
    );
    optionsServiceType = optionsServiceType?.filter((item: any) => {
      const type = String(item);
      return type != 'undefined';
    });
    optionsServiceType = Array.from(new Set(optionsServiceType));
    const options = optionsServiceType.map((item: any) => ({
      label: item,
      value: item,
    }));
    return options;
  }

  function optionsSchool() {
    let optionsServiceSchool = serviceCalls?.map(
      (item: any) => item?.school?.name,
    );
    optionsServiceSchool = optionsServiceSchool?.filter((item: any) => {
      const type = String(item);
      return type != 'undefined';
    });
    optionsServiceSchool = Array.from(new Set(optionsServiceSchool));
    const options = optionsServiceSchool.map((item: any) => ({
      label: item,
      value: item,
    }));
    return options;
  }

  function optionsResource() {
    let optionsResourse = serviceCalls?.map((item: any) => item.resource_name);
    optionsResourse = optionsResourse?.filter((item: any) => {
      const type = String(item);
      return type != 'undefined';
    });
    optionsResourse = Array.from(new Set(optionsResourse));
    const options = optionsResourse.map((item: any) => ({
      label: item,
      value: item,
    }));
    return options;
  }

  function optionsClassification() {
    let optionsServiceType = serviceCalls?.map(
      (item: any) => item?.classification,
    );
    optionsServiceType = optionsServiceType?.filter((item: any) => {
      const type = String(item);
      return type != 'undefined';
    });
    optionsServiceType = Array.from(new Set(optionsServiceType));
    const options = optionsServiceType.map((item: any) => ({
      label: item,
      value: item,
    }));
    return options;
  }

  const optionsDrop: any = {
    'classification': {
      options: optionsClassification(),
      placeholder: 'Tipo',
    },
    'service_call_type.type': {
      options: optionsType(),
      placeholder: 'Tipo do serviço',
    },
    status: {
      options: [
        { label: 'Aberto', value: 'Aberto' },
        { label: 'Em Andamento', value: 'Em Andamento' },
        { label: 'Aguardando Retorno', value: 'Aguardando Retorno' },
        { label: 'Finalizado', value: 'Finalizado' },
      ],
      placeholder: 'Status',
    },
    resource_name: {
      options: optionsResource(),
      placeholder: 'Tipo do recurso',
    },
    'school.name': {
      options: optionsSchool(),
      placeholder: 'Escolas',
    },
  };

  const dropFilterTemplate = (options: any) => {
    const nameFilter = String(options.field);
    return (
      <DropdownStyle
        className="dropdown-filter"
        color={colors.text}
        value={options.value}
        options={optionsDrop[nameFilter].options}
        onChange={(e) => options.filterApplyCallback(e.value)}
        style={{ backgroundColor: colors.background }}
        placeholder={optionsDrop[nameFilter].placeholder}
        showClear
      />
    );
  };

  const pagination = {
    layout: 'PrevPageLink PageLinks NextPageLink',
    PrevPageLink: (options: any) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          style={{ borderRadius: '8px', color: colors.text }}
          disabled={options.disabled}
        >
          <span className="p-3">Anterior</span>
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options: any) => {
      return (
        <button
          type="button"
          className={options.className}
          style={{ borderRadius: '8px', color: colors.text }}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-3">Próximo</span>
          <Ripple />
        </button>
      );
    },
    PageLinks: (options: any) => {
      if (
        (options.view.startPage === options.page &&
          options.view.startPage !== 0) ||
        (options.view.endPage === options.page &&
          options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { 'p-disabled': true });

        return (
          <span
            className={className}
            style={{ userSelect: 'none', borderRadius: '3px' }}
          >
            ...
          </span>
        );
      }

      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          style={{ marginRight: '10px' }}
        >
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
  };

  const statuses = [
    'Em andamento',
    'Aberto',
    'Finalizado',
    'Aguardando Retorno',
  ];

  const statusItemTemplate = (option: any) => {
    return <span className={`customer-badge status-${option}`}>{option}</span>;
  };

  const statusBodyTemplate = (rowData: any) => {
    return (
      <span className={`customer-badge status-${rowData.status}`}>
        {rowData.status}
      </span>
    );
  };

  const statusRowFilterTemplate = (options: any) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e) => options.filterApplyCallback(e.value)}
        // itemTemplate={statusItemTemplate}
        placeholder="Select a Status"
        className="p-column-filter"
        showClear
      />
    );
  };

  const HeaderInFilter = ({ label }: any) => (
    <p
      className="text-center"
      style={{ fontSize: '.8rem', color: colors.text }}
    >
      {label}
    </p>
  );

  return (
    <div className="container-table-call">
      <Link to="/chamados" className="text-decoration-none">
        <Button
          onClick={() => {
            const _filters2 = { ...filters2 };
            const value: any = '';
            _filters2['global'].value = value;
            setFilters2(_filters2);
            setGlobalFilterValue2(value);
          }}
          className="py-2 px-3 bg-green-400 border-0 hover:bg-green-300"
          label="Limpar Filtro"
        />
      </Link>
      {allowedActions.acoes.read ? (
        <div className="card mt-3">
          <DataTableStyle
            showGridlines
            className="call-table"
            value={serviceCalls}
            dataKey="id"
            size="small"
            scrollable
            scrollDirection="horizontal"
            scrollHeight="55vh"
            stripedRows
            paginator
            loading={loading}
            first={first1}
            rows={10}
            filters={filters2}
            filterDisplay="row"
            paginatorTemplate={pagination}
            globalFilterFields={[
              'status',
              'resource.name',
              'service_call_type.type',
            ]}
            style={{ backgroundColor: colors.background }}
            backgroundColor={colors.container}
            backgroundButtonPaginate={colors.btn_color}
            colorButtonPaginate={colors.text}
            backgroundButtonPaginateActive={colors.icon_visu}
            colorButtonPaginateActive={'#FFF'}
            rowStripedColor={colors.stripedRow}
            rowsColor={colors.row}
            colorIcon={colors.secundary}
            emptyMessage="Não há dados para serem exibidos"
          >
            <Column
              style={{ minWidth: '4.5rem', maxWidth: '4.5rem' }}
              bodyClassName="flex justify-content-center"
              filterHeaderStyle={{
                backgroundColor: colors.background,
                color: colors.text,
              }}
              headerStyle={{
                fontSize: '14px',
                backgroundColor: colors.background,
                color: colors.text,
              }}
              bodyStyle={{
                color: colors.text,
              }}
              className="text-sm"
              field="id"
              filter
              filterElement={<HeaderInFilter label="ID" />}
              showClearButton={false}
              showFilterMenu={false}
            />
            <Column
              style={{ minWidth: '10rem' }}
              filterHeaderStyle={{
                backgroundColor: colors.background,
                color: colors.text,
              }}
              headerStyle={{
                backgroundColor: colors.background,
                color: colors.text,
              }}
              bodyStyle={{
                color: colors.text,
              }}
              className="text-sm"
              filter
              filterElement={dropFilterTemplate}
              showFilterMenu={false}
              showClearButton={false}
              field="classification"
              sortable
            />
            <Column
              // header="Tipo do serviço"
              filter
              style={{ minWidth: '10rem' }}
              filterMenuStyle={{ width: '100%' }}
              headerStyle={{
                backgroundColor: colors.background,
                color: colors.text,
              }}
              bodyStyle={{
                color: colors.text,
              }}
              className="text-sm"
              field="service_call_type.type"
              // showFilterMenuOptions={false}
              showFilterMenu={false}
              showClearButton={false}
              filterMenuClassName="w-2"
              filterElement={dropFilterTemplate}
              sortable
            />
            <Column
              style={{ minWidth: '11rem', maxWidth: '12rem' }}
              filterMenuStyle={{
                backgroundColor: colors.container,
                color: colors.text,
              }}
              headerStyle={{
                backgroundColor: colors.background,
                color: colors.text,
              }}
              bodyStyle={{
                color: colors.text,
              }}
              className="text-sm"
              header="Status"
              field="status"
              filter
              showClearButton={false}
              showFilterMenu={false}
              filterMenuClassName="w-2"
              filterElement={dropFilterTemplate}
              body={statusBody}
              sortable
            />
            <Column
              style={{ minWidth: '13rem' }}
              filterHeaderStyle={{
                backgroundColor: colors.background,
                color: colors.text,
              }}
              headerStyle={{
                backgroundColor: colors.background,
                color: colors.text,
              }}
              bodyStyle={{
                color: colors.text,
              }}
              sortable
              field="createdAt"
              header="Data e hora"
              filter
              showClearButton={false}
              {...localeBR}
              dataType="date"
              body={dateBodyTemplate}
              // filterMatchModeOptions={[
              //   { label: 'Esta data', value: FilterMatchMode.DATE_IS },
              //   { label: 'Sem essa data', value: FilterMatchMode.DATE_IS_NOT },
              //   {
              //     label: 'Antes dessa data',
              //     value: FilterMatchMode.DATE_BEFORE,
              //   },
              //   {
              //     label: 'Depois dessa data',
              //     value: FilterMatchMode.DATE_AFTER,
              //   },
              // ]}
              showFilterMenu={false}
              filterElement={dateFilterTemplate}
            />
            <Column
              style={{ minWidth: '25rem' }}
              filterMenuStyle={{
                backgroundColor: colors.container,
                color: colors.text,
              }}
              headerStyle={{
                backgroundColor: colors.background,
                color: colors.text,
              }}
              bodyStyle={{
                color: colors.text,
              }}
              className="text-sm"
              header="Escola"
              field="school.name"
              filter
              showFilterMenu={false}
              showClearButton={false}
              filterElement={dropFilterTemplate}
              sortable
            />
            <Column
              style={{ minWidth: '10rem', maxWidth: '12rem' }}
              filterMenuStyle={{
                backgroundColor: colors.container,
                color: colors.text,
              }}
              headerStyle={{
                backgroundColor: colors.background,
                color: colors.text,
              }}
              bodyStyle={{
                color: colors.text,
              }}
              className="text-sm"
              header="Recurso"
              field="resource_name"
              filter
              showClearButton={false}
              showFilterMenu={false}
              showFilterMenuOptions={false}
              filterMenuClassName="w-2"
              filterElement={dropFilterTemplate}
              sortable
            />
            <Column
              style={{ minWidth: '5rem', maxWidth: '5rem' }}
              filterHeaderStyle={{
                backgroundColor: colors.background,
                color: colors.text,
              }}
              headerStyle={{
                backgroundColor: colors.background,
                color: colors.text,
              }}
              bodyStyle={{
                color: colors.text,
              }}
              filter
              filterElement={<HeaderInFilter label="Opções" />}
              showClearButton={false}
              showFilterMenu={false}
              body={opcoesBody}
            />
          </DataTableStyle>
        </div>
      ) : (
        <div className="w-full h-full grid place-items-center">
          <span>Você não tem permissão</span>
        </div>
      )}
      {/* <ServiceCallTableContainer
        striped
        style={{
          overflow: 'scroll',
        }}
      >
        <thead>
          <tr>
            <th>Identificador</th>
            <th>Tipo</th>
            <th>Status</th>
            <th>Data</th>
            <th>Escola</th>
            <th>Opções</th>
          </tr>
        </thead>
        <tbody>
          {
            loading ? (
              <div style={{
                position: 'absolute',
                left: '50%',
                right: '50%',
              }}
              >
                <ReactLoading type="bars" color="#36B37E" />
              </div>
            ) : (
              allowedActions.acoes.read && currentCalls?.map((serviceCall) => (
                <tr key={serviceCall.id}>
                  <td>{`#${serviceCall.id}`}</td>
                  <td>{serviceCall.classification}</td>
                  <td>
                    <Badge color={
                       (serviceCall.status === 'Aberto' || serviceCall.status === 'Em Aberto') ? 'primary'
                         : serviceCall.status === 'Aguardando Retorno' ? 'secondary' : serviceCall.status === 'Em Andamento' ? 'warning' : 'success'
                      }
                    >
                      {serviceCall.status === 'Aberto' || serviceCall.status === 'Em Aberto' ? 'Aberto' : serviceCall.status}
                    </Badge>
                  </td>
                  <td>{dateFormatter.format(new Date(serviceCall.createdAt))}</td>
                  <td>{serviceCall.school.name}</td>
                  <td>
                    <Link to={{
                      pathname: `/chamado/${serviceCall.id}`,
                      state: serviceCall.id,
                    }}
                    >
                      <div
                        onClick={() => {

                        }}
                        style={{
                          cursor: 'pointer',
                        }}
                      >
                        <Eye />
                      </div>
                    </Link>
                  </td>
                </tr>
              ))
            )
          }
        </tbody>
      </ServiceCallTableContainer> */}
    </div>
  );
};

export default ServiceCallTable;
