/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Form, Input, Label } from 'reactstrap';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InstitutionContext } from '../../context/institutionContext';
import api from '../../services/api';

const INITIAL_STATE = {
  description: '',
  institution_id: 0,
};

const ReferralTypeModal = ({
  selectedReferralType,
  loadReferralTypes,
  getInstitutions,
  selectedInstitution,
  toggle,
  modal,
}: any) => {
  const [data, setData] = useState(INITIAL_STATE);

  function handleSubmit() {
    toast
      .promise(api.post('/referrals-type', data), {
        pending: 'Cadastrando...',
        success: 'Tipo de encaminhamento cadastrado com sucesso',
        error: 'Ocorreu um erro ao cadastrar o Tipo de encaminhamento ',
      })
      .then(() =>
        setTimeout(() => {
          loadReferralTypes();
          getInstitutions();
          setData({ description: '', institution_id: selectedInstitution.id });
        }, 300),
      );
  }

  function editReferralType(referralTypeId: number) {
    toast
      .promise(api.patch(`/referrals-type/${referralTypeId}`, data), {
        pending: 'Alterando...',
        success: 'Tipo de encaminhamento alterado com sucesso',
        error: 'Ocorreu um erro ao alterar o Tipo de encaminhamento ',
      })
      .then(() =>
        setTimeout(() => {
          loadReferralTypes();
          setData({ description: '', institution_id: selectedInstitution.id });
          getInstitutions();
        }, 300),
      );
  }
  useEffect(() => {
    setData({
      description: '',
      institution_id: selectedInstitution.id,
    });
  }, [selectedInstitution.id]);

  const footer = (
    <>
      {!selectedReferralType ? (
        <Button
          className="btn-cadastro"
          onClick={() => {
            handleSubmit();
            loadReferralTypes();
            setData(INITIAL_STATE);
            toggle();
          }}
        >
          Cadastrar
        </Button>
      ) : (
        <Button
          className="btn-atualizar"
          onClick={() => {
            editReferralType(selectedReferralType.id);
            toggle();
          }}
        >
          Alterar
        </Button>
      )}
    </>
  );

  return (
    <>
      <Dialog
        header={
          !selectedReferralType
            ? 'Cadastro de tipo de encaminhamento'
            : 'Alterar informação do tipo de encaminhamento'
        }
        style={{ width: '40%' }}
        visible={modal}
        footer={footer}
        onHide={toggle}
      >
        <Form className="px-4" onSubmit={e => e.preventDefault()}>
          <Label>Descrição</Label>
          <Input
            type="text"
            value={data.description}
            onChange={e => {
              setData({ ...data, description: e.target.value });
            }}
          />
        </Form>
      </Dialog>
    </>
  );
};

export default ReferralTypeModal;
