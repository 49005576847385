import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import React, { useState, useEffect } from 'react';
import { Table } from 'reactstrap';
import { Eye } from '../../assets/svg/Eye';
import api from '../../services/api';
import AllotmentCityModal from '../AllotmentCityModal';
import { DataTableStyle } from '../ServiceCallTable/style';

const AllotmentCityList = ({
  allotmentCities,
  loadAllotmentCities,
  loading,
}: any) => {
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [selectedAllotmentCity, setSelectedAllotmentCity] = useState({});

  const toggle = () => {
    setModal(!modal);
  };

  const optionsBody = (rowData: any) => (
    <>
      <Tooltip position="top" content="Visualizar" target=".icon-visualizar" />
      <i
        onClick={() => {
          setSelectedAllotmentCity(rowData);
          toggle();
        }}
        className="fa-solid fa-eye icon-visualizar"
      />
    </>
  );

  return (
    <>
      <DataTableStyle
        size="small"
        className="border-1 rounded-lg mt-2"
        key="id"
        value={allotmentCities}
        scrollHeight="200px"
        scrollable
        loading={loading}
        emptyMessage="Sem dados a serem mostrados"
      >
        <Column
          sortable
          style={{ minWidth: '86.4%' }}
          className="border-1"
          header="Municipio"
          field="name"
        />
        <Column
          header="Opções"
          headerClassName="flex justify-content-center"
          bodyClassName="flex justify-content-center"
          body={optionsBody}
        />
      </DataTableStyle>
      <AllotmentCityModal
        modal={modal}
        toggle={toggle}
        selectedAllotmentCity={selectedAllotmentCity}
        loadAllotmentCities={loadAllotmentCities}
      />
    </>
  );
};

export default AllotmentCityList;
