/* eslint-disable arrow-parens */
// @ts-nocheck
import { DataTable } from 'primereact/datatable';
import { TabMenu } from 'primereact/tabmenu';
import React from 'react';
import { ListGroupItem } from 'reactstrap';
import styled from 'styled-components';

export const TabContainer = styled.div`
  overflow: hidden;
  background: none;
  font-family: Open sans;
  height: 3em;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const TabButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  position: relative;
  right: 0;

  margin-right: 0.1em;
  font-size: 1em;

  border: ${(props) => (props.active ? '1px solid #ccc' : '')};
  border-bottom: ${(props) => (props.active ? 'none' : '')};
  background-color: ${(props) => (props.active ? '#36b37e' : 'lightgray')};
  height: ${(props) => (props.active ? '3em' : '2.6em; top:.4em')};
  transition: background-color 0.5s ease-in-out;

  :hover {
    background-color: #36b37e;
  }
`;

export const ContentContainer = styled.div`
  ${(props) => (props.active ? '' : 'display:none')};
  max-height: 70vh;

  @media(max-width: 600px) {
    padding-bottom: 50px;
  }
`;

export const ServiceCallListItem = styled(ListGroupItem)`
  width: 100%;
  max-width: 1667px;
  display: flex;
  flex-direction: row;
  justify-content: inherit;
  background-color: ${props => props.backgroundColor};
  gap: 20px;
`;

export const TabMenuSty = styled(TabMenu)`
  ::-webkit-scrollbar {
    width: 3px;
  }
  background-color: transparent;
  .p-tabmenu {
    background-color: transparent !important;
  }
  .p-tabmenu-nav {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-rows: repeat(1fr);
    grid-template-columns: ${props => props.columns};
    height: 100% !important;
    background-color: transparent !important;
  }
  .p-tabmenuitem {
    width: 100%;
    border: 2px solid #CCC !important;
    border-radius: 5px;
    background-color: transparent !important;
    color: ${(props) => props.color} !important;
  }
  .p-tabmenuitem.p-highlight {
    border-color: #36b37e !important;
  }
  .p-highlight a {
    color: #36b37e !important;
    border-color: #36b37e !important;
  }
  .p-highlight a:focus {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
  .p-menuitem-link {
    display: grid;
    justify-items: center;
    grid-template-columns: .5fr 1fr;
    background-color: ${props => props.backgroundColor} !important;
    color: ${(props) => props.color} !important;
  }
  .p-menuitem-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
  }
  .p-menuitem-text {
    justify-self: start;
  }
  .p-menuitem-icon {
    justify-self: end;
  }
`;

export const Label = styled.p`
  font-weight: 700;
  color: ${props => props.color};
`;

export const TextInfo = styled.p`
  font-weight: 500;
  color: ${props => props.color};
`;

export const DataTableStyle = styled(DataTable)`
  .p-highlight {
    background-color: rgba(54, 179, 126, 0.15) !important;
    color: #36b37e !important;
  }
  .p-highlight:focus {
    border: 0px;
    box-shadow: none;
  }
  span.p-sortable-column-icon {
    border-radius: 100%;
    width: 35px;
    height: 35px;
    padding: 0px;
    display: grid;
    place-items: center;
    font-size: 1rem;
  }
  span.p-sortable-column-icon:hover {
    color: #fff !important;
    background-color: #36b391;
  }
  .p-highlight .p-sortable-column-icon {
    background-color: #fff;
    color: #36b37e !important;
    border: 3px solid #36b37e;
    font-weight: bold;
  }
  .p-column-filter-menu-button {
    margin-left: 0.5rem;
  }
  .p-column-filter-menu-button:hover {
    color: #fff;
    background-color: #36b391;
  }
  .p-column-filter-menu-button-active {
    background-color: #fff;
    color: #36b391;
  }
  .p-column-filter-menu-button-open {
    background-color: #fff;
    box-shadow: 0 0 0 0.2rem #36b37e;
    color: #36b37e;
  }
  .p-inputwrapper-focus {
    box-shadow: 0 0 0 0.2rem rgba(54, 179, 126, 0.5) !important;
    border: 2px solid #36b37e !important;
  }
  .p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled:hover {
    border: 1px solid #36b37e !important;
  }
  .p-paginator.p-component.p-paginator-bottom {
    background-color: ${(props) => props.backgroundColor};
    display: flex;
    justify-content: flex-start;
  }
  .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: ${(props) => props.colorIcon} !important;
  }
  .p-sortable-column .p-sortable-column-icon {
    color: #555;
  }
  .p-datatable-tbody tr {
    background-color: ${(props) => props.rowsColor};
    color: ${(props) => props.rowsColorText};
  }
  .p-datatable-tbody tr.p-row-odd {
    background-color: ${(props) => props.rowStripedColor} !important;
  }
  .p-paginator-page.p-paginator-element.p-link {
    background-color: ${(props) => props.backgroundButtonPaginate};
    color: ${(props) => props.colorButtonPaginate};
    border-radius: 8px;
    min-width: 0rem;
    padding: 0;
    height: 31px;
    width: 31px;
  }
  .p-paginator-page.p-paginator-element.p-link.p-highlight {
    background-color: ${(props) => props.backgroundButtonPaginateActive};
    color: ${(props) => props.colorButtonPaginateActive};
  }
`;
