export default {
  title: "dark",

  font: {
    family: "Roboto",
    size: "14",
  },

  colors: {
    primary: "#333",
    secundary: "#36B37E",

    primary_btn: "#36B37E",
    primary_btn_hover: "#008453",


    background: "#222",
    container: "#333",
    border: "#555",
    stripedRow: "#222",
    row: "#333",
    text: "#fff",
    icon: "#36B37E",
    btn_color: "#555",
    icon_visu: "#36B37E",
    card: "#333",
  },
};
