import React, {
  createContext, useCallback, useState, useContext, useMemo,
} from 'react';

import api from '../services/api';

interface User {
  id: number;
  name: string;
  profile: {
    id: number;
    name: string;
    functionalities: any;
  }
  school_id: number | null;
  institution_id: number | null;
}

interface AuthState {
  token: string;
  user: User;
}

interface IApplications {
  id: number;
  aplicacao_id: string;
  user_id: string;
  acesso_concedido: boolean;
  created_at: string;
  updated_at: string;
}

export interface SignInCredentials {
  id: number;
  name: string;
  email: string;
  email_verified_at: string | null;
  created_at: string;
  updated_at: string;
  cpf: string;
  path_avatar: string | null;
  url_avatar: string | null;
  id_servidor: string | null;
  ativo: boolean;
  perfil_id: number;
  aplicacoes: IApplications[]
}

interface OAuthContextData {
  user: User;
  loading: boolean;
  // eslint-disable-next-line no-unused-vars
  signIn(credentias: SignInCredentials): Promise<void>;
  signOut(): void;
  signed: boolean;
}

interface IProps {
  children: React.ReactNode;
}

const OAuthContext = createContext<OAuthContextData>({} as OAuthContextData);

export const OAuthProvider: React.FC<IProps> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@infra-escolar:token');
    const user = localStorage.getItem('@infra-escolar:user');

    if (token && user) {
      return { token, user: { ...JSON.parse(user), profile: { ...JSON.parse(user).profile, functionalities: JSON.parse(JSON.parse(user).profile.functionalities) } } };
    }

    return {} as AuthState;
  });

  const signed = useMemo(
    () => !!data.token && !!data.user,
    [data],
  );

  const signIn = useCallback(async (data: SignInCredentials) => {
    try {
      setLoading(true);

      const response = await api.post(`/auth/oauth`, data);

      const { auth, user } = response.data;
      localStorage.setItem('@infra-escolar:token', auth);
      localStorage.setItem('@infra-escolar:user', JSON.stringify(user));
      localStorage.setItem('@infra-escolar:auth', "oauth");

      // @ts-ignore
      api.defaults.headers.authorization = `Bearer ${auth}`;

      setData({ token: auth, user: { ...user, profile: { ...user.profile, functionalities: JSON.parse(user.profile?.functionalities) } } });

      window.location.href = JSON.parse(user.profile.functionalities).filter((item: any) => item.active)[0].link;
    } catch (error) {
      setLoading(false);

      localStorage.removeItem('@infra-escolar:token');
      localStorage.removeItem('@infra-escolar:user');
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@infra-escolar:token');
    localStorage.removeItem('@infra-escolar:user');
    localStorage.removeItem('@infra-escolar:auth');
    localStorage.removeItem('@infra-escolar:access_token');

    window.location.replace('https://sisged.seed.ap.gov.br');
  }, []);

  return (
    <OAuthContext.Provider value={{
      user: data.user, loading, signed, signIn, signOut,
    }}
    >
      {children}
    </OAuthContext.Provider>
  );
};

export function useOAuth(): OAuthContextData {
  const context = useContext(OAuthContext);

  if (!context) {
    throw new Error('useOAuth must be used within an OAuthProvider');
  }

  return context;
}
