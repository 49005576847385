import React, {
  createContext, ReactNode, useEffect, useState,
} from 'react';
import api from '../services/api';
import { IInstitution } from '../types/IInstitution';

interface InstitutionContextType {
  institutions: IInstitution[],
  getInstitutions: () => void;
  setInstitutions: (values: any) => void;
  loading: boolean;
}

interface IProps {
  children: ReactNode;
}

export const InstitutionContext = createContext({} as InstitutionContextType);

export const InstitutionsProvider: React.FC<IProps> = ({ children }: IProps) => {
  const [institutions, setInstitutions] = useState<IInstitution[]>([]);
  const [loading, setLoading] = useState(false);

  async function getInstitutions() {
    setLoading(true);
    try {
      const response = await api.get('/institution');
      setInstitutions(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    setLoading(true);
    getInstitutions();
  }, []);
  return (
    <InstitutionContext.Provider value={{
      institutions, loading, setInstitutions, getInstitutions,
    }}
    >
      {children}
    </InstitutionContext.Provider>
  );
};
