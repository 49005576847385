/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Navbar,
  NavbarBrand,
} from 'reactstrap';
import { ThemeContext } from 'styled-components';
import { TitleIcon } from '../../assets/svg/TitleIcon';
import { useAuth } from '../../hooks/auth';
import { useOAuth } from '../../hooks/oauth';
import { HeaderContainer } from './Header.styled';
import { MenuIcon } from '../MenuIcon';
import { ToggleButton } from 'primereact/togglebutton';
import { InputSwitch } from 'primereact/inputswitch';
import './style.css';

interface User {
  full_name: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Header = ({
  title,
  backPage,
  linkBack,
  backHistoryPage,
  icon,
  showSidebar,
  setSidebar,
  toggleTheme,
}: any) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);
  const [currentUser, setCurrentUser] = useState();
  const { user, signOut } = useAuth();
  const { colors, title: titleTheme } = React.useContext(ThemeContext);
  const { signOut: signOauthOut } = useOAuth();

  useEffect(() => {
    const user = localStorage.getItem('@infra-escolar:user');
    if (user) {
      const username = JSON.parse(user);
      setCurrentUser(username.full_name);
    }
  }, []);

  return (
    <HeaderContainer
      style={{ backgroundColor: colors.container, borderRadius: '8px' }}
    >
      <Navbar>
        <NavbarBrand
          style={{
            display: 'flex',
            flexDirection: 'row',
            margin: '0',
            alignItems: 'center',
          }}
        >
          <MenuIcon
            style={{ color: colors.text }}
            onClick={() => setSidebar(true)}
            className="fa-solid fa-bars"
          />
          {backPage && (
            <Link to={linkBack}>
              <i className="fa-solid fa-arrow-left back-link cursor-pointer" />
            </Link>
          )}
          {backHistoryPage && (
            <i
              onClick={() => window.history.back()}
              className="fa-solid fa-arrow-left back-link cursor-pointer"
            />
          )}
          <i
            style={{ color: '#36B37E', fontSize: '1.5rem' }}
            className={icon}
          />
          <h1
            style={{
              margin: '0',
              marginLeft: '10px',
              color: '#36B37E',
            }}
            className="font-bold text-2xl"
          >
            {title}
          </h1>
        </NavbarBrand>
        <div className="flex gap-4">
          <InputSwitch
            checked={titleTheme === 'dark'}
            onChange={(e) =>
              toggleTheme(titleTheme === 'dark' ? 'light' : 'dark')
            }
          />
          <Dropdown nav isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle nav caret style={{ color: colors.text }}>
              Olá, <strong>{currentUser}</strong>
            </DropdownToggle>
            <DropdownMenu style={{ backgroundColor: colors.container }}>
              <DropdownItem
                style={{
                  backgroundColor: colors.container,
                  color: colors.text,
                  padding: '0',
                }}
              >
                {/* <ToggleButton
                style={{
                  height: '30px',
                  width: '100%',
                  border: 'none',
                  borderRadius: '0px',
                }}
                className="toggle-theme pr-6"
                onLabel="Dark"
                offLabel="Light"
                onIcon="fa-solid fa-sun"
                offIcon="fa-solid fa-moon"
                checked={titleTheme === 'light'}
                onChange={() => {
                  toggleTheme(titleTheme === 'dark' ? 'light' : 'dark')
                }
                }
              /> */}
              </DropdownItem>
              <DropdownItem
                style={{
                  backgroundColor: colors.container,
                  color: colors.text,
                }}
                className="flex justify-center"
                onClick={() =>
                  localStorage.getItem('@infra-escolar:auth') == 'auth'
                    ? signOut()
                    : signOauthOut()
                }
              >
                Sair
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </Navbar>
    </HeaderContainer>
  );
};

export default Header;
