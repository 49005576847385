/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import ReactLoading from 'react-loading';
import { Eye } from '../../assets/svg/Eye';
import ProfileModal from '../ProfileModal';
import { ProfilesTableContainer, DataTableStyle, Container } from './styles';
import { IProfile } from '../../types/IProfile';
import { ProfileContext } from '../../context/profileContext';
import Pagination from '../Pagination';
import { Tooltip } from 'primereact/tooltip';
import { ThemeContext } from 'styled-components';
import { classNames } from 'primereact/utils';
import { Ripple } from 'primereact/ripple';
import { Column } from 'primereact/column';
import { InputTextStyle } from './styles'
import { FilterMatchMode } from 'primereact/api';

const ProfilesTable = () => {
  const [modal, setModal] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState<IProfile | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const { profiles, loading } = useContext(ProfileContext);
  const [filters, setFilters] = useState({
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const toggle = () => {
    setModal(!modal);
  };

  const { colors } = useContext(ThemeContext);

  const indexOfLastCall = currentPage * itemsPerPage;
  const indexOfFirstCall = indexOfLastCall - itemsPerPage;
  const currentCalls = profiles?.slice(indexOfFirstCall, indexOfLastCall);

  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

  const pagination = {
    layout: 'PrevPageLink PageLinks NextPageLink',
    PrevPageLink: (options: any) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          style={{ borderRadius: '8px' }}
          disabled={options.disabled}
        >
          <span className="p-3">Anterior</span>
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options: any) => {
      return (
        <button
          type="button"
          className={options.className}
          style={{ borderRadius: '8px' }}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-3">Próximo</span>
          <Ripple />
        </button>
      );
    },
    PageLinks: (options: any) => {
      if (
        (options.view.startPage === options.page &&
          options.view.startPage !== 0) ||
        (options.view.endPage === options.page &&
          options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { 'p-disabled': true });

        return (
          <span
            className={className}
            style={{ userSelect: 'none', borderRadius: '3px' }}
          >
            ...
          </span>
        );
      }

      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          style={{ marginRight: '10px' }}
        >
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
  };

  const funcionalities = React.useCallback((rowData: any) => {
    const funcionalities = rowData.functionalities
      .filter((func: any) => func.active)
      .map((item: any) => String(item.title))
      .join(', ');

    return funcionalities;
  }, []);

  const options = React.useCallback((rowData: any) => {
    return (
      <div
        onClick={() => {
          setSelectedProfile(rowData);
          toggle();
        }}
        style={{
          cursor: 'pointer',
        }}
        className="flex justify-content-center"
      >
        <Tooltip
          content="Editar"
          position="top"
          target=".icon-visualizar"
        />
        <i className="fa-solid fa-pen-to-square icon-visualizar" />
      </div>
    );
  }, []);

  const filterTemplate = (options: any) => {
    return (
      <span className="p-input-icon-right">
        <i className="pi pi-search" />
        <InputTextStyle
          color={colors.text}
          placeholder={"Funcionalidades"}
          onChange={(e) => options.filterApplyCallback(e.target.value)}
          style={{
            backgroundColor: colors.background,
            color: colors.text,
            border: 'none',
          }}
        />
      </span>
    );
  };

  const HeaderInFilter = ({ label }: any) => (
    <p
      className="text-center"
      style={{ fontSize: '1rem', color: colors.text }}
    >
      {label}
    </p>
  );

  return (
    <div className="my-4">
      <div className="flex justify-content-end mb-4">
        <Button
          className="px-3 py-2 btn-cadastro border-0"
          onClick={() => {
            setSelectedProfile(null);
            toggle();
          }}
          label="Cadastrar novo perfil"
          icon="fa-solid fa-plus"
        />
      </div>
      <DataTableStyle
        value={currentCalls}
        className="drop-shadow-md"
        size="small"
        dataKey="id"
        paginator
        loading={loading}
        scrollable
        filterLocale="BR"
        showGridlines
        scrollDirection="horizontal"
        scrollHeight="60vh"
        rows={6}
        filterDisplay="row"
        stripedRows
        filters={filters}
        rowsPerPageOptions={[5, 10, 25]}
        paginatorTemplate={pagination}
        backgroundColor={colors.container}
        backgroundButtonPaginate={colors.btn_color}
        colorButtonPaginate={colors.text}
        backgroundButtonPaginateActive={colors.icon_visu}
        colorButtonPaginateActive={'#FFF'}
        rowStripedColor={colors.stripedRow}
        rowsColor={colors.row}
        colorIcon={colors.secundary}
      >
        <Column
          sortable
          filter
          filterMatchMode="contains"
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          showClearButton={false}
          showFilterMenu={false}
          filterPlaceholder="Funcionalidades"
          bodyStyle={{
            color: colors.text,
          }}
          headerStyle={{
            backgroundColor: colors.background,
            color: colors.text,
            borderRadius: '5px 0 0 0',
          }}
          filterMenuStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          field="name"
          filterElement={filterTemplate}
          header="Funcionalidades"
          style={{ minWidth: '15rem' }}
        />
        <Column
          filterPlaceholder="Permissões"
          bodyStyle={{
            color: colors.text,
          }}
          headerStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          filterHeaderStyle={{backgroundColor: colors.background, color: colors.text}}
          header="Permissões"
          filter
          showClearButton={false}
          showFilterMenu={false}
          filterElement={<HeaderInFilter label="Permissões" />}
          body={funcionalities}
          style={{ minWidth: '12rem' }}
        />
        <Column
          header="Opções"
          body={options}
          style={{ minWidth: '5rem', maxWidth: '6rem' }}
          bodyClassName="flex justify-content-center"
          bodyStyle={{
            color: colors.text,
          }}
          filterHeaderStyle={{backgroundColor: colors.background, color: colors.text}}
          filter
          showClearButton={false}
          showFilterMenu={false}
          filterElement={<HeaderInFilter label="Opções" />}
          headerStyle={{
            backgroundColor: colors.background,
            color: colors.text,
            borderRadius: '0 5px 0 0',
          }}
        />
      </DataTableStyle>
      <ProfileModal modal={modal} toggle={toggle} profile={selectedProfile} />
      {/* <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={profiles?.length}
        paginate={paginate}
      /> */}
    </div>
  );
};

export default ProfilesTable;
