/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import {
  Form,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import api from '../../services/api';

import { ServiceTypesContext } from '../../context/serviceCallTypesContext';
import ServiceCallSubtypesList from '../ServiceCallSubtypesList';
import { ThemeContext } from 'styled-components';

const NewServiceCallSubtypeModal = ({
  modal,
  toggle,
  selectedType: selectedTypeProp,
}: any) => {
  const [subtype, setSubtype] = useState('');

  const [selectedType, setSelectedType] = useState(selectedTypeProp);

  const [type, setType] = useState('');

  const { colors } = React.useContext(ThemeContext);

  const { getTypes, loading } = useContext(ServiceTypesContext);

  const createSubtype = () => {
    toast
      .promise(
        api.post(`/service-call-subtype/${selectedType.id}`, {
          subtype,
        }),
        {
          pending: 'Cadastrando....',
          success: 'subtipo cadastrado',
          error: 'Ocorreu um erro ao cadastrar o subtipo',
        },
      )
      .then(({ data }) => {
        getTypes();
        setSelectedType({
          ...selectedType,
          service_call_subtype: [...selectedType?.service_call_subtype, data],
        });
      });

    setSubtype('');
  };


  const updateType = () => {
    toast
      .promise(
        api.patch(`/service-call-type/${selectedType.id}`, {
          type,
        }),
        {
          pending: 'Alterando....',
          success: 'Tipo Alterado',
          error: 'Ocorreu um erro ao alterar o tipo',
        },
      )
      .then(getTypes);
  };

  useEffect(() => {
    setSelectedType(selectedTypeProp);
    if (selectedType) setType(selectedType?.type);
  }, [selectedType, selectedTypeProp, selectedType?.type]);

  return (
    <>
      <Dialog
        header="Tipo de Chamado"
        visible={modal}
        onHide={toggle}
        contentStyle={{
          backgroundColor: colors.container,
          color: colors.text,
          minWidth: '340px',
          width: '50vw',
        }}
        headerStyle={{
          backgroundColor: colors.container,
          color: colors.text,
          minWidth: '340px',
          width: '50vw',
        }}
      >
        <ModalBody>
          <Form onSubmit={(e) => e.preventDefault()}>
            <InputGroup className="mt-2">
              <Input
                type="text"
                value={type}
                onChange={(e) => setType(e.target.value)}
                style={{
                  backgroundColor: colors.container,
                  color: colors.text,
                }}
              />
              <Button
                className="px-3 py-2 btn-atualizar font-bold border-0"
                onClick={() => {
                  updateType();
                  toggle();
                }}
              >
                Alterar
              </Button>
            </InputGroup>
          </Form>
          <Form className="mt-4" onSubmit={(e) => e.preventDefault()}>
            <span className="text-lg" style={{ color: colors.text }}>
              Cadastrar Subtipos de Chamado
            </span>
            <InputGroup className="mt-1">
              <Input
                value={subtype}
                onChange={(e) => setSubtype(e.target.value)}
                style={{
                  backgroundColor: colors.container,
                  color: colors.text,
                }}
              />

              <Button
                className="px-3 py-2 btn-cadastro-group font-bold border-0"
                onClick={() => {
                  createSubtype();
                  toggle();
                }}
              >
                Cadastrar
              </Button>
            </InputGroup>
          </Form>
          <ServiceCallSubtypesList selectedType={selectedType} />
        </ModalBody>
      </Dialog>
    </>
  );
};

export default NewServiceCallSubtypeModal;
