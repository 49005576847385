/* eslint-disable  */
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
// import { Button } from 'reactstrap';
// import { Eye } from '../../assets/svg/Eye';
import { InstitutionContext } from '../../context/institutionContext';
import api from '../../services/api';
import InstitutionModal from '../InstitutionModal';

import './InstituitionTable.css';
import { Tooltip } from 'primereact/tooltip';
import { ThemeContext } from 'styled-components';
import { DataTableStyle } from '../SchoolsTable/styles';

const InstitutionTable = ({ allowedActions }: any) => {
  const { institutions, getInstitutions, loading } =
    useContext(InstitutionContext);
  const [modal, setModal] = useState(false);
  const [selectedInstitution, setSelectedInstitution] = useState([]);
  const toggle = () => {
    setModal(!modal);
  };

  const { colors } = React.useContext(ThemeContext);

  async function handleDelete(institution: any) {
    toast.promise(
      api.delete(`/institution/${institution.id}`).then(getInstitutions),
      {
        pending: 'Deletando....',
        success: 'Órgão deletado com sucesso',
        error: 'Ocorreu um erro ao deletar o órgão.',
      },
    );
  }

  const actionBodyTemplate = (rowData: any) => (
    <>
      <Button
        icon="pi pi-pencil"
        className="p-button-rounded bg-green-500 mr-2 border-0 hover:bg-green-400"
        style={{ height: '2.5rem', alignSelf: 'center' }}
        onClick={() => {
          setSelectedInstitution(rowData);
          toggle();
        }}
        tooltip="Editar"
        tooltipOptions={{ position: 'top', className: 'green-tooltip' }}
      />
      <Button
        icon="pi pi-trash"
        className="p-button-rounded bg-red-600 border-0 hover:bg-red-400"
        style={{ height: '2.5rem', alignSelf: 'center' }}
        onClick={() => handleDelete(rowData)}
        tooltip="Apagar"
        tooltipOptions={{ position: 'top' }}
      />
    </>
  );

  const reloadIcon = (
    <i
      style={{ justifySelf: 'center' }}
      className={
        loading
          ? 'fa-solid fa-rotate-right pi-spin'
          : 'fa-solid fa-rotate-right p-0 '
      }
    />
  );

  return (
    <>
      <div className="mt-5" style={{ display: 'grid' }}>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          <Tooltip position="top" content="Recarregar" target=".refresh" />
          <Button
            className="p-0 bg-blue-400 border-0 refresh"
            style={{ height: '2.5rem', width: '3rem', display: 'grid' }}
            onClick={() => getInstitutions()}
          >
            {reloadIcon}
          </Button>
          {allowedActions.acoes.create && (
            <Button
              className="btn-cadastro"
              style={{ justifySelf: 'end', height: '2.5rem' }}
              onClick={() => {
                toggle();
                setSelectedInstitution(() => []);
              }}
              label="Cadastrar novo órgão"
              icon="fa-solid fa-plus"
            />
          )}
        </div>
        <div className="card mt-3">
          <DataTableStyle
            value={institutions}
            selection={selectedInstitution}
            onSelectionChange={(e) => {
              setSelectedInstitution(e.value);
            }}
            stripedRows
            dataKey="id"
            paginator
            scrollable
            scrollDirection="vertical"
            scrollHeight="50vh"
            rows={10}
            style={{ backgroundColor: colors.container }}
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} órgãos"
            // responsiveLayout="scroll"
            loading={loading}
            backgroundColor={colors.container}
            backgroundButtonPaginate={colors.btn_color}
            colorButtonPaginate={colors.text}
            backgroundButtonPaginateActive={colors.icon_visu}
            colorButtonPaginateActive={'#FFF'}
            rowStripedColor={colors.stripedRow}
            rowsColor={colors.row}
            colorIcon={colors.secundary}
          >
            <Column
              field="name"
              header="Orgão"
              sortable
              style={{
                minWidth: '80%',
                backgroundColor: colors.background,
                color: colors.text,
              }}
            />
            <Column
              header="Opções"
              body={actionBodyTemplate}
              style={{
                minWidth: '80%',
                backgroundColor: colors.background,
                color: colors.text,
              }}
              exportable={false}
            />
          </DataTableStyle>
        </div>

        <InstitutionModal
          modal={modal}
          toggle={toggle}
          selectedInstitution={selectedInstitution}
          allowedActions={allowedActions}
        />
      </div>
    </>
  );
};

export default InstitutionTable;
