// @ts-nocheck
/* eslint-disable */
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Form, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Button } from 'primereact/button';
import api from '../../services/api';
import { money_mask } from '../../utils/money_mask';

const INITIAL_STATE = {
  name: '',
  value: '',
};

const ResourcesModal = ({
  modal,
  toggle,
  getResources,
  selectedResource,
}: any) => {
  const [resource, setResource] = useState(INITIAL_STATE);

  // const resourceValue = resource.value.replace('R$ ', "").replaceAll(".", "").replace(",", ".");

  function createResource() {
    toast
      .promise(api.post('/resource', resource), {
        pending: 'Cadastrando Recurso...',
        success: 'Recurso cadastrado com sucesso',
        error: 'Ocorreu um erro ao cadastrar o recurso',
      })
      .then(toggle())
      .then(() => {
        getResources();
      });
  }

  function updateResource() {
    toast
      .promise(api.patch(`/resource/${selectedResource.id}`, resource), {
        pending: 'Alterando Recurso...',
        success: 'Recurso alterado com sucesso',
        error: 'Ocorreu um erro ao alterar o recurso',
      })
      .then(toggle())
      .then(() => {
        getResources();
      });
  }

  function deleteResource() {
    toast
      .promise(api.delete(`/resource/${selectedResource.id}`), {
        pending: 'Deletando Recurso...',
        success: 'Recurso deletado com sucesso',
        error: 'Ocorreu um erro ao deletar o recurso',
      })
      .then(toggle())
      .then(() => {
        getResources();
      });
  }

  useEffect(() => {
    if (selectedResource == null) {
      setResource(INITIAL_STATE);
    } else {
      setResource(selectedResource);
    }
  }, [selectedResource]);
  return (
    <>
      <Dialog
        className="w-25"
        header="Cadastro de Recursos"
        visible={modal}
        onHide={toggle}
      >
        <ModalBody>
          <Form onSubmit={e => e.preventDefault()}>
            <Label>Nome</Label>
            <Input
              type="text"
              value={resource.name}
              onChange={e => setResource({ ...resource, name: e.target.value })}
            />

            <Label>Valor</Label>
            <Input
              type="text"
              value={resource.value}
              onChange={e =>
                setResource({ ...resource, value: money_mask(e.target.value) })
              }
            />
            <div className="flex justify-content-end gap-2">
              {!selectedResource ? (
                <Button
                  className="px-3 py-2 bg-green-400 border-0"
                  style={{
                    marginTop: '20px',
                  }}
                  onClick={() => {
                    createResource();
                    setResource(INITIAL_STATE);
                  }}
                >
                  Cadastrar
                </Button>
              ) : (
                <>
                  <Button
                    className="px-3 py-2  bg-yellow-400 border-0"
                    style={{
                      marginTop: '20px',
                    }}
                    onClick={() => {
                      updateResource();
                      setResource(INITIAL_STATE);
                    }}
                  >
                    Alterar
                  </Button>{' '}
                  <Button
                    className="px-3 py-2 bg-red-400 border-0"
                    style={{
                      marginTop: '20px',
                    }}
                    onClick={() => {
                      deleteResource();
                      setResource(INITIAL_STATE);
                    }}
                  >
                    Excluir
                  </Button>
                </>
              )}
            </div>
          </Form>
        </ModalBody>
      </Dialog>
    </>
  );
};

export default ResourcesModal;
