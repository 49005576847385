import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col } from 'reactstrap';
import Header from '../../components/Header';
import TimelineAddSchoolModal from '../../components/TimelineAddSchoolModal';
import TimelineSchoolList from '../../components/TimelineSchoolList';
import api from '../../services/api';

const TimelineInfo = ({ showSidebar, setSidebar, toggleTheme }: any) => {
  const [data, setData] = useState<any>();
  const [timeline, setTimeline] = useState<any>();
  const [modal, setModal] = useState(false);
  const [selectedAllotment, setSelectedAllotment] = useState();
  const [loading, setLoading] = useState(false);

  const { id } = useParams<any>();

  async function getTimelineById() {
    try {
      const { data } = await api.get(`timeline/${id}`);
      setTimeline(data);
    } catch (error) {
      console.error(error);
    }
  }

  async function getSchoolAllotmentsInTimeline() {
    if (timeline) {
      try {
        setLoading(true);
        const { data } = await api.get(
          `allotment-school/timeline/${timeline?.allotment_id}`,
        );
        setData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  }

  const toggle = () => {
    setModal(!modal);
  };

  useEffect(() => {
    getTimelineById();
    getSchoolAllotmentsInTimeline();
    setSelectedAllotment(timeline?.allotment_id);
  }, [id, timeline?.allotment_id]);

  return (
    <>
      <Header
        backHistoryPage
        title={`Cronograma #${id}`}
        showSidebar={showSidebar}
        setSidebar={setSidebar}
        toggleTheme={toggleTheme}
      />
      <div className="flex justify-end">
        <Button onClick={toggle} className="mb-4 mt-2 btn-cadastro">
          Adicionar Escolas
        </Button>
      </div>
      <Col>
        <TimelineSchoolList
          schoolAllotments={data}
          loading={loading}
          timelineId={id}
          getSchoolAllotmentsInTimeline={getSchoolAllotmentsInTimeline}
        />
        <TimelineAddSchoolModal
          toggle={toggle}
          modal={modal}
          selectedAllotment={selectedAllotment}
          selectedTimeline={id}
          getSchoolAllotmentsInTimeline={getSchoolAllotmentsInTimeline}
        />
      </Col>
    </>
  );
};

export default TimelineInfo;
