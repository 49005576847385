import React from 'react';
import { Link } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import CardDash from '../../components/CardDashboard';
import Header from '../../components/Header';
import SchoolDashboard from '../../components/SchoolDashboard';
import SchoolSearchInput from '../../components/SchoolSearchInput';
import { SchoolContext } from '../../context/schoolContext';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import './dashboard.css';

const DashboardPage = ({ showSidebar, setSidebar, toggleTheme }: any) => {
  const { user, signed } = useAuth();
  const { schools } = React.useContext(SchoolContext);

  const { colors } = React.useContext(ThemeContext);

  const [serviceCalls, setServiceCalls] = React.useState<any>();
  const [loading, setLoading] = React.useState<any>(false);
  const [selectedSchool, setSelectedSchool] = React.useState<any>();

  async function getServiceCalls() {
    try {
      setLoading(true);
      if (signed) {
        if (user.profile.name === 'MASTER' || user.profile.name === 'COREF') {
          const { data } = await api.get(`/service-call`);
          setServiceCalls(data);
        } else if (user.profile.id === 1) {
          const { data } = await api.get(
            `/service-call/institution/${user.institution_id}`,
          );
          setServiceCalls(data);
        } else {
          const { data } = await api.get(
            `/service-call/school/${user.school_id}`,
          );
          setServiceCalls(data);
        }
      }
    } catch (error) {
      /* toast('Não foi possível carregar os chamados', {
        position: 'bottom-center',
      }); */
    } finally {
      setLoading(false);
    }
  }

  const dataDash = {
    aberto: serviceCalls?.filter((item: any) => item.status === 'Aberto')
      .length,
    andamento: serviceCalls?.filter(
      (item: any) => item.status === 'Em Andamento',
    ).length,
    retorno: serviceCalls?.filter(
      (item: any) => item.status === 'Aguardando Retorno',
    ).length,
    finalizado: serviceCalls?.filter(
      (item: any) => item.status === 'Finalizado',
    ).length,
    seinf: serviceCalls?.filter((item: any) => item?.resource?.name === 'SEINF')
      .length,
    proem: serviceCalls?.filter((item: any) => item?.resource?.name === 'PROEM')
      .length,
    manutencaoEletrica: serviceCalls?.filter(
      (item: any) => item?.service_call_type?.type === 'MANUTENÇÃO ELÉTRICA',
    ).length,
    manutencaoPredialCivil: serviceCalls?.filter(
      (item: any) =>
        item?.service_call_type?.type === 'MANUTENÇÃO PREDIAL CIVIL',
    ).length,
  };

  React.useEffect(() => {
    getServiceCalls();
  }, [signed]);

  return (
    <div>
      <Header
        showSidebar={showSidebar}
        setSidebar={setSidebar}
        toggleTheme={toggleTheme}
        title="Dashboard"
        icon="fa-solid fa-chart-pie"
      />
      <div
        className="layout-dash mt-2"
        style={{
          overflowY: 'scroll',
          maxHeight: '80vh',
          overflowX: 'hidden',
          padding: '5px 0px',
        }}
      >
        <SchoolSearchInput
          selectedSchool={selectedSchool}
          setSelectedSchool={setSelectedSchool}
          schools={schools}
        />
        {!selectedSchool?.value ? (
          <div className="grid-item px-1">
            <Link
              to="/chamados?filter=Aberto"
              style={{ textDecoration: 'none' }}
            >
              <CardDash
                label="Em aberto"
                icon="fa-regular fa-clock text-xl"
                value={
                  loading ? (
                    <i className="fa-solid fa-arrows-rotate pi-spin" />
                  ) : (
                    dataDash.aberto
                  )
                }
                colorIcon="#609AF8"
                classNameCard="border-2 border-blue-400"
                classNameBoxIcon="bg-blue-100 border-2 border-blue-400"
                classNameLabel="text-blue-400"
                classNameValue="text-blue-500"
                labelDescricao="Chamados em aberto"
              />
            </Link>
            <Link
              to="/chamados?filter=andamento"
              style={{ textDecoration: 'none' }}
            >
              <CardDash
                label="Em andamento"
                icon="fa-solid fa-spinner pi-spin text-xl"
                value={
                  loading ? (
                    <i className="fa-solid fa-arrows-rotate pi-spin" />
                  ) : (
                    dataDash.andamento
                  )
                }
                colorIcon="#EEC137"
                classNameCard="border-2 border-yellow-400"
                classNameBoxIcon="bg-yellow-100 border-2 border-yellow-300"
                classNameLabel="text-yellow-400"
                classNameValue="text-yellow-500"
                labelDescricao="Chamados em andamento"
              />
            </Link>
            <Link
              to="/chamados?filter=retorno"
              style={{ textDecoration: 'none' }}
            >
              <CardDash
                label="Aguardando retorno"
                icon="fa-solid fa-right-left text-md animate-direction"
                value={
                  loading ? (
                    <i className="fa-solid fa-arrows-rotate pi-spin" />
                  ) : (
                    dataDash.retorno
                  )
                }
                colorIcon="#9FADC0"
                classNameCard="border-2 border-slate-400"
                classNameBoxIcon="bg-slate-100 border-2 border-slate-400"
                classNameLabel="text-slate-400"
                classNameValue="text-zinc-500"
                labelDescricao="Chamados aguardando retorno"
              />
            </Link>
            <Link
              to="/chamados?filter=finalizado"
              style={{ textDecoration: 'none' }}
            >
              <CardDash
                label="Finalizado"
                icon="fa-solid fa-check text-xl animate-pulse"
                value={
                  loading ? (
                    <i className="fa-solid fa-arrows-rotate pi-spin" />
                  ) : (
                    dataDash.finalizado
                  )
                }
                colorIcon="#36B37E"
                classNameCard="border-2 border-green-400"
                classNameBoxIcon="bg-green-100 border-2 border-green-400"
                classNameLabel="text-green-400"
                classNameValue="text-green-500"
                labelDescricao="Chamados finalizados"
              />
            </Link>
            <Link
              to="/chamados?filter=SEINF"
              style={{ textDecoration: 'none' }}
            >
              <CardDash
                label="SEINF"
                icon="fa-solid fa-hammer text-xl"
                value={
                  loading ? (
                    <i className="fa-solid fa-arrows-rotate pi-spin" />
                  ) : (
                    dataDash.seinf
                  )
                }
                colorIcon="#0284C7"
                classNameCard="border-2 border-sky-600"
                classNameBoxIcon="bg-sky-200 border-2 border-sky-600"
                classNameLabel="text-sky-600 opacity-70"
                classNameValue="text-sky-600"
                labelDescricao="Chamados SEINF"
              />
            </Link>
            <Link
              to="/chamados?filter=PROEM"
              style={{ textDecoration: 'none' }}
            >
              <CardDash
                label="Proem"
                icon="fa-solid fa-graduation-cap text-xl"
                value={
                  loading ? (
                    <i className="fa-solid fa-arrows-rotate pi-spin" />
                  ) : (
                    dataDash.proem
                  )
                }
                colorIcon="#4547A9"
                classNameCard="border-2 border-indigo-700"
                classNameBoxIcon="bg-indigo-100 border-2 border-indigo-700"
                classNameLabel="text-indigo-400"
                classNameValue="text-indigo-500"
                labelDescricao="Chamados PROEM"
              />
            </Link>
            <Link
              to="/chamados?filter=Predial"
              style={{ textDecoration: 'none' }}
            >
              <CardDash
                label="Manutenção Predial Corretiva"
                icon="fa-solid fa-wrench text-xl"
                value={
                  loading ? (
                    <i className="fa-solid fa-arrows-rotate pi-spin" />
                  ) : (
                    dataDash.manutencaoPredialCivil
                  )
                }
                colorIcon="#047F94"
                classNameCard="border-2 border-cyan-700"
                classNameBoxIcon="bg-cyan-100 border-2 border-cyan-700"
                classNameLabel="text-cyan-400"
                classNameValue="text-cyan-500"
                labelDescricao="Chamados manutenção predial"
              />
            </Link>
            <Link
              to="/chamados?filter=Eletrica"
              style={{ textDecoration: 'none' }}
            >
              <CardDash
                label="Manutenção Elétrica"
                icon="fa-solid fa-bolt-lightning text-xl"
                value={
                  loading ? (
                    <i className="fa-solid fa-arrows-rotate pi-spin" />
                  ) : (
                    dataDash.manutencaoEletrica
                  )
                }
                colorIcon="#4CC8BB"
                classNameCard="border-2 border-teal-700"
                classNameBoxIcon="bg-teal-100 border-2 border-teal-700"
                classNameLabel="text-teal-400"
                classNameValue="text-teal-500"
                labelDescricao="Chamados manutenção elétrica"
              />
            </Link>
          </div>
        ) : (
          <SchoolDashboard selectedSchool={selectedSchool?.value} />
        )}
      </div>
    </div>
  );
};

export default DashboardPage;
