// @ts-nocheck
import React from 'react';
import { ConfigIcon } from '../../assets/svg/ConfigIcon';
import { DashboardIcon } from '../../assets/svg/DashboardIcon';
import { OptionsIcon } from '../../assets/svg/OptionsIcon';
import { ReportIcon } from '../../assets/svg/ReportIcon';
import { ServidoresIcon } from '../../assets/svg/ServidoresIcon';
import { ShieldUserIcon } from '../../assets/svg/ShieldUserIcon';
import { UserIcon } from '../../assets/svg/UserIcon';

// const user = JSON.parse(localStorage.getItem('@project:user') || "");

export const SidebarData = [
  {
    title: 'Dashboard',
    icon: 'fa-solid fa-chart-pie',
    link: '/dashboard',
    role: ['SUPERUSER', 'USER_ADMIN', 'USER_SCHOOL'],
    active: true,
    acoes: {
      create: true,
      update: true,
      read: true,
      delete: true,
    },
  },
  {
    title: 'Escolas',
    icon: 'fa-solid fa-graduation-cap',
    link: '/schools',
    role: ['SUPERUSER', 'USER_ADMIN', 'USER_SCHOOL'],
    active: true,
    acoes: {
      create: true,
      update: true,
      read: true,
      delete: true,
    },
  },
  // {
  //   title: "Órgãos",
  //   icon: <ServidoresIcon />,
  //   link: "/orgaos",
  //   role: [
  //     "SUPERUSER",
  //     "USER_ADMIN",
  //     "USER_SCHOOL",
  //   ],
  // },
  {
    title: 'Chamados',
    icon: 'fa-solid fa-headset',
    link: '/chamados',
    role: ['SUPERUSER', 'USER_ADMIN', 'USER_SCHOOL'],
    active: true,
    acoes: {
      create: true,
      update: true,
      read: true,
      delete: true,
    },
  },
  {
    title: 'Perfil',
    icon: 'fa-solid fa-users-gear',
    link: '/perfis',
    role: ['SUPERUSER', 'USER_ADMIN', 'USER_SCHOOL'],
    active: true,
    acoes: {
      create: true,
      update: true,
      read: true,
      delete: true,
    },
  },
  {
    title: 'Usuarios',
    icon: 'fa-solid fa-user',
    link: '/usuarios',
    role: ['SUPERUSER', 'USER_ADMIN', 'USER_SCHOOL'],
    active: true,
    acoes: {
      create: true,
      update: true,
      read: true,
      delete: true,
    },
  },
  {
    title: 'Serviços',
    icon: 'fa-solid fa-gear',
    link: '/config',
    role: ['SUPERUSER', 'USER_ADMIN', 'USER_SCHOOL'],
    active: true,
    acoes: {
      create: true,
      update: true,
      read: true,
      delete: true,
    },
  },
  {
    title: 'Empresas',
    icon: 'fa-solid fa-building',
    link: '/empresas',
    role: ['SUPERUSER', 'USER_ADMIN', 'USER_SCHOOL'],
    active: true,
    acoes: {
      create: true,
      update: true,
      read: true,
      delete: true,
    },
  },
  {
    title: 'Lotes',
    icon: 'fa-solid fa-cart-flatbed',
    link: '/lotes',
    role: ['SUPERUSER', 'USER_ADMIN', 'USER_SCHOOL'],
    active: true,
    acoes: {
      create: true,
      update: true,
      read: true,
      delete: true,
    },
  },
  {
    title: 'Recursos',
    icon: 'fa-solid fa-layer-group',
    link: '/recursos',
    role: ['SUPERUSER', 'USER_ADMIN', 'USER_SCHOOL'],
    active: true,
    acoes: {
      create: true,
      update: true,
      read: true,
      delete: true,
    },
  },
  {
    title: 'Instituições',
    icon: 'fa-solid fa-building-columns',
    link: '/instituicoes',
    role: ['SUPERUSER', 'USER_ADMIN', 'USER_SCHOOL'],
    active: true,
    acoes: {
      create: true,
      update: true,
      read: true,
      delete: true,
    },
  },
  {
    title: 'Relatórios',
    icon: 'fa-solid fa-file-contract',
    link: '/relatorio',
    role: ['SUPERUSER', 'USER_ADMIN', 'USER_SCHOOL'],
    active: true,
    acoes: {
      create: true,
      update: true,
      read: true,
      delete: true,
    },
  },
];

// // eslint-disable-next-line no-return-assign
// export const result = SidebarData.filter((role) => user.role == role.role);
