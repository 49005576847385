/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import styled from 'styled-components';
import style from './Form.module.css';

const IconInput = styled.span`
  border: none;
  background-color: transparent;
  color: ${(props) => props.color};
`;

const InputTextStyle = styled(InputText)`
  font-size: ${(props) => props.fontSize};
  border: none;
  &:hover.p-inputtext:enabled {
    border: none !important;
  }
  &:focus.p-inputtext:enabled {
    box-shadow: none;
    border: none !important;
  }
  height: ${(props) => props.height};
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  width: 97.5%;
  border-radius: 8px;
  padding-left: 0.5rem;
  margin-top: 0.5rem;
`;

const InputMaskStyle = styled(InputMaskComp)`
  color: ${(props) => props.color}
  font-size: ${(props) => props.fontSize};
  height: ${(props) => props.height};
  border-width: 0px !important;
  &:hover {
    border-width: 0px !important;
  }
  &:hover.inputtext {
    border-width: 0px !important;
  }
  &:hover.p-inputtext:enabled {
    border-width: 0px !important;
  }
  &:focus.p-inputtext:enabled {
    border: none !important;
    box-shadow: none !important;
  }
`;

const Label = styled.label`
  color: ${(props) => props.color};
  font-weight: bold;
`;

export const Input = (props) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error, clearError } =
    useField(props.name);

  const {
    handleViewPass,
    conditionIcon,
    icon,
    iconChange,
    label,
    colorTextLabel,
    rightIcon,
    colors,
    ...rest
  } = props;

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <div>
      {label && (
        <Label htmlFor={props.id} color={colors.text}>
          {label}
        </Label>
      )}
      <div
        className={`p-inputgroup ${style['p-inputgroup']} ${
          error ? style.invalid : null
        }`}
      >
        {icon && !rightIcon && (
          <IconInput className="p-inputgroup-addon">
            <i
              onClick={handleViewPass}
              style={{color: colors.text}}
              className={conditionIcon ? icon : iconChange}
            />
          </IconInput>
        )}
        <InputTextStyle
          ref={inputRef}
          onClick={(e) => {
            clearError();
          }}
          onFocus={(e) => {
            e.currentTarget.parentElement.classList.add(style.active);
          }}
          onBlur={(e) => {
            e.currentTarget.parentElement.classList.remove(style.active);
          }}
          fontSize="16px"
          style={{backgroundColor: colors.background, color: colors.text}}
          type={props.type}
          value={defaultValue}
          {...rest}
        />
        {icon && rightIcon && (
          <IconInput className="p-inputgroup-addon" color={colorTextLabel}>
            <i
              onClick={handleViewPass}
              style={{color: colors.text}}
              className={`${conditionIcon ? icon : iconChange}`}
            />
          </IconInput>
        )}
      </div>
      {error && (
        <span style={{ color: '#ef3a5d', display: 'block', fontSize: '15px' }}>
          {error}
        </span>
      )}
    </div>
  );
};

export const InputNumberUn = (props) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error, clearError } =
    useField(props.name);

  const {
    handleViewPass,
    conditionIcon,
    icon,
    iconChange,
    label,
    colorTextLabel,
    rightIcon,
    colors,
    ...rest
  } = props;

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current.getInput(),
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <div>
      {label && (
        <Label htmlFor={props.id} color={colors.text}>
          {label}
        </Label>
      )}
      <div
        className={`p-inputgroup ${style['p-inputgroup']} ${
          error ? style.invalid : null
        }`}
      >
        {icon && !rightIcon && (
          <IconInput className="p-inputgroup-addon">
            <i
              onClick={handleViewPass}
              style={{color: colors.text}}
              className={conditionIcon ? icon : iconChange}
            />
          </IconInput>
        )}
        <InputNumber
          ref={inputRef}
          onClick={(e) => {
            clearError();
          }}
          onFocus={(e) => {
            e.currentTarget.parentElement.classList.add(style.active);
          }}
          onBlur={(e) => {
            e.currentTarget.parentElement.classList.remove(style.active);
          }}
          fontSize="16px"
          style={{backgroundColor: colors.background, color: colors.text}}
          type={props.type}
          value={defaultValue}
          {...rest}
        />
        {icon && rightIcon && (
          <IconInput className="p-inputgroup-addon" color={colorTextLabel}>
            <i
              onClick={handleViewPass}
              style={{color: colors.text}}
              className={`${conditionIcon ? icon : iconChange}`}
            />
          </IconInput>
        )}
      </div>
      {error && (
        <span style={{ color: '#ef3a5d', display: 'block', fontSize: '15px' }}>
          {error}
        </span>
      )}
    </div>
  );
};

import { InputMask as InputMaskComp } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';

export function InputMask(props) {
  const inputRef = useRef(null);
  const { fieldName, registerField, value, error, clearError } = useField(
    props.name,
  );

  const {
    handleViewPass,
    conditionIcon,
    icon,
    iconChange,
    label,
    colorTextLabel,
    rightIcon,
    colors,
    ...rest
  } = props;

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <div>
      {label && (
        <Label htmlFor={props.id} color={colors.text}>
          {label}
        </Label>
      )}
      <div
        className={`p-inputgroup ${style['p-inputgroup']} ${
          error ? style.invalid : null
        }`}
      >
        {props.icon && (
          <IconInput className="p-inputgroup-addon">
            <i style={{color: colors.text}} className={icon} />
          </IconInput>
        )}
        <InputMaskStyle
          ref={inputRef}
          onClick={(e) => {
            clearError();
          }}
          onFocus={(e) => {
            e.currentTarget.parentElement.classList.add(style.active);
          }}
          onBlur={(e) => {
            e.currentTarget.parentElement.classList.remove(style.active);
          }}
          fontSize="16px"
          style={{backgroundColor: colors.background, color: colors.text}}
          value={value}
          name={props.name}
          autoClear={false}
          {...rest}
        />
      </div>
      {error && (
        <span style={{ color: '#ef3a5d', display: 'block', fontSize: '15px' }}>
          {error}
        </span>
      )}
    </div>
  );
}
