import React from 'react';
import ReactLoading from 'react-loading';

import api from '../../services/api';

import { useOAuth } from '../../hooks/oauth';

const Callback = () => {
  const { signIn } = useOAuth();

  function getUserSSO(accessToken: string) {
    api
      .get("https://sisged.seed.ap.gov.br/api/user", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        signIn(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  function getQueryVariable(variable: string) {
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split("=");
      if (pair[0] == variable) { return pair[1]; }
    }
    return (false);
  }

  React.useEffect(() => {
    function callback() {
      api
        .post(`https://sisged.seed.ap.gov.br/oauth/token`, {
          grant_type: "authorization_code",
          client_id: process.env.REACT_APP_CLIENTE_ID,
          client_secret: process.env.REACT_APP_CLIENTE_SECRET,
          redirect_uri: process.env.REACT_APP_REDIRECT_URL,
          code: getQueryVariable('code'),
        })
        .then((res) => {
          sessionStorage.setItem("@assessoramento:access_token", res.data.access_token);
          getUserSSO(res.data.access_token);
        })
        .catch((e) => console.log(e));
    }

    callback();
  }, []);

  return (
    <>
      <div style={{
        height: '100vh', marginTop: '-5%', display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: "center",
      }}
      >
        <ReactLoading type="spokes" color="#000" height="50px" width="50px" />
        <h1 style={{ fontSize: 24, fontWeight: "bold" }}>Aguarde um momento, logo você será redirecionado...</h1>
      </div>
    </>
  );
};

export default Callback;
