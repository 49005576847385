import React, { useState, useRef } from 'react';
import { FormGroup } from 'reactstrap';
import ReactLoading from 'react-loading';
import { Button } from 'primereact/button';
import styled, { ThemeContext } from 'styled-components';
import LogoSeedAm from '../../assets/logo-seed.png';
import { useAuth } from '../../hooks/auth';
import { Input, InputMask } from '../../components/Form';
import { cpf_mask } from '../../utils/cpf_mask';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as yup from 'yup';
import usePersistedState from '../../utils/usePersistedState';
import light from '../../styles/themes/light';
import dark from '../../styles/themes/dark';

interface TypesContainer {
  backgroundColor: string,
  btnColor: string,
  btnColorHover: string
}

interface TypesBanner {
  backgroundColor: string
}

const ContainerLogin = styled.div<TypesContainer>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
  place-items: center;
  background-color: ${props => props.backgroundColor};

  .btn-login {
    background-color: ${props => props.btnColor};
    border: none;
    height: 40px;
  }
  .btn-login:hover {
    background-color: ${props => props.btnColorHover};
    border: none;
  }
  .Container-Form {
    width: 65%;
  }
  .Logo-Adm-Mobile {
    display: none;
  }
  @media (max-width: 620px) {
    grid-template-columns: 1fr;
    .Banner-Logo {
      display: none;
    }
    .Logo-Adm-Mobile {
      display: block;
      width: 50vw;
      min-width: 200px;
      margin: auto;
    }
  }
`;



const Banner = styled.div<TypesBanner>`
  width: 50vw;
  height: 100vh;
  background-color: ${props => props.backgroundColor};
  display: grid;
  place-items: center;
  .Logo-Adm-Login {
    width: 40%;
  }
`;

interface Props {
  toggleTheme(title: string): void;
}

const Login: React.FC<Props> = ({ toggleTheme }) => {
  const { signIn, loading } = useAuth();
  const [viewPass, setViewPass] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const { colors, title } = React.useContext(ThemeContext);

  console.log(title)
  const handleSubmit = React.useCallback((data: any) => {
    async function handlerValidation() {
      try {
        const schema = yup.object({
          cpf: yup.string().required('Digite o seu CPF'),
          password: yup.string().required('Digite sua senha'),
        });

        await schema
          .validate(data)
          .then(() => signIn({ cpf: data.cpf, password: data.password }))
          .catch(() => null);

        await schema.validate(data, {
          abortEarly: false,
        });
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          const errorMensages: any = {};
          err.inner.forEach((error: yup.ValidationError) => {
            errorMensages[`${error.path}`] = error.message;
          });
          formRef.current?.setErrors(errorMensages);
        }
      }
    }
    handlerValidation();
  }, []);

  const handleViewPass = React.useCallback(() => {
    setViewPass((e) => !e);
  }, []);

  return (
    <ContainerLogin btnColor={colors.secundary} btnColorHover={colors.primary_btn_hover} backgroundColor={colors.background} className="Container-Login-Responsive">
      <Banner backgroundColor={colors.primary} className="Banner-Logo">
        <img src={LogoSeedAm} className="Logo-Adm-Login" />
      </Banner>
      <div className="Container-Form">
        <Form
          onSubmit={handleSubmit}
          ref={formRef}
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <img src={LogoSeedAm} className="Logo-Adm-Mobile" />
          <FormGroup>
            <InputMask
              name="cpf"
              icon="fa-solid fa-user"
              colorIcon={colors.text}
              colors={colors}
              label="CPF"
              id="cpf"
              mask="999.999.999-99"
              colorTextLabel="#454"
              height="40px"
              placeholder="Digite seu CPF"
            />
          </FormGroup>
          <FormGroup>
            <Input
              name="password"
              label="Senha"
              id="password"
              type={viewPass ? 'text' : 'password'}
              handleViewPass={handleViewPass}
              conditionIcon={viewPass}
              rightIcon={true}
              colors={colors}
              iconChange="fa-solid fa-eye"
              icon="fa-solid fa-eye-slash"
              mask="999-999-99"
              height="40px"
              colorTextLabel="#454"
              placeholder="*******"
            />
          </FormGroup>
          {loading ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ReactLoading
                type="spin"
                height="5%"
                width="5%"
                color="#36B37E"
              />
            </div>
          ) : (
            <Button
              type="submit"
              className="btn-login"
              style={{
                width: '100%',
              }}
              label="Entrar"
            />
          )}
        </Form>
      </div>
    </ContainerLogin>
  );
};

export default Login;
