import React, { useState } from 'react';
import { ThemeContext } from 'styled-components';
import Header from '../../components/Header';
import SchoolSearchInput from '../../components/SchoolSearchInput';
import SchoolsTable from '../../components/SchoolsTable';
import { SchoolContext, SchoolsProvider } from '../../context/schoolContext';
import { Skeleton } from 'primereact/skeleton';
import { Form } from '@unform/web';
import { InputNumberUn } from '../../components/Form';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { Button } from 'primereact/button';
import './style.css';

const Schools = ({ showSidebar, setSidebar, toggleTheme }: any) => {
  const { schools, getSchools } = React.useContext(SchoolContext);
  const { colors } = React.useContext(ThemeContext);

  const form = React.useRef<any>();

  const [selectedSchool, setSelectedSchool] = useState<any>();

  const [checkedDiningHall, setCheckedDiningHall] = useState<boolean>();
  const [checkedSportsHall, setCheckedSportsHall] = useState<boolean>();
  const [checkedInternetConnection, setCheckedInternetConnection] =
    useState<boolean>();

  const [type_school, setTypeSchool] = useState<string>();
  const [divScroll, setDivScroll] = useState({
    scrollTop: 0,
    scrollTopMax: 0,
  });

  function handleSubmit(dataForm: any) {
    dataForm = {
      classrooms: dataForm.classrooms,
      bathrooms: dataForm.bathrooms,
      school_area: dataForm.school_area.split('m²').join(''),
      dining_hall: checkedDiningHall,
      sports_court: checkedSportsHall,
      internet_connection: checkedInternetConnection,
      immobile_condition: type_school,
    };

    toast
      .promise(api.patch(`/schools/${selectedSchool.value.id}`, dataForm), {
        pending: 'Alterando...',
        success: 'Escola alterada com sucesso',
        error: 'Ocorreu um erro ao alterar a escola',
      })
      .then(() => {
        getSchools();
      });
  }

  const handlerScroll = React.useCallback((e: any) => {
    setDivScroll({
      scrollTop: e.target.scrollTop,
      scrollTopMax: e.target.scrollHeight - e.target.offsetHeight,
    });
  }, []);

  React.useEffect(() => {
    setCheckedDiningHall(selectedSchool?.value?.dining_hall);
    setCheckedInternetConnection(selectedSchool?.value?.internet_connection);
    setCheckedSportsHall(selectedSchool?.value?.sports_court);
    setTypeSchool(selectedSchool?.value?.immobile_condition);
  }, [selectedSchool]);

  return (
    <>
      <SchoolsProvider>
        <Header
          title="Escolas"
          icon="fa-solid fa-graduation-cap"
          showSidebar={showSidebar}
          setSidebar={setSidebar}
          toggleTheme={toggleTheme}
        />

        <SchoolSearchInput
          style={{
            marginTop: '40px',
          }}
          selectedSchool={selectedSchool}
          setSelectedSchool={setSelectedSchool}
          schools={schools}
        />
        {/* <SchoolsTable /> */}
        <div
          style={{
            backgroundColor: colors.background,
            border: `.5px ${colors.border} solid`,
            height: '70vh',
          }}
          className="drop-shadow-md mt-4 grid place-items-center rounded-md"
        >
          {!selectedSchool?.value ? (
            <div className="grid gap-2 h-full w-full p-4">
              <div className="place-self-center grid gap-2">
                <i
                  className="fa-solid fa-magnifying-glass text-4xl md:hidden text-center"
                  style={{ color: colors.primary }}
                ></i>
                <div className="flex content-center gap-4 justify-self-center">
                  <i
                    className="fa-solid fa-magnifying-glass hidden text-4xl md:inline"
                    style={{ color: colors.primary }}
                  ></i>
                  <span
                    className="text-xl grid align-items-center pt-2 font-medium text-center md:text-left"
                    style={{ color: colors.text }}
                  >
                    Pesquise por uma escola/instituição
                  </span>
                </div>
                <span
                  className="lg:text-lg sm:text-base text-center"
                  style={{ color: colors.text }}
                >
                  Utilize o campo de texto na parte superior direita para
                  pesquisar por uma escola/instituição.
                </span>
              </div>
            </div>
          ) : (
            <div
              className="w-full h-full overflow-y-scroll overflow-x-hidden scroll-bar"
              onScroll={handlerScroll}
              style={{
                maxHeight: '100%',
                scrollSnapType: 'y mandatory',
                scrollBehavior: 'smooth',
              }}
            >
              <div
                className="grid gap-2 h-full w-full px-4 pb-8 pt-6 snap-center overflow-x-scroll scroll-bar-none"
                id="escola"
              >
                <div
                  className="flex justify-between h-20 rounded-md px-4"
                  style={{ border: `1.5px ${colors.border} solid` }}
                >
                  <div
                    className="flex gap-2"
                    style={{ justifyContent: 'center' }}
                  >
                    <i
                      style={{ color: colors.primary }}
                      className="fa-solid fa-school self-center text-2xl"
                    />
                    <span
                      style={{ color: colors.text }}
                      className="self-center text-base font-medium pt-1"
                    >
                      {selectedSchool.label}
                    </span>
                  </div>
                  <div className="flex">
                    <span
                      style={{ color: colors.text }}
                      className="self-center font-medium"
                    >
                      Localização:{' '}
                      <span className="font-normal">
                        {selectedSchool.value?.school_type?.name}
                      </span>
                    </span>
                  </div>
                </div>
                <div className="grid px-4">
                  <div
                    className="h-16 flex gap-2 align-items-center"
                    style={{ color: colors.text }}
                  >
                    <i
                      style={{ color: colors.primary }}
                      className="fa-solid fa-location-dot text-xl"
                    />
                    {`${selectedSchool?.value?.street}, ${selectedSchool?.value?.number} ${selectedSchool?.value?.district}. ${selectedSchool?.value?.zip_code} ${selectedSchool?.value?.city}`}
                  </div>{' '}
                  <div
                    style={{ color: colors.text }}
                    className="h-16 flex gap-2 align-items-center"
                  >
                    <i
                      style={{ color: colors.primary }}
                      className="fa-solid fa-building text-xl"
                    />
                    {selectedSchool?.value?.cnpj}
                  </div>
                  <div
                    style={{ color: colors.text }}
                    className="h-16 flex gap-2 align-items-center"
                  >
                    <i
                      style={{ color: colors.primary }}
                      className="fa-solid fa-at text-xl"
                    />
                    {selectedSchool?.value?.email}
                  </div>
                  <div
                    style={{ color: colors.text }}
                    className="h-16 flex gap-2 align-items-center"
                  >
                    <i
                      style={{ color: colors.primary }}
                      className="fa-solid fa-user-tie text-xl"
                    />
                    {selectedSchool?.value?.manager}
                  </div>
                  <div
                    style={{ color: colors.text }}
                    className="h-16 flex gap-2 align-items-center"
                  >
                    <i
                      style={{ color: colors.primary }}
                      className="fa-solid fa-phone text-xl"
                    />
                    {selectedSchool?.value?.phone}
                  </div>
                </div>
              </div>
              <div
                style={{ gridTemplateRows: '.2fr 1fr', color: colors.text }}
                id="infra"
                className="grid gap-2 h-full w-full px-4 pb-8 pt-6 snap-center"
              >
                <h4
                  className="px-3 font-semibold"
                  style={{ fontFamily: 'roboto', color: colors.primary }}
                >
                  Infraestrutura da escola
                </h4>
                <Form
                  ref={form}
                  onSubmit={handleSubmit}
                  className="grid min-[528px]:grid-cols-2 min-[728px]:grid-cols-3 gap-4 px-4 h-full overflow-y-scroll scroll-bar-none"
                >
                  <div>
                    <h6>Número de salas</h6>
                    <InputNumberUn
                      colors={colors}
                      name="classrooms"
                      value={selectedSchool?.value?.classrooms}
                      className="w-full"
                    />
                  </div>
                  <div>
                    <h6>Banheiros</h6>
                    <InputNumberUn
                      colors={colors}
                      name="bathrooms"
                      value={selectedSchool?.value?.bathrooms}
                      className="w-full"
                    />
                  </div>
                  <div>
                    <h6>Condição do imóvel</h6>
                    <Dropdown
                      value={type_school}
                      options={[
                        { value: 'CEDIDO', label: 'CEDIDO' },
                        { value: 'ALUGADO', label: 'ALUGADO' },
                        { value: 'PROPRIO', label: 'PROPRIO' },
                      ]}
                      name="label"
                      className="w-full"
                      onChange={(e: any) => {
                        setTypeSchool(e.value);
                      }}
                    />
                  </div>
                  <div>
                    <h6>Possui Quadra?</h6>
                    <div className="flex gap-4">
                      <div className="flex gap-1 align-items-center">
                        <Checkbox
                          checked={checkedSportsHall}
                          onChange={() => setCheckedSportsHall(true)}
                        />
                        SIM
                      </div>
                      <div className="flex gap-1 align-items-center">
                        <Checkbox
                          checked={!checkedSportsHall}
                          onChange={() => setCheckedSportsHall(false)}
                        />
                        NÃO
                      </div>
                    </div>
                  </div>
                  <div>
                    <h6>Possui Cozinha?</h6>
                    <div className="flex gap-4">
                      <div className="flex gap-1 align-items-center">
                        <Checkbox
                          checked={checkedDiningHall}
                          onChange={() => setCheckedDiningHall(true)}
                        />
                        SIM
                      </div>
                      <div className="flex gap-1 align-items-center">
                        <Checkbox
                          checked={!checkedDiningHall}
                          onChange={() => setCheckedDiningHall(false)}
                        />
                        NÃO
                      </div>
                    </div>
                  </div>
                  <div>
                    <h6>Possui Internet?</h6>
                    <div className="flex gap-4">
                      <div className="flex gap-1 align-items-center">
                        <Checkbox
                          checked={checkedInternetConnection}
                          onChange={() => setCheckedInternetConnection(true)}
                        />
                        SIM
                      </div>
                      <div className="flex gap-1 align-items-center">
                        <Checkbox
                          checked={!checkedInternetConnection}
                          onChange={() => setCheckedInternetConnection(false)}
                        />
                        NÃO
                      </div>
                    </div>
                  </div>
                  <div>
                    <h6>Área do imóvel</h6>
                    <InputNumberUn
                      colors={colors}
                      name="school_area"
                      value={selectedSchool?.value?.school_area}
                      suffix="m²"
                      className="w-full"
                    />
                  </div>
                </Form>
                <div className="flex justify-end">
                  <Button
                    onClick={() => form.current.submitForm()}
                    className="btn-cadastro"
                  >
                    Atualizar
                  </Button>
                </div>
              </div>
            </div>
          )}
          {selectedSchool?.value && divScroll.scrollTop == 0 && (
            <a
              href="#infra"
              className="flex justify-center w-full h-10 fixed bottom-0 rounded-br-md rounded-bl-md cursor-pointer text-decoration-none"
            >
              <i
                style={{ color: colors.text }}
                className="fa-solid fa-angles-down animate-bounce"
              />
            </a>
          )}
          {selectedSchool?.value && divScroll.scrollTop > 0 && (
            <a
              href="#escola"
              className="flex justify-center w-full h-10 fixed bottom-0 rounded-br-md rounded-bl-md cursor-pointer text-decoration-none"
            >
              <i
                style={{ color: colors.text }}
                className="fa-solid fa-angles-up animate-bounce"
              />
            </a>
          )}
        </div>
      </SchoolsProvider>
    </>
  );
};

export default Schools;
