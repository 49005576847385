/* eslint-disable */
import moment from 'moment';
import React, { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { ServiceTypesContext } from '../../context/serviceCallTypesContext';
import api from '../../services/api';
import { ISubtype } from '../../types/ISubtype';
import { IType } from '../../types/IType';
import { money_mask } from '../../utils/money_mask';
import { price_to_number } from '../../utils/price_to_number';
import { IReferralType } from '../ReferralTypeTable';
import { ThemeContext } from 'styled-components';

const ServiceCallDefinitionModal = ({
  modal,
  toggle,
  loadServiceCall,
  service_call_id,
}: any) => {
  const [status, setStatus] = useState<any>({
    status: null,
  });
  const [priority, setPriority] = useState({
    priority: 'Normal',
  });
  const [typeSubtype, setTypeSubtype] = useState<any>({
    type: 0,
    subtype: 0,
  });

  const [selectedType, setSelectedType] = useState<IType>();
  const [serviceCall, setServiceCall] = useState<any>(null);

  const { colors } = useContext(ThemeContext)

  const [data, setData] = useState<ISubtype[]>();
  const [referralType, setReferralType] = useState<IReferralType[]>([]);
  const [referral, setRefferal] = useState({
    description: '',
    institution_id: 0,
    referral_type_id: 0,
    service_call_id: 0,
  });
  const [resources, setResources] = useState([]);
  const [checkboxValue, setCheckboxValue] = useState('');
  const [isChecked, setIsChecked] = useState({
    resourceCheck: false,
    allotmentCheck: false,
  });

  const [value, setValue] = useState('');
  const [resourceId, setResourceId] = useState<number | any>(0);
  const [allotmentId, setAllotmentId] = useState<number | any>(0);
  const [schoolAllotments, setSchoolAllotments] = useState<any>();
  const [allotment, setAllotment] = useState<any>();
  const [error, setError] = useState<any>(false);
  const [errorMsg, setErrorMsg] = useState({ value: '', allotment: '' });
  const [msgError, setMsgError] = useState(null);
  const [loading, setLoading] = useState(false);

  const { types } = useContext(ServiceTypesContext);

  function getServiceCall() {
    setLoading(true);
    api
      .get(`service-call/getServiceCallByID/${service_call_id}`)
      .then(({ data }) => {
        setServiceCall(data);
        setStatus((e: any) => ({
          status: e.status == null ? data.status : e.status,
        }));
        setPriority({
          priority: data.priority,
        });
        setTypeSubtype({
          type: data.type,
          subtype: data.subtype,
        });
      });
  }
  async function getResources() {
    setLoading(true);
    try {
      const { data } = await api.get('/resource');
      setResources(data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }

  async function getSubtypes() {
    setLoading(true);
    if (selectedType) {
      const { data } = await api.get(
        `/service-call-subtype/${selectedType?.id}`,
      );
      setData(data);
    }

    setTypeSubtype({ ...typeSubtype, subtype: 0 });
  }

  async function getReferralTypes() {
    const { data } = await api.get(
      `/referrals-type/${referral.institution_id}`,
    );
    setReferralType(data);
    setLoading(false);
  }
  function changeStatus() {
    toast
      .promise(
        api.patch(`service-call/${service_call_id}`, {
          ...status,
          type: selectedType?.id === 0 ? null : selectedType?.id,
          subtype: typeSubtype.subtype === 0 ? null : typeSubtype.subtype,
          ...priority,
          value,
          resource_id: resourceId,
          allotment_id:
            !resourceId &&
              (selectedType?.type === 'MANUTENÇÃO PREDIAL CIVIL' ||
                selectedType?.type === 'MANUTENÇÃO ELÉTRICA')
              ? filteredAllotmentByType[0].allotment_id
              : null,
        }),
        {
          pending: 'Alterando status do chamado...',
          success: 'Status alterado com sucesso',
          error: 'Ocorreu um erro ao alterar o status',
        },
      )
      .then(() => {
        loadServiceCall();
        getServiceCall();
        setError(false);
        toggle();
      });
  }

  async function getAllotmentId() {
    if (serviceCall) {
      const { data } = await api.get(
        `/allotment-school/school/${serviceCall?.school_id}`,
      );
      setSchoolAllotments(data);
    }
  }

  function formatReal(value: any) {
    const money = value
      .replace('R$', '')
      .split('.')
      .join('')
      .split(',')
      .join('');
    return money;
  }

  function checkAvailableValue(value: any) {
    let handlerAllotmentValue = {
      available_value: '',
    };
    schoolAllotments?.forEach((item: any) => {
      if (Array.isArray(item?.allotment)) {
        item?.allotment.forEach((allot: any) => {
          if (
            selectedType?.type ==
            item?.allotment?.allotment_type?.type.toUpperCase()
          ) {
            handlerAllotmentValue.available_value =
              item?.allotment?.available_value;
          }
        });
      }
      selectedType?.type == item?.allotment?.allotment_type?.type?.toUpperCase()
        ? (handlerAllotmentValue.available_value =
          item?.allotment?.available_value)
        : null;
    });
    if (
      selectedType?.type?.includes('MANUTENÇÃO') &&
      selectedType?.type != 'MANUTENÇÃO GERAL'
    ) {
      let available_value = +formatReal(handlerAllotmentValue.available_value);
      let value_target = +formatReal(value);
      available_value >= value_target
        ? (setError(false), setErrorMsg(e => ({ ...e, value: '' })))
        : (setError(true),
          setErrorMsg(e => ({ ...e, value: 'Valor maior que o disponível' })));
    }
  }

  function checkAllotment() {
    let inAllotment = false;
    const resources = [
      'MANUTENÇÃO GERAL',
      'Reforma',
      'Construção',
      'Ampliação',
    ];
    schoolAllotments?.forEach((item: any) => {
      if (
        selectedType?.type ==
        item?.allotment?.allotment_type?.type?.toUpperCase()
      ) {
        inAllotment = true;
      } else {
        resources.forEach(resource => {
          selectedType?.type === resource ? (inAllotment = true) : null;
        });
      }
    });

    !inAllotment && selectedType?.type != undefined
      ? (setError(true),
        setErrorMsg(e => ({
          ...e,
          allotment: 'Você não está inserido neste lote',
        })))
      : (setError(false), setErrorMsg(e => ({ ...e, allotment: '' })));
  }

  const filteredAllotmentByType = schoolAllotments?.filter(
    (item: any) =>
      item?.allotment?.allotment_type?.type ===
      selectedType?.type?.toUpperCase(),
  );

  const filteredResourceByType_PROEM: any = resources?.filter(
    (item: any) =>
      item?.name === 'PROEM' &&
      selectedType?.type?.toUpperCase() === 'MANUTENÇÃO GERAL',
  );

  const filteredResourceByType_SEINF: any = resources?.filter(
    (item: any) => item?.name === 'SEINF',
  );

  useEffect(() => {
    getServiceCall();
    getResources();
    getReferralTypes();
    getSubtypes();
    getAllotmentId();
    checkAllotment();
  }, [selectedType, referral.institution_id]);

  useEffect(() => {
    if (selectedType?.type === 'MANUTENÇÃO GERAL') {
      setResourceId(filteredResourceByType_PROEM[0]?.id);
    } else if (
      selectedType?.type?.toUpperCase() === 'REFORMA' ||
      selectedType?.type?.toUpperCase() === 'CONSTRUÇÃO' ||
      selectedType?.type?.toUpperCase() === 'AMPLIAÇÃO'
    ) {
      setResourceId(filteredResourceByType_SEINF[0]?.id);
    } else {
      setResourceId(null);
    }
  }, [
    filteredAllotmentByType,
    filteredResourceByType_PROEM,
    filteredResourceByType_SEINF,
    resourceId,
    // schoolAllotments
  ]);
  useEffect(() => {

    getAllotmentId();
  }, [modal, selectedType?.type]);

  return (
    <Dialog
      header="Definições do chamado"
      className="w-50"
      visible={modal}
      headerStyle={{backgroundColor: colors.container, color: colors.text}}
      contentStyle={{backgroundColor: colors.container, color: colors.text}}
      onHide={() => {
        setStatus({ status: null });
        getServiceCall();
        toggle();
      }}
    >
      <ModalBody>
        <Row>
          <Col md={6}>
            <Form onSubmit={e => e.preventDefault()}>
              <FormGroup>
                <Label>Status:</Label>
                <Input
                  value={status.status}
                  type="select"
                  onChange={e => setStatus({ status: e.target.value })}
                  style={{backgroundColor: colors.container, color: colors.text}}
                >
                  <option>-</option>
                  <option value="Aberto">Aberto</option>
                  <option value="Em Andamento">Em Andamento</option>
                  <option value="Aguardando Retorno">Aguardando Retorno</option>
                  <option value="Finalizado">Finalizado</option>
                </Input>
              </FormGroup>
            </Form>
          </Col>
          <Col md={6}>
            <Form onSubmit={e => e.preventDefault()}>
              <FormGroup>
                <Label>Prioridade:</Label>
                <Input
                  value={priority.priority}
                  type="select"
                  onChange={e => setPriority({ priority: e.target.value })}
                  style={{backgroundColor: colors.container, color: colors.text}}
                  
                >
                  <option>-</option>
                  <option value="Normal">Normal</option>
                  <option value="Alta">Alta</option>
                  <option value="Urgente">Urgente</option>
                </Input>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <Form onSubmit={e => e.preventDefault()}>
          <FormGroup>
            <Label>Classificação :</Label>
            <Input
              className="mb-2"
              value={typeSubtype?.type?.id}
              type="select"
              onChange={e => {
                setSelectedType(JSON.parse(e.target.value));
              }}
              style={{backgroundColor: colors.container, color: colors.text}}
            >
              <option value="0">-</option>
              {types?.map(type => (
                <option value={JSON.stringify(type)}>{type.type}</option>
              ))}
            </Input>
            {error && errorMsg.allotment && (
              <span className="text-red-500 pl-2">{errorMsg.allotment}</span>
            )}
          </FormGroup>
          <FormGroup style={{ display: 'grid' }}>
            {selectedType && errorMsg.allotment.length < 1 ? (
              <div className="mb-2">
                <Label>Subclassificação:</Label>
                <Input
                  value={typeSubtype?.subtype?.id}
                  type="select"
                  disabled={error && errorMsg.allotment}
                  style={{backgroundColor: colors.container, color: colors.text}}
                  onChange={e =>
                    setTypeSubtype({
                      ...typeSubtype,
                      subtype:
                        parseInt(e.target.value) === 0
                          ? null
                          : parseInt(e.target.value),
                    })
                  }
                >
                  <option value="0">-</option>
                  {selectedType?.service_call_subtype?.map((subtype: any) => (
                    <option value={subtype.id}>{subtype.subtype}</option>
                  ))}
                </Input>
              </div>
            ) : null}
            <Label>Valor:</Label>
            <Input
              type="text"
              value={value}
              disabled={error && errorMsg.allotment}
              style={{backgroundColor: colors.container, color: colors.text}}
              onChange={e => {
                checkAvailableValue(e.target.value);
                setValue(money_mask(e.target.value));
              }}
            />
            {error && errorMsg.value && (
              <span className="text-red-500 pl-2">{errorMsg.value}</span>
            )}
            <Button
              color="primary"
              className="px-5 py-2 btn-cadastro"
              style={{
                marginTop: '20px',
                justifySelf: 'end',
              }}
              label="Salvar"
              onClick={() => {
                changeStatus();
              }}
              disabled={error || loading}
            />
          </FormGroup>
        </Form>
      </ModalBody>
    </Dialog>
  );
};

export default ServiceCallDefinitionModal;
