import React from 'react';
import ReactLoading from 'react-loading';

const OAuth = () => {
  function makeid(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  React.useEffect(() => {
    const query = {
      client_id: process.env.REACT_APP_CLIENTE_ID,
      redirect_uri: process.env.REACT_APP_REDIRECT_URL,
      response_type: "code",
      scope: "",
      state: makeid(40),
    };
    const url = `https://sisged.seed.ap.gov.br/oauth/authorize?client_id=${query.client_id}&redirect_uri=${query.redirect_uri}&response_type=${query.response_type}&scope=${query.scope}&state=${query.state}`;

    window.location.assign(url);
  }, []);

  return (
    <>
      <div style={{
        height: '100vh', marginTop: '-5%', display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: "center",
      }}
      >
        <ReactLoading type="spokes" color="#000" height="50px" width="50px" />
        <h1 style={{ fontSize: 24, fontWeight: "bold" }}>Autenticação em andamento...</h1>
      </div>
    </>
  );
};

export default OAuth;
