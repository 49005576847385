import React, {
  createContext, ReactNode, useEffect, useState,
} from 'react';
import { toast } from 'react-toastify';
import api from '../services/api';
import { IProfile } from '../types/IProfile';

import { useAuth } from '../hooks/auth';

interface ProfileContextType {
  profiles: IProfile[];
  loadProfiles: () => void;
  loading: boolean;
}

interface SchoolsProviderProps {
  children: ReactNode;
}

export const ProfileContext = createContext({} as ProfileContextType);

export const ProfilesProvider = ({ children }: SchoolsProviderProps) => {
  const [profiles, setProfiles] = useState<IProfile[]>([]);
  const [loading, setLoading] = useState(false);

  const { signed } = useAuth();

  async function loadProfiles() {
    try {
      if (signed) {
        const { data } = await api.get('/profile');
        setProfiles(data.map((item: any) => ({ ...item, functionalities: JSON.parse(item.functionalities) })));
      }
    } catch (err) {
      /* toast('Não foi possível carregar os perfis', {
        position: 'bottom-center',
      }); */
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setLoading(true);
    loadProfiles();
  }, [signed]);

  return (
    // eslint-disable-next-line no-sequences
    <ProfileContext.Provider value={{ profiles, loadProfiles, loading }}>
      {children}
    </ProfileContext.Provider>
  );
};
