import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useMemo,
} from 'react';

import api from '../services/api';

interface User {
  id: number;
  name: string;
  profile: {
    id: number;
    name: string;
    functionalities: any;
  };
  school_id: number | null;
  institution_id: number | null;
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  cpf: string;
  password: string;
}

interface AuthContextData {
  user: User;
  loading: boolean;
  // eslint-disable-next-line no-unused-vars
  signIn(credentias: SignInCredentials): Promise<void>;
  signOut(): void;
  signed: boolean;
}

interface IProps {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC<IProps> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@infra-escolar:token');
    const user = localStorage.getItem('@infra-escolar:user');

    if (token && user) {
      return {
        token,
        user: {
          ...JSON.parse(user),
          profile: {
            ...JSON.parse(user).profile,
            functionalities: JSON.parse(
              JSON.parse(user).profile.functionalities,
            ),
          },
        },
      };
    }

    return {} as AuthState;
  });

  const signed = useMemo(() => !!data.token && !!data.user, [data]);

  const signIn = useCallback(async ({ cpf, password }: SignInCredentials) => {
    try {
      setLoading(true);
      const response = await api.post(`/auth/signin`, {
        cpf,
        password,
      });

      const { auth, user } = response.data;
      localStorage.setItem('@infra-escolar:token', auth);
      localStorage.setItem('@infra-escolar:user', JSON.stringify(user));
      localStorage.setItem('@infra-escolar:auth', 'auth');
      // @ts-ignore
      api.defaults.headers.authorization = `Bearer ${auth}`;

      setData({
        token: auth,
        user: {
          ...user,
          profile: {
            ...user.profile,
            functionalities: JSON.parse(user.profile?.functionalities),
          },
        },
      });

      window.location.href = JSON.parse(user.profile.functionalities).filter(
        (item: any) => item.active,
      )[0].link;
    } catch (error) {
      setLoading(false);
      localStorage.removeItem('@infra-escolar:token');
      localStorage.removeItem('@infra-escolar:user');
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@infra-escolar:token');
    localStorage.removeItem('@infra-escolar:user');
    localStorage.removeItem('@infra-escolar:auth');

    setData({} as AuthState);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        loading,
        signed,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an authProvider');
  }

  return context;
}
