/* eslint-disable */
import React from 'react';
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
} from 'react-router-dom';
import styled from 'styled-components';
import Sidebar from '../components/Sidebar';

import PageError from '../pages/PageError';

import { useAllowedRoutes } from './useAllowedRoutes';

const Routes = ({ toggleTheme }: any) => {
  const { routes } = useAllowedRoutes();

  const [showSidebar, setSidebar] = React.useState(false);

  return (
    <Router>
      <Sidebar showSidebar={showSidebar} setSidebar={setSidebar}>
        <Switch>
          <Route exact path="/">
            <Redirect to="/dashboard" />
          </Route>
          {routes.map((val: any) => (
            <Route
              key={val.link}
              exact
              path={val.link}
              render={(props) => (
                <val.component
                  showSidebar={showSidebar}
                  setSidebar={setSidebar}
                  toggleTheme={toggleTheme}
                  {...props}
                />
              )}
            />
          ))}

          {routes.map(({ subRoute }, i) =>
            subRoute?.map((sub: any) => (
              <Route
                key={i}
                exact
                path={sub.link}
                render={(props) => (
                  <sub.component
                    showSidebar={showSidebar}
                    setSidebar={setSidebar}
                    toggleTheme={toggleTheme}
                    {...props}
                  />
                )}
              />
            )),
          )}

          <Route path="*" component={PageError} />
        </Switch>
      </Sidebar>
    </Router>
  );
};

export default Routes;
