/* eslint-disable no-restricted-globals */
/* eslint-disable */
// @ts-nocheck
import React, { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Button } from 'primereact/button';
import { Redirect, useHistory } from 'react-router-dom';
import { BiTrash } from 'react-icons/bi';
import Header from '../../components/Header';
import { UserContext } from '../../context/userContext';
import api from '../../services/api';

import { useAuth } from '../../hooks/auth';

const NewServiceCall = ({ showSidebar, setSidebar, toggleTheme }: any) => {
  const [data, setData] = useState({
    service_description: '',
    classification: 'Infraestrutura Administrativa',
    novo: true,
    user: 0,
    institution_id: null,
    institution_id_origem: null,
    rent: 'Novo',
  });

  const [loading, setLoading] = useState(true);

  const { user, signed } = useAuth();

  useEffect(() => {
    if (user) {
      setData({
        ...data,
        classification: user.institution_id
          ? 'Infraestrutura Administrativa'
          : 'Infraestrutura Escolar',
      });
    }
  }, [user]);

  const history = useHistory();

  const [images, setImages] = useState([]);
  // const { users } = useContext(UserContext);

  const [users, setUsers] = useState([]);

  async function getUsers() {
    try {
      if (signed) {
        const response = await api.get(`/users/school/${user.school_id}`);
        setUsers(() => response.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      /* toast('Não foi possível carregar os usuários', {
        position: 'bottom-center',
      }); */
    }
  }

  useEffect(() => {
    getUsers();
  }, [signed]);

  useEffect(() => {
    if (users.length > 0) {
      setData({ ...data, user: users[0].id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);
  async function OpenServiceCall() {
    let aux = data;
    if (user.institution_id) {
      aux = {
        ...data,
        institution_id: user.institution_id,
        institution_id_origem: user.institution_id,
        user: user.id,
      };
    }

    toast
      .promise(api.post('/service-call', aux), {
        pending: 'Cadastrando chamado...',
        success: 'Chamado criado com sucesso',
        error: 'Ocorreu um erro ao criar chamado.',
      })
      .then(async (response) => {
        if (images.length > 0) {
          toast.success('Fazendo Upload do(s) anexo(s)...', {
            position: 'top-right',
          });

          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < images.length; i++) {
            const formData = new FormData();
            formData.append('file', images[i].file);

            // eslint-disable-next-line no-await-in-loop
            await api.post(`upload/${response.data.id}`, formData);
          }

          history.goBack();

          /* toast.promise(api.post(`upload/${response.data.id}`, formData), {
          pending: 'Fazendo Upload do anexo...',
          success: 'Anexo salvo com sucesso',
          error: 'Ocorreu um erro ao salvar anexo .',
        }).then(() => {
          history.goBack();
        }); */
        } else {
          history.goBack();
        }
      });
  }

  return (
    <>
      <Header
        title="Novo Chamado"
        showSidebar={showSidebar}
        setSidebar={setSidebar}
        toggleTheme={toggleTheme}
      />

      {users.length > 0 || user.institution_id ? (
        <div>
          <Form>
            <FormGroup>
              <Label>Tipo de chamado</Label>
              <Input
                type="select"
                onChange={(e) =>
                  setData({ ...data, classification: e.target.value })
                }
              >
                {user.institution_id && (
                  <option value="Infraestrutura Administrativa">
                    Infraestrutura Administrativa
                  </option>
                )}
                {user.school_id && (
                  <option value="Infraestrutura Escolar">
                    Infraestrutura Escolar
                  </option>
                )}
                <option value="Aluguel">Aluguel</option>
              </Input>
            </FormGroup>

            {data.classification == 'Aluguel' && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '20px',
                }}
              >
                <FormGroup check>
                  <Input
                    checked={data.rent === 'Novo'}
                    name="radio"
                    type="radio"
                    onChange={(e) => setData({ ...data, rent: 'Novo' })}
                  />
                  <Label>Novo</Label>
                </FormGroup>
                <FormGroup>
                  <Input
                    checked={data.rent === 'Renovação'}
                    name="radio"
                    type="radio"
                    style={{ marginRight: 10 }}
                    onChange={(e) => setData({ ...data, rent: 'Renovação' })}
                  />
                  <Label>Renovação</Label>
                </FormGroup>
              </div>
            )}

            <FormGroup>
              <Label>Descrição do serviço</Label>
              <Input
                type="textarea"
                rows={5}
                value={data.service_description}
                onChange={(e) =>
                  setData({ ...data, service_description: e.target.value })
                }
              />
            </FormGroup>

            {user.school_id && (
              <FormGroup>
                <Label>Responsável</Label>
                <Input
                  type="select"
                  value={data.user}
                  onChange={(e) =>
                    setData({ ...data, user: Number(e.target.value) })
                  }
                >
                  {users.map((user) => (
                    <option value={user.id}>
                      {`${user.full_name} (${user?.school?.name})`}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            )}

            <div>
              {images.map((item) => (
                <>
                  <FormGroup>
                    <Label>Anexo</Label>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Input
                        className="inputfile"
                        onChange={(e) => {
                          setImages(
                            images.map((file) =>
                              file.id === item.id
                                ? { ...file, file: e.target.files[0] }
                                : file,
                            ),
                          );
                        }}
                        type="file"
                      />
                      <BiTrash
                        style={{
                          fontSize: 30,
                          marginLeft: 10,
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          setImages(
                            images.filter((file) => file.id !== item.id),
                          )
                        }
                      />
                    </div>
                  </FormGroup>

                  <div style={{ marginBottom: 20 }}>
                    <label style={{ fontWeight: 'bold', color: '#626262' }}>
                      Arquivo anexado:
                    </label>
                    <td style={{ textAlign: 'justify', color: '#626262' }}>
                      <a
                        style={{ textDecoration: 'none' }}
                        className="url"
                        href={
                          item.file && window.URL.createObjectURL(item.file)
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item.file ? item.file.name : 'Sem anexo'}
                      </a>
                    </td>
                  </div>
                </>
              ))}
            </div>
            <div className="flex justify-between">
              <Button
                className="px-3 py-3 text-sm bg-blue-500 hover:bg-blue-400 font-bold border-0"
                type="button"
                style={{
                  marginBottom: 20,
                  background: '#080',
                  border: 'none',
                }}
                onClick={() => {
                  setImages([
                    ...images,
                    {
                      id: Date.now(),
                      file: null,
                    },
                  ]);
                }}
              >
                Adicionar anexo
              </Button>

              {(users.length > 0 || user.institution_id) && (
                <Button
                  type="button"
                  className="h-14 btn-cadastro border-0"
                  onClick={() => {
                    OpenServiceCall();
                  }}
                >
                  Abrir Chamado
                </Button>
              )}
            </div>
          </Form>
        </div>
      ) : (
        <>
          {!loading ? (
            <div
              style={{
                display: 'grid',
                placeItems: 'center',
                height: '90%',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <h1>
                  Você não pode criar novo chamado por não está inserido em
                  alguma instituição
                </h1>
                <h1>
                  ou a escola onde você está inserido não possui responsáveis
                </h1>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: 'grid',
                placeItems: 'center',
                height: '90%',
                width: '100%',
              }}
            >
              <i
                className="fa-solid fa-arrows-rotate pi-spin"
                style={{ fontSize: '3rem', color: '#36B37E' }}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default NewServiceCall;
