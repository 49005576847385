/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';
import { Button } from 'primereact/button';
import ReactLoading from 'react-loading';
import { Eye } from '../../assets/svg/Eye';
import { Container, DataTableStyle, UsersTableContainer } from './style';
import { IUser } from '../../types/IUser';
import UserModal from '../UserModal';
import { UserContext } from '../../context/userContext';
import { useAuth } from '../../hooks/auth';
import { Tooltip } from 'primereact/tooltip';
import { ThemeContext } from 'styled-components';
import { Column } from 'primereact/column';
import { classNames } from 'primereact/utils';
import { Ripple } from 'primereact/ripple';
import { InputTextStyle } from '../ProfilesTable/styles';

const UsersTable = () => {
  const [modal, setModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const [allowedActions, setAllowedActions] = useState({
    acoes: {
      create: true,
      update: true,
      read: true,
      delete: true,
    },
  });

  const { colors } = useContext(ThemeContext);

  const { users, loading } = useContext(UserContext);

  const { user } = useAuth();

  const toggle = () => {
    setModal(!modal);
  };

  useEffect(() => {
    if (user) {
      const temp = user?.profile?.functionalities
        ?.filter((func: any) => func.active && func.link == '/schools')
        .map(({ acoes }: any) => ({ acoes }))[0];
      setAllowedActions(temp);
    }
  }, [user]);

  const options = (rowData: any) => (
    <div
      onClick={() => {
        setSelectedUser(rowData);
        toggle();
      }}
      style={{
        cursor: 'pointer',
      }}
    >
      <Tooltip position="top" content="Editar" target=".icon-visualizar" />
      <i className="fa-solid fa-pen-to-square icon-visualizar" />
    </div>
  );

  const pagination = {
    layout: 'PrevPageLink PageLinks NextPageLink',
    PrevPageLink: (options: any) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          style={{ borderRadius: '8px' }}
          disabled={options.disabled}
        >
          <span className="p-3">Anterior</span>
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options: any) => {
      return (
        <button
          type="button"
          className={options.className}
          style={{ borderRadius: '8px' }}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-3">Próximo</span>
          <Ripple />
        </button>
      );
    },
    PageLinks: (options: any) => {
      if (
        (options.view.startPage === options.page &&
          options.view.startPage !== 0) ||
        (options.view.endPage === options.page &&
          options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { 'p-disabled': true });

        return (
          <span
            className={className}
            style={{ userSelect: 'none', borderRadius: '3px' }}
          >
            ...
          </span>
        );
      }

      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          style={{ marginRight: '10px' }}
        >
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
  };

  const optionsFilter: any = {
    full_name: {
      placeholder: 'Nome',
    },
    cpf: {
      placeholder: 'CPF',
    },
    email: {
      placeholder: 'Email',
    },
  };

  const filterTemplate = (options: any) => {
    return (
      <span className="p-input-icon-right">
        <i className="pi pi-search" />
        <InputTextStyle
          color={colors.text}
          placeholder={optionsFilter[options?.field].placeholder}
          onChange={(e) => options.filterApplyCallback(e.target.value)}
          style={{
            backgroundColor: colors.background,
            color: colors.text,
            border: 'none',
          }}
        />
      </span>
    );
  };

  const HeaderInFilter = ({ label }: any) => (
    <p className="text-center" style={{ fontSize: '1rem', color: colors.text }}>
      {label}
    </p>
  );

  return (
    <>
      <DataTableStyle
        className="mt-4 drop-shadow-md"
        tableClassName='header-filter'
        value={users}
        size="small"
        dataKey="id"
        paginator={allowedActions.acoes.read}
        loading={loading}
        scrollable
        filterLocale="BR"
        showGridlines
        scrollDirection="horizontal"
        scrollHeight="60vh"
        rows={10}
        filterDisplay="row"
        stripedRows
        rowsPerPageOptions={[5, 10, 25]}
        paginatorTemplate={pagination}
        backgroundColor={colors.container}
        backgroundButtonPaginate={colors.btn_color}
        colorButtonPaginate={colors.text}
        backgroundButtonPaginateActive={colors.icon_visu}
        colorButtonPaginateActive={'#FFF'}
        rowStripedColor={colors.stripedRow}
        rowsColor={colors.row}
        colorIcon={colors.secundary}
      >
        <Column
          sortable
          filter
          filterMatchMode="contains"
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          filterPlaceholder="Escola"
          bodyStyle={{
            // backgroundColor: colors.background,
            color: colors.text,
            overflow: 'hidden',
          }}
          headerStyle={{
            backgroundColor: colors.background,
            color: colors.text,
            borderRadius: '5px 0 0 0',
          }}
          filterHeaderStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          filterElement={filterTemplate}
          field="full_name"
          // body={school}
          showClearButton={false}
          showFilterMenu={false}
          header="USUÁRIOS"
          style={{ minWidth: '12rem' }}
        />
        <Column
          filter
          filterMatchMode="contains"
          showFilterMenuOptions={false}
          filterPlaceholder="CPF"
          bodyStyle={{
            // backgroundColor: colors.background,
            color: colors.text,
          }}
          headerStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          filterHeaderStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          showClearButton={false}
          showFilterMenu={false}
          field="cpf"
          header="CPF"
          filterElement={filterTemplate}
          // body={cnpj}
          style={{ minWidth: '12rem' }}
        />
        <Column
          filter
          filterMatchMode="contains"
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          filterPlaceholder="Responsável"
          bodyStyle={{
            // backgroundColor: colors.background,
            color: colors.text,
            overflow: 'hidden',
          }}
          headerStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          filterHeaderStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          sortable
          showClearButton={false}
          showFilterMenu={false}
          // body={manager}
          filterElement={filterTemplate}
          field="email"
          header="Email"
          style={{ minWidth: '12rem' }}
        />
        <Column
          sortable
          // body={email}
          field="phone"
          filter
          filterElement={<HeaderInFilter label="Telefone" />}
          bodyStyle={{
            // backgroundColor: colors.background,
            color: colors.text,
          }}
          headerStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          filterHeaderStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          showClearButton={false}
          showFilterMenu={false}
          header="Telefone"
          style={{ minWidth: '12rem', overflow: 'hidden' }}
        />
        <Column
          header="Opções"
          body={options}
          filter
          filterElement={<HeaderInFilter label="Options" />}
          showClearButton={false}
          showFilterMenu={false}
          style={{ minWidth: '5rem', maxWidth: '6rem' }}
          bodyStyle={{
            // backgroundColor: colors.background,
            color: colors.text,
            display: 'flex',
            justifyContent: 'center',
          }}
          filterHeaderStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          headerStyle={{
            backgroundColor: colors.background,
            color: colors.text,
            borderRadius: '0 5px 0 0',
          }}
        />
      </DataTableStyle>
      {/* <UsersTableContainer color={colors.text}>
      {/* {allowedActions.acoes.create && (
        <Button
          className="px-3 py-2 btn-cadastro border-0 mt-4 mb-2"
          style={{
            float: 'right',
          }}
          onClick={() => {
            setSelectedUser(null);
            toggle();
          }}
        >
          Cadastrar novo usuário
        </Button>
      )} */}
      {/* <UsersTableContainer striped>
        <thead>
          <tr>
            <th>Nome Completo</th>
            <th>CPF</th>
            <th>Email</th>
            <th>Telefone</th>
            <th>Opções</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <div
              style={{
                position: 'absolute',
                left: '50%',
                right: '50%',
              }}
            >
              <ReactLoading type="bars" color="#36B37E" />
            </div>
          ) : (
            allowedActions.acoes.read &&
            users?.map(user => (
              <tr key={user.id}>
                <td>{user.full_name}</td>
                <td>{user.cpf}</td>
                <td>{user.email}</td>
                <td>{user.phone}</td>
                <td>
                  <div
                    onClick={() => {
                      setSelectedUser(user);
                      toggle();
                    }}
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <Tooltip
                      position="top"
                      content="Visualizar"
                      target=".icon-visualizar"
                    />
                    <i className="fa-solid fa-eye icon-visualizar" />
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </UsersTableContainer> */}
      <UserModal
        modal={modal}
        toggle={toggle}
        user={selectedUser}
        allowedActions={allowedActions}
      />
    </>
  );
};

export default UsersTable;
