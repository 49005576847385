import React, {
  createContext, ReactNode, useEffect, useState,
} from "react";
import { useAuth } from "../hooks/auth";
import api from "../services/api";
import { ISchoolTypes } from "../types/ISchoolTypes";

interface SchoolTypesContextType {
  schoolTypes: ISchoolTypes[],
  getSchoolTypes: () => void;
  setSchoolTypes: (values: any) => void;
  loading: boolean
}

interface IProps {
  children: ReactNode
}

export const SchoolTypesContext = createContext({} as SchoolTypesContextType);

export const SchoolTypesProvider: React.FC<IProps> = ({ children }: IProps) => {
  const [schoolTypes, setSchoolTypes] = useState<ISchoolTypes[]>([]);
  const [loading, setLoading] = useState(false);

  const { signed } = useAuth();

  async function getSchoolTypes() {
    try {
      if (signed) {
        const response = await api.get('/school-type');
        setSchoolTypes(() => response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setLoading(true);
    getSchoolTypes();
  }, []);

  return (
    <SchoolTypesContext.Provider value={{
      schoolTypes, loading, getSchoolTypes, setSchoolTypes,
    }}
    >
      {children}
    </SchoolTypesContext.Provider>
  );
};
