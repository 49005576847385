import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { Eye } from '../../assets/svg/Eye';
import api from '../../services/api';
import { ISubtype } from '../../types/ISubtype';
import EditSubtypeModal from '../EditSubtypeModal';
import { ThemeContext } from 'styled-components';

const ServiceCallSubtypesList = ({ selectedType }: any) => {
  const [subtypes, setSubtypes] = useState<ISubtype[]>();
  const [selectedSubtype, setSelectedSubtype] = useState<ISubtype>();
  const [loading, setLoading] = useState<any>();
  const [subtypeModal, setSubtypeModal] = useState(false);

  const loadSubtypes = async () => {
    setLoading(true);
    const response = await api.get(`/service-call-subtype/${selectedType.id}`);
    setSubtypes(response.data);
    setLoading(false);
  };

  const {colors} = React.useContext(ThemeContext)

  async function handleDelete(subtypeId: any) {
    toast.promise(
      api.delete(`service-call-subtype/${subtypeId}`).then(loadSubtypes),
      {
        pending: 'Deletando...',
        success: 'Subtipo de chamado deletado.',
        error: 'Ocorreu um erro ao deletar subtipo ',
      },
    );
  }

  const openSubtypeModal = () => {
    setSubtypeModal(!subtypeModal);
  };

  const optionsColumn = (rowData: any) => (
    <div className="flex gap-1">
      <Tooltip content="Visuailizar" position="top" target=".fa-eye" />
      <i
        className="fa-solid fa-eye icon-visualizar"
        onClick={() => {
          setSelectedSubtype(rowData);
          openSubtypeModal();
        }}
      />
      <Tooltip content="Apagar" position="top" target=".fa-trash" />
      <i
        className="fa-solid fa-trash icon-apagar"
        onClick={() => {
          handleDelete(rowData.id);
        }}
      />
    </div>
  );

  useEffect(() => {
    loadSubtypes();
  }, [selectedType.id]);

  return (
    <>
      <DataTable
        size="small"
        showGridlines
        scrollable
        scrollHeight="180px"
        className="mt-4"
        value={subtypes}
        loading={loading}
        loadingIcon="fa-solid fa-arrow-rotate"
        emptyMessage="Sem subtipos cadastrados"
      >
        <Column
          style={{ minWidth: '5rem' }}
          bodyStyle={{backgroundColor: colors.container, color: colors.text}}
          headerStyle={{backgroundColor: colors.background, color: colors.text}}
          header="Subtipos"
          field="subtype"
        />
        <Column
          style={{ maxWidth: '6rem' }}
          bodyStyle={{backgroundColor: colors.container, color: colors.text}}
          headerStyle={{backgroundColor: colors.background, color: colors.text}}
          header="Opções"
          body={optionsColumn}
        />
      </DataTable>

      <EditSubtypeModal
        openSubtypeModal={openSubtypeModal}
        subtypeModal={subtypeModal}
        selectedSubtype={selectedSubtype}
        loadSubtypes={loadSubtypes}
      />
    </>
  );
};

export default ServiceCallSubtypesList;
