import { Dialog } from 'primereact/dialog';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Form, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Button } from 'primereact/button';
import api from '../../services/api';
import { cities } from '../../utils/city.data';
import { ThemeContext } from 'styled-components';

const AllotmentCityModal = ({
  modal,
  toggle,
  selectedAllotmentCity,
  loadAllotmentCities,
}: any) => {
  const [name, setName] = useState('');

  const { colors } = React.useContext(ThemeContext);

  function deleteCity() {
    toast
      .promise(api.delete(`/allotment-city/${selectedAllotmentCity.id}`), {
        pending: 'Deletando cidade...',
        error: 'Ocorreu um erro ao deletar a cidade',
        success: 'Cidade deletada',
      })
      .then(() => {
        loadAllotmentCities();
      })
      .then(toggle());
  }

  function updateCity() {
    toast
      .promise(
        api.patch(`/allotment-city/${selectedAllotmentCity.id}`, { name }),
        {
          pending: 'Atualizando cidade...',
          error: 'Ocorreu um erro ao atualizar a cidade',
          success: 'Cidade alterada',
        },
      )
      .then(() => {
        loadAllotmentCities();
      })
      .then(toggle());
  }
  return (
    <>
      <Dialog
        header="Municipio"
        visible={modal}
        onHide={toggle}
        contentStyle={{ backgroundColor: colors.container, color: colors.text, minWidth: "340px" }}
        headerStyle={{ backgroundColor: colors.container, color: colors.text, minWidth: "340px" }}
      >
        <ModalBody>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Label>Nome</Label>
            <Input
              type="select"
              onChange={(e) => setName(e.target.value)}
              value={selectedAllotmentCity ? selectedAllotmentCity.name : ' '}
            >
              <option> </option>
              {cities.map((city) => (
                <option
                  value={city.name}
                  key={city.name}
                >
                  {city.name}
                </option>
              ))}
            </Input>
            <div className="flex justify-content-end mt-2">
              <Button
                className="px-3 py-2 bg-yellow-400 border-0"
                style={{
                  marginTop: '10px',
                  marginRight: '20px',
                }}
                color="warning"
                onClick={updateCity}
              >
                Alterar
              </Button>
              <Button
                className="px-3 py-2 bg-red-400 border-0"
                style={{
                  marginTop: '10px',
                }}
                onClick={deleteCity}
                color="danger"
              >
                Deletar
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Dialog>
    </>
  );
};

export default AllotmentCityModal;
