/* eslint-disable */
import React from 'react';
import { useAuth } from '../hooks/auth';

import ConfigPage from '../pages/Config';
import DashboardPage from '../pages/Dashboard';
// import InstitutionPage from '../pages/Institution';

import ServiceCallPage from '../pages/ServiceCallPage';
import ReportsPage from '../pages/Reports';
import UserConfigPage from '../pages/UserConfig';
import UsersPage from '../pages/Users';
import ProfilesPage from '../pages/Profile';
import Schools from '../pages/Schools';
import NewServiceCall from '../pages/NewServiceCall';
import CompanyPage from '../pages/Company';
import ServiceCallInfo from '../pages/ServiceCallInfo';
import InstitutionPage from '../pages/InstitutionPage';

import Allotment from '../pages/Allotment';
import Resources from '../pages/Resources';
import AllotmentInfo from '../components/AllotmentInfo/AllotmentInfo';
import FinancialStatement from '../pages/FinancialStatement';
import Timeline from '../pages/Timeline';
import TimelineInfo from '../pages/TimelineInfo';

export function useAllowedRoutes() {
  const { user } = useAuth();

  const allowedPaths = user?.profile?.functionalities
    ?.filter((func: any) => func.active)
    .map(({ link }: any) => link);

  const routesAll = [
    { link: '/dashboard', component: DashboardPage },
    { link: '/instituicoes', component: InstitutionPage },
    {
      link: '/chamados',
      component: ServiceCallPage,
      subRoute: [
        { link: '/chamados/chamado/:id', component: ServiceCallInfo },
        { link: '/chamados/new_service_call', component: NewServiceCall },
      ],
    },
    {
      link: '/relatorio',
      component: ReportsPage,
    },
    { link: '/usuarios', component: UsersPage },
    { link: '/perfis', component: ProfilesPage },
    { link: '/config_user', component: UserConfigPage },
    { link: '/schools', component: Schools },
    { link: '/config', component: ConfigPage },
    {
      link: '/empresas',
      component: CompanyPage,
      subRoute: [
        { link: '/empresas/empresa/:id', component: FinancialStatement },
      ],
    },
    {
      link: '/lotes',
      component: Allotment,
      subRoute: [
        { link: '/lotes/lote/:id', component: AllotmentInfo },
        { link: '/lotes/lote/cronogramas/cronograma/:id', component: TimelineInfo },
        { link: '/lotes/lote/cronogramas/:lote_id', component: Timeline },
      ],
    },
    { link: '/recursos', component: Resources },
  ];

  const routes = routesAll?.filter(route => allowedPaths.includes(route.link));
  return { routes };
}
