import React, {
  createContext, useContext, useEffect, useState, useCallback,
} from 'react';
import { toast } from 'react-toastify';
import api from '../services/api';
import { IType } from '../types/IType';

import { useAuth } from '../hooks/auth';

interface ServiceCallTypesContextType {
  types: IType[];
  getTypes: () => void;
  setTypes: (values: any) => void;
  loading: boolean
}

interface IProps {
  children: React.ReactNode
}

export const ServiceTypesContext = createContext({} as ServiceCallTypesContextType);

export const ServiceTypesProvider: React.FC<IProps> = ({ children }: IProps) => {
  const [types, setTypes] = useState<IType[]>([]);
  const [loading, setLoading] = useState(false);

  const { signed } = useAuth();

  const getTypes = async () => {
    try {
      setLoading(true)

      if (signed) {
        const response = await api.get('/service-call-type');
        setTypes(() => response.data);
      }
    } catch (error) {
      /* toast('Não foi possível carregar os tipos de chamados', {
        position: 'bottom-center',
      }); */
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    getTypes();
  }, [signed]);
  return (
    <ServiceTypesContext.Provider value={{
      types, setTypes, getTypes, loading,
    }}
    >
      {children}
    </ServiceTypesContext.Provider>
  );
};
