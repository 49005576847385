import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
} from 'reactstrap';
import { toast } from 'react-toastify';
import api from '../../services/api';
import { money_mask } from '../../utils/money_mask';
import { TimelineContext } from '../../context/timelineContext';
import { ThemeContext } from 'styled-components';

const TimelineAddSchoolModal = ({
  toggle,
  modal,
  selectedAllotment,
  selectedTimeline,
  getSchoolAllotmentsInTimeline
}: any) => {
  const [data, setData] = useState({
    timeline_id: 0,
    date: '',
    finish_date: '',
  });
  const [loading, setLoading] = useState(false);
  const [schoolAllotments, setSchoolAllotments] = useState([]);
  const [selectedSchoolAllotment, setSelectedSchoolAllotment] =
    useState<string>();

  const { colors } = React.useContext(ThemeContext);


  async function addSchoolAllotment() {
    toast.promise(api.patch(`/timeline/school/${selectedSchoolAllotment}`, data), {
      pending: 'Adicionando escola ao cronograma',
      error: 'Ocorreu um erro ao adicionar a escola ao cronograma',
      success: 'Escola Adicionada',
    }).then(() => {
      getSchoolAllotmentsInTimeline();
    }).then(
      toggle(),
    );


  }

  async function getSchoolAllotment() {
    try {
      setLoading(true);
      const { data } = await api.get(
        `/allotment-school/timeline/${selectedAllotment}`,
      );
      setSchoolAllotments(data);
    } catch (error) {
      /* toast('Não foi possível carregar o chamado', {
          position: 'bottom-center',
        }); */
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    getSchoolAllotment();
    setData({ ...data, timeline_id: Number(selectedTimeline) });
  }, [selectedAllotment, selectedTimeline]);
  return (
    <>
      <Modal size="lg" isOpen={modal} toggle={toggle}>
        <ModalHeader
          toggle={toggle}
          style={{ backgroundColor: colors.container, color: colors.text }}
        >
          Cronograma
        </ModalHeader>
        <ModalBody
          style={{ backgroundColor: colors.container, color: colors.text }}
        >
          <>
            <Form>
              <Label>Selecionar Escolas do Cronograma </Label>
              <div>
                <Input
                  type="select"
                  value={selectedSchoolAllotment}
                  onChange={(e) => setSelectedSchoolAllotment(e.target.value)}
                  style={{
                    backgroundColor: colors.container,
                    color: colors.text,
                  }}
                >
                  <option> </option>
                  {schoolAllotments?.map((schoolAllotment: any) => (
                    <option
                      key={schoolAllotment.id}
                      value={schoolAllotment?.id}
                    >
                      {schoolAllotment?.escola?.name}
                    </option>
                  ))}
                </Input>
                <Label>Data de Início</Label>
                <Input
                  type="date"
                  onChange={(e) => setData({ ...data, date: e.target.value })}
                  value={data?.date}
                  style={{
                    backgroundColor: colors.container,
                    color: colors.text,
                  }}
                />
                <Label>Data Final</Label>
                <Input
                  type="date"
                  onChange={(e) =>
                    setData({ ...data, finish_date: e.target.value })
                  }
                  value={data?.finish_date}
                  style={{
                    backgroundColor: colors.container,
                    color: colors.text,
                  }}
                />
                <div className="flex justify-end">
                  <Button
                    className="btn-cadastro"
                    style={{
                      marginTop: '20px',
                    }}
                    onClick={() => {
                      addSchoolAllotment();
                    }}
                  >
                    Adicionar
                  </Button>
                </div>
              </div>
            </Form>
          </>
        </ModalBody>
      </Modal>
    </>
  );
};

export default TimelineAddSchoolModal;
