/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import { ICompany } from '../../types/ICompany';
import { CompanyTableContainer } from '../UsersTable/styles';
import CompanyModal from '../CompanyModal';
import { Eye } from '../../assets/svg/Eye';
import { useAuth } from '../../hooks/auth';
import Pagination from '../Pagination';
import { DataTableStyle } from '../SchoolsTable/styles';
import { ThemeContext } from 'styled-components';
import { classNames } from 'primereact/utils';
import { Ripple } from 'primereact/ripple';
import { Column } from 'primereact/column';
import { InputTextStyle } from '../ProfilesTable/styles';

const CompanyTable = () => {
  const [data, setData] = useState<ICompany[]>([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [selectedCompany, setSelectedCompany] = useState<ICompany | null>(null);
  const toggle = () => {
    setModal(!modal);
  };

  const { user } = useAuth();

  const indexOfLastCall = currentPage * itemsPerPage;
  const indexOfFirstCall = indexOfLastCall - itemsPerPage;
  const currentCalls = data?.slice(indexOfFirstCall, indexOfLastCall);

  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

  const [allowedActions, setAllowedActions] = useState({
    acoes: {
      create: true,
      update: true,
      read: true,
      delete: true,
    },
  });

  const { colors } = React.useContext(ThemeContext);

  async function getCompanies() {
    try {
      setLoading(true);
      const { data } = await api.get('/company');
      setData(data);
    } catch (error) {
      /* toast('Não foi possível carregas as empresas', {
        position: 'bottom-center',
      }); */
    } finally {
      setLoading(false);
    }
  }

  const pagination = {
    layout: 'PrevPageLink PageLinks NextPageLink',
    PrevPageLink: (options: any) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          style={{ borderRadius: '8px' }}
          disabled={options.disabled}
        >
          <span className="p-3">Anterior</span>
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options: any) => {
      return (
        <button
          type="button"
          className={options.className}
          style={{ borderRadius: '8px' }}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-3">Próximo</span>
          <Ripple />
        </button>
      );
    },
    PageLinks: (options: any) => {
      if (
        (options.view.startPage === options.page &&
          options.view.startPage !== 0) ||
        (options.view.endPage === options.page &&
          options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { 'p-disabled': true });

        return (
          <span
            className={className}
            style={{
              userSelect: 'none',
              borderRadius: '3px',
              borderColor: 'transparent',
            }}
          >
            ...
          </span>
        );
      }

      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          style={{ marginRight: '10px' }}
        >
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
  };

  const options = React.useCallback(
    (rowData: any) => (
      <>
        <i
          onClick={() => {
            setSelectedCompany(rowData);
            toggle();
          }}
          className="fa-solid fa-pen icon-editar"
        ></i>

        <Link
          className="text-decoration-none"
          to={{
            pathname: `empresas/empresa/${rowData?.id}`,
            state: rowData?.id,
          }}
        >
          <i className="fa-solid fa-eye icon-visualizar"></i>
        </Link>
      </>
    ),
    [],
  );

  useEffect(() => {
    if (user) {
      if (user) {
        const temp = user?.profile?.functionalities
          ?.filter((func: any) => func.active && func.link == '/empresas')
          .map(({ acoes }: any) => ({ acoes }))[0];
        setAllowedActions(temp);
      }
    }
    getCompanies();
  }, [user]);

  const optionsFilter: any = {
    corporate_name: {
      placeholder: 'Empresa',
    },
    phone: {
      placeholder: 'Telefone',
    },
    email: {
      placeholder: 'Email',
    },
    cnpj: {
      placeholder: 'CNPJ',
    },
  };

    const filterTemplate = (options: any) => {
      return (
        <span className="p-input-icon-right">
          <i className="pi pi-search" />
          <InputTextStyle
            color={colors.text}
            placeholder={optionsFilter[options?.field].placeholder}
            onChange={(e) => options.filterApplyCallback(e.target.value)}
            style={{
              backgroundColor: colors.background,
              color: colors.text,
              border: 'none',
            }}
          />
        </span>
      );
    };

    const HeaderInFilter = ({ label }: any) => (
      <p className="text-center" style={{ fontSize: '1rem', color: colors.text }}>
        {label}
      </p>
    );

  return (
    <>
      {allowedActions.acoes.create && (
        <div className="flex justify-content-end">
          <Button
            className="px-3 py-2 btn-cadastro border-0 mt-4"
            onClick={() => {
              setSelectedCompany(null);
              toggle();
            }}
            icon="fa-solid fa-plus"
            label="Cadastrar empresa"
          />
        </div>
      )}

      {allowedActions.acoes.read && (
        <DataTableStyle
          tableClassName="header-filter"
          value={currentCalls}
          size="small"
          dataKey="id"
          paginator={allowedActions.acoes.read}
          loading={loading}
          scrollable
          filterLocale="BR"
          showGridlines
          scrollDirection="horizontal"
          scrollHeight="50vh"
          className="mt-4"
          rows={10}
          filterDisplay="row"
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate={pagination}
          style={{ backgroundColor: colors.background }}
          loadingIcon="fa-solid fa-arrows-rotate"
          backgroundColor={colors.container}
          backgroundButtonPaginate={colors.btn_color}
          colorButtonPaginate={colors.text}
          backgroundButtonPaginateActive={colors.icon_visu}
          colorButtonPaginateActive={'#FFF'}
          rowStripedColor={colors.stripedRow}
          rowsColor={colors.row}
          colorIcon={colors.secundary}
        >
          <Column
            sortable
            filter
            filterMatchMode="contains"
            showFilterMatchModes={false}
            showFilterMenuOptions={false}
            filterPlaceholder="Empresa"
            bodyStyle={{
              color: colors.text,
            }}
            headerStyle={{
              backgroundColor: colors.background,
              color: colors.text,
            }}
            filterHeaderStyle={{
              backgroundColor: colors.background,
              color: colors.text,
            }}
            field="corporate_name"
            header="Razão Social"
            showClearButton={false}
            showFilterMenu={false}
            filterElement={filterTemplate}
            style={{ minWidth: '12rem' }}
          />
          <Column
            sortable
            filter
            filterMatchMode="contains"
            showFilterMatchModes={false}
            showFilterMenuOptions={false}
            filterPlaceholder="CNPJ"
            bodyStyle={{
              color: colors.text,
            }}
            headerStyle={{
              backgroundColor: colors.background,
              color: colors.text,
            }}
            filterHeaderStyle={{
              backgroundColor: colors.background,
              color: colors.text,
            }}
            field="cnpj"
            header="CNPJ"
            showClearButton={false}
            showFilterMenu={false}
            filterElement={filterTemplate}
            style={{ minWidth: '12rem' }}
          />
          <Column
            sortable
            filter
            filterMatchMode="contains"
            showFilterMatchModes={false}
            showFilterMenuOptions={false}
            filterPlaceholder="Telefone"
            bodyStyle={{
              color: colors.text,
            }}
            headerStyle={{
              backgroundColor: colors.background,
              color: colors.text,
            }}
            filterHeaderStyle={{
              backgroundColor: colors.background,
              color: colors.text,
            }}
            field="phone"
            header="Telefone"
            showClearButton={false}
            showFilterMenu={false}
            filterElement={filterTemplate}
            style={{ minWidth: '12rem' }}
          />
          <Column
            sortable
            filter
            filterMatchMode="contains"
            showFilterMatchModes={false}
            showFilterMenuOptions={false}
            filterPlaceholder="Email"
            bodyStyle={{
              color: colors.text,
            }}
            headerStyle={{
              backgroundColor: colors.background,
              color: colors.text,
            }}
            filterHeaderStyle={{
              backgroundColor: colors.background,
              color: colors.text,
            }}
            field="email"
            header="Email"
            filterElement={filterTemplate}
            showClearButton={false}
            showFilterMenu={false}
            style={{ minWidth: '12rem' }}
          />
          <Column
            bodyStyle={{
              color: colors.text,
            }}
            headerStyle={{
              backgroundColor: colors.background,
              color: colors.text,
            }}
            filterHeaderStyle={{
              backgroundColor: colors.background,
              color: colors.text,
            }}
            header="Opções"
            filter
            filterElement={<HeaderInFilter label="Opções" />}
            showClearButton={false}
            showFilterMenu={false}
            body={options}
            style={{ minWidth: '12rem' }}
          />
        </DataTableStyle>
      )}
      <CompanyModal
        modal={modal}
        toggle={toggle}
        company={selectedCompany}
        getCompanies={getCompanies}
        allowedActions={allowedActions}
      />
    </>
  );
};

export default CompanyTable;
