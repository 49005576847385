import React from 'react';
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
} from 'react-router-dom';

import Login from '../pages/Login';
import OAuth from '../pages/OAuth';
import Callback from '../pages/Callback';

import Routes from './route';

import { useAuth } from '../hooks/auth';

// @ts-ignore
const PrivateRoute = ({ component: Component, toggleTheme, ...rest }: any) => {
  const { signed } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        signed ? (
          <Component {...props} toggleTheme={toggleTheme} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const Main = ({toggleTheme}: any) => {

  return (
    <Router>
      <Switch>
        <Route path="/login" render={() => <Login toggleTheme={toggleTheme} />} />
        <Route path="/oauth" component={OAuth} />
        <Route path="/callback" component={Callback} />

        <PrivateRoute path="/" component={() => <Routes toggleTheme={toggleTheme} /> } />
      </Switch>
    </Router>
  );
};

export default Main;
