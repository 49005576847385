import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Form, Input, Label } from 'reactstrap';
import api from '../../services/api';

const INITIAL_STATE = {
  date: '',
  finish_date: '',
  status: '',
};

const TimelineSchoolEditModal = ({
  timelineSchoolModal, toggleTimelineSchoolModal, selectedTimelineSchool, getSchoolAllotmentsInTimeline,
}: any) => {
  const [data, setData] = useState<any>(INITIAL_STATE);

  async function editSchoolTimeline() {
    toast.promise(api.patch(`timeline/school/${selectedTimelineSchool?.id}`, data), {
      pending: 'Alterando...',
      success: 'Escola do Cronograma alterada com sucesso',
      error: 'Ocorreu um erro ao alterar a escola do cronograma',
    }).then(() => {
      getSchoolAllotmentsInTimeline();
    }).then(() => {
      toggleTimelineSchoolModal();
    });
  }
  async function removeSchoolTimeline() {
    toast.promise(api.patch(`timeline/school/${selectedTimelineSchool?.id}`, {
      timeline_id: null,
    }), {
      pending: 'Removendo...',
      success: 'Escola do removida do cronograma com sucesso',
      error: 'Ocorreu um erro ao remover a escola do cronograma',
    }).then(() => {
      getSchoolAllotmentsInTimeline();
    }).then(() => {
      toggleTimelineSchoolModal();
    });
  }

  const footer = (
    <div>
      <Button
        label="Remover"
        className="px-3 py-2 bg-red-400 hover:bg-red-300 border-0"
        onClick={removeSchoolTimeline}
      />
      <Button
        label="Editar"
        className="px-3 py-2 bg-yellow-400 hover:bg-yellow-300 border-0"
        onClick={editSchoolTimeline}
      />
    </div>
  );

  useEffect(() => {
    if (selectedTimelineSchool) {
      setData({
        ...data,
        date: selectedTimelineSchool?.date,
        finish_date: selectedTimelineSchool?.finish_date,
        status: selectedTimelineSchool?.status,
      });
    } else {
      setData(INITIAL_STATE);
    }
  }, [selectedTimelineSchool]);
  return (
    <>
      <Dialog
        footer={footer}
        className="w-50"
        header="Editar Escola do Cronograma"
        visible={timelineSchoolModal}
        onHide={toggleTimelineSchoolModal}
      >
        <Form onSubmit={(e) => e.preventDefault()}>
          <Label>Data de Início:</Label>
          <Input
            type="date"
            onChange={(e) => setData({ ...data, date: e.target.value })}
            value={data?.date}
          />
          <Label>Data Final:</Label>
          <Input
            type="date"
            onChange={(e) => setData({ ...data, finish_date: e.target.value })}
            value={data?.finish_date}
          />
          <Label>Status:</Label>
          <Input
            value={data?.status}
            type="select"
            defaultValue={selectedTimelineSchool?.status || null}
            onChange={(e) => setData({ ...data, status: e.target.value })}
          >
            <option value={selectedTimelineSchool?.status || null}>
              {selectedTimelineSchool?.status || null}
            </option>
            <option value="Em Aberto">Em Aberto</option>
            <option value="Em Andamento">Em Andamento</option>
            <option value="Aguardando Retorno">Aguardando Retorno</option>
            <option value="Finalizado">Finalizado</option>
          </Input>
        </Form>
      </Dialog>
    </>
  );
};

export default TimelineSchoolEditModal;
