/* eslint-disable */
import React, { useState, useContext } from 'react';
import { Table } from 'reactstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import { Eye } from '../../assets/svg/Eye';
import NewServiceCallSubtypeModal from '../NewServiceCallSubtypeModal';
import { ServiceTypesContext } from '../../context/serviceCallTypesContext';
import { IType } from '../../types/IType';
import api from '../../services/api';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';
import { Ripple } from 'primereact/ripple';
import { Container, DataTableStyle } from './style';
import { ThemeContext } from 'styled-components';

const ServiceCallTypesList = ({ allowedActions }: any) => {
  const [selectedType, setSelectedType] = useState<IType>();
  const [modal, setModal] = useState(false);

  const { types, loading, getTypes } = useContext(ServiceTypesContext);

  const {colors} = React.useContext(ThemeContext)

  async function handleDelete(item: any) {
    toast.promise(api.delete(`/service-call-type/${item}`).then(getTypes), {
      pending: 'Deletando...',
      success: 'Tipo de chamado deletado.',
      error: 'Ocorreu um erro ao deletar o tipo ',
    });
  }

  const toggle = () => {
    setModal(!modal);
  };

  const optionsBodyTemplate = (rowData: any) => (
    <div className="flex gap-2">
      <div
        style={{
          cursor: 'pointer',
        }}
        onClick={() => {
          setSelectedType(rowData);
          toggle();
        }}
      >
        <Tooltip content="Editar" position="top" target=".fa-pen-to-square" />
        <i className="fa-solid fa-pen-to-square text-blue-500 rounded-full py-1 px-2 text-center hover:text-white hover:bg-blue-500 text-lg transition duration-200" />
      </div>
      <div
        style={{
          cursor: 'pointer',
        }}
        onClick={() => {
          handleDelete(rowData.id);
        }}
      >
        <Tooltip content="Apagar" position="top" target=".fa-trash" />
        <i className="fa-solid fa-trash text-red-400 rounded-full px-3 py-1 text-center hover:text-white hover:bg-red-400 text-lg transition duration-200" />
      </div>
    </div>
  );

  const pagination = {
    layout: 'PrevPageLink PageLinks NextPageLink',
    PrevPageLink: (options: any) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          style={{ borderRadius: '8px' }}
          disabled={options.disabled}
        >
          <span className="p-3">Anterior</span>
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options: any) => {
      return (
        <button
          type="button"
          className={options.className}
          style={{ borderRadius: '8px' }}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-3">Próximo</span>
          <Ripple />
        </button>
      );
    },
    PageLinks: (options: any) => {
      if (
        (options.view.startPage === options.page &&
          options.view.startPage !== 0) ||
        (options.view.endPage === options.page &&
          options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { 'p-disabled': true });

        return (
          <span
            className={className}
            style={{ userSelect: 'none', borderRadius: '3px' }}
          >
            ...
          </span>
        );
      }

      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          style={{ marginRight: '10px' }}
        >
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
  };

  return (
    <div className="w-full grid place-items-center mt-5">
      {allowedActions.acoes.read ? (
          <DataTableStyle
            className="w-11/12 shadow-md border-1"
            value={types}
            showGridlines
            size="small"
            stripedRows
            dataKey="id"
            paginator
            scrollable
            scrollDirection="vertical"
            scrollHeight="45vh"
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate={pagination}
            responsiveLayout="scroll"
            loading={loading}
            backgroundColor={colors.container}
            backgroundButtonPaginate={colors.btn_color}
            colorButtonPaginate={colors.text}
            backgroundButtonPaginateActive={colors.icon_visu}
            colorButtonPaginateActive={'#FFF'}
            rowStripedColor={colors.stripedRow}
            rowsColor={colors.row}
            colorIcon={colors.secundary}
          >
            <Column
              headerClassName="pl-3 py-3"
              headerStyle={{backgroundColor: colors.background, color: colors.text}}
              bodyStyle={{color: colors.text}}
              header="Tipo"
              field="type"
              sortable
            />
            {allowedActions.acoes.create && (
              <Column
                className="max-w-7rem"
                headerStyle={{backgroundColor: colors.background, color: colors.text }}
                header="Opções"
                body={optionsBodyTemplate}
              />
            )}
          </DataTableStyle>
      ) : (
        <span>Sem permissão para visualizar está página</span>
      )}
      {/* {loading ? (
      <div style={{
        position: 'absolute',
        left: '50%',
        right: '50%',
      }}
      >
        <ReactLoading type="bars" color="#36B37E" />
      </div>
    ) : (
      allowedActions.acoes.read && types?.map((data) => (
        <tr key={data?.id}>
          <td>{data?.type}</td>
          <td
            style={{
              display: "flex",
              alignItems: 'center',
              gap: '10px',
              width: '100px',
            }}
          >
            {allowedActions.acoes.create && (
              <div
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setSelectedType(data);
                  toggle();
                }}
              >
                <Eye />
              </div>
            )}
            <div
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                handleDelete(data.id);
              }}
            >
              <p>X</p>
            </div>
          </td>
        </tr>
      ))
    )} */}
      <NewServiceCallSubtypeModal
        modal={modal}
        toggle={toggle}
        selectedType={selectedType}
      />
    </div>
  );
};

export default ServiceCallTypesList;
