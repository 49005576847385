import { Table } from 'reactstrap';
import styled from 'styled-components';
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext';

export const ProfilesTableContainer = styled(Table)`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0.5rem;
  margin-top: 1.5rem;

  td {
    &:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  thead {
    border: none;
  }
`;

interface DataTableTypes {
  backgroundColor?: string,
  colorIcon?: string,
  rowsColor?: string,
  rowStripedColor?: string,
  backgroundButtonPaginate?: string,
  colorButtonPaginate?: string,
  backgroundButtonPaginateActive?: string,
  colorButtonPaginateActive?: string,
}

export const DataTableStyle = styled(DataTable)<DataTableTypes>`
  .p-datatable-thead tr[role="row"]:nth-child(odd) {
    display: none;
  }
  .p-datatable-thead > tr > th {
    padding: 0 !important;
  }
  .p-inputtext.p-component.p-column-filter {
    border-radius: 0px;
    background-color: transparent;
  }
  .p-datatable-wrapper {
    border-radius: 5px 5px 0 0;
  }
  .p-highlight {
    background-color: rgba(54,179,126,0.15) !important;
    color: #36B37E !important;
  }
  .p-highlight:focus {
    border: 0px;
    box-shadow: none;
  }
  span.p-sortable-column-icon {
    border-radius: 100%;
    width: 35px;
    height: 35px;
    padding: 0px;
    display: grid;
    place-items: center;
    font-size: 1rem;
  }
  span.p-sortable-column-icon:hover {
    color: #FFF !important;
    background-color: #36B391;
  }
  .p-highlight .p-sortable-column-icon {
    background-color: #FFF;
    color: #36B37E !important;
    border: 3px solid #36B37E;
    font-weight: bold;
  }
  .p-column-filter-menu-button {
    margin-left: .5rem;
  }
  .p-column-filter-menu-button:hover {
    color: #FFF;
    background-color: #36B391;
  }
  .p-column-filter-menu-button-open {
    background-color: #FFF;
    box-shadow: 0 0 0 0.2rem #36B37E;
    color: #36B37E;
  }
  .p-paginator.p-component.p-paginator-bottom {
    background-color: ${(props) => props.backgroundColor};
    display: flex;
    justify-content: flex-start;
  }
  .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: ${(props) => props.colorIcon} !important;
  }
  .p-sortable-column .p-sortable-column-icon {
    color: #555;
  }
  .p-datatable-tbody tr {
    background-color: ${(props) => props.rowsColor};
  }
  .p-datatable-tbody tr.p-row-odd {
    background-color: ${(props) => props.rowStripedColor} !important;
  }
  .p-paginator-page.p-paginator-element.p-link {
    background-color: ${(props) => props.backgroundButtonPaginate};
    color: ${(props) => props.colorButtonPaginate};
    border-radius: 8px;
    min-width: 0rem;
    padding: 0;
    height: 31px;
    width: 31px;
  }
  .p-paginator.p-component {
    border-radius: 0 0 8px 8px;
  }
  .p-paginator-page.p-paginator-elemstyle={{borderRadius: "8px"}}ent.p-link.p-highlight {
    background-color: ${(props) => props.backgroundButtonPaginateActive};
    color: ${(props) => props.colorButtonPaginateActive};
  }
`;

export const Container = styled.div`
  max-height: 75vh;
  @media(max-height: 800px) {
    max-height: 65vh;
  }
`

export const InputTextStyle = styled(InputText)`
  &::placeholder {
    color: ${props => props.color};
    font-weight: bold;
  }
`