export const cities = [
  { name: 'Macapá' },
  { name: 'Santana' },
  { name: 'Laranjal do Jari' },
  { name: 'Oiapoque' },
  { name: 'Mazagão' },
  { name: 'Porto Grande' },
  { name: 'Tatarugalzinho' },
  { name: 'Pedra Branca do Amapari' },
  { name: 'Vitória do Jari' },
  { name: 'Calçoene' },
  { name: 'Amapá' },
  { name: 'Ferreira Gomes' },
  { name: 'Cutias' },
  { name: 'Itaubal' },
  { name: 'Serra do Navio' },
  { name: 'Pracuuba' },
];
