import React from 'react';
import Header from '../../components/Header';
import ResourcesList from '../../components/ResourcesList';

const Resources = ({ showSidebar, setSidebar, toggleTheme }: any) => (
  <>
    <Header
      title="Recursos"
      icon="fa-solid fa-layer-group"
      showSidebar={showSidebar}
      setSidebar={setSidebar}
      toggleTheme={toggleTheme}
    />

    <ResourcesList />
  </>
);

export default Resources;
