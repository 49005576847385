import { Dropdown } from 'primereact/dropdown';
import styled from 'styled-components';

interface DropdownTypes {
  backgroundColor: string;
  hoverBorder: string;
}

export const DropdownStyle = styled(Dropdown)<DropdownTypes>`
  background-color: ${(props) => props.backgroundColor};
  &:hover.p-dropdown:not(.p-disabled) {
    border-color: ${(props) => props.hoverBorder} !important;
  }
  .p-inputtext {
    color: ${(props) => props.color};
  }
`;
