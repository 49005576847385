import React from 'react';
import CompanyTable from '../../components/CompanyTable';
import Header from '../../components/Header';

const CompanyPage = ({ showSidebar, setSidebar, toggleTheme }: any) => (
  <>
    <Header
      title="Empresas"
      icon="fa-solid fa-building"
      showSidebar={showSidebar}
      setSidebar={setSidebar}
      toggleTheme={toggleTheme}
    />

    <CompanyTable />
  </>
);

export default CompanyPage;
