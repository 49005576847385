/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
// import { Button, Table } from 'reactstrap';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import api from '../../services/api';
import ReferralTypeModal from '../ReferralTypeModal';
import { Eye } from '../../assets/svg/Eye';
import { Tooltip } from 'primereact/tooltip';

export interface IReferralType {
  id: number;
  description: string;
}

const ReferralTable = ({
  selectedInstitution,
  allowedActions,
  getInstitutions,
}: any) => {
  const [data, setData] = useState<IReferralType[]>(
    selectedInstitution.referrals_type
      ? selectedInstitution.referrals_type
      : [],
  );
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedReferralType, setSelectedReferralType] =
    useState<IReferralType | null>();
  const toggle = () => {
    setModal(!modal);
  };

  function handleClick(item: any) {
    toast.promise(
      api.delete(`/referrals-type/${item.id}`).then(() => {
        loadReferralTypes();
        getInstitutions();
      }),
      {
        pending: 'Deletando...',
        success: 'Tipo de encaminhamento apagado com sucesso',
        error: 'Ocorreu um erro ao apagar tipo de encaminhamento',
      },
    );
  }

  async function loadReferralTypes() {
    try {
      setLoading(true);
      const { data } = await api.get(
        `referrals-type/${selectedInstitution.id}`,
      );
      setData(data);
    } catch (error) {
      setData([]);
    } finally {
      setLoading(false);
    }
  }
  const actionBodyTemplate = (rowData: any) => (
    <>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          justifyItems: 'center',
        }}
      >
        <Tooltip
          content="Visualizar"
          position="top"
          target=".icon-visualizar"
        />
        <i
          onClick={() => {
            setSelectedReferralType(rowData);
            toggle();
          }}
          className="pi pi-eye icon-visualizar"
          style={{ fontSize: '1.3rem' }}
        />
        <Tooltip content="Apagar" position="top" target=".apagar" />
        <i
          onClick={() => handleClick(rowData)}
          className="pi pi-trash icon-apagar apagar"
        />
      </div>
    </>
  );
  return (
    <div style={{ display: 'grid' }}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          alignItems: 'center',
        }}
      >
        <p>Tipos de Encaminhamento Relacionado:</p>
        {allowedActions.acoes.create && (
          <Button
            onClick={() => {
              setSelectedReferralType(null);
              toggle();
            }}
            style={{
              justifySelf: 'end',
              marginRight: '20px',
              width: '70%',
            }}
            className="text-sm py-2 px-0 bg-green-500 border-0 hover:bg-green-400"
            label="Cadastrar tipo de encaminhamento"
          />
        )}
      </div>
      {data.length > 0 && allowedActions.acoes.read && (
        <div className="card mt-3">
          <DataTable
            value={data}
            scrollable
            scrollHeight="100%"
            style={{ maxHeight: '150px' }}
            size="small"
            responsiveLayout="scroll"
          >
            <Column
              field="description"
              header="Descrição"
              className="text-sm"
              style={{ minWidth: '80%' }}
            />
            <Column
              header="Opções"
              headerStyle={{ paddingLeft: '1.6rem' }}
              className="text-sm"
              body={actionBodyTemplate}
            />
          </DataTable>
        </div>
      )}
      {/* <Table
        striped
        style={{
          marginLeft: '15px',
          maxWidth: '70%',
          marginTop: '20px',
        }}
      >
        <tbody>
          {
            loading ? (
              <div style={{
                position: 'absolute',
                left: '50%',
                right: '50%',
              }}
              >
                <ReactLoading type="bars" color="#36B37E" />
              </div>
            ) : (
              // allowedActions.acoes.read &&
              data?.map((item) => (
                <tr
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <td>{item.description}</td>
                  <div
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      handleClick(item);
                    }}
                  >
                    <td>X</td>
                  </div>
                  <td
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      setSelectedReferralType(item);
                      toggle();
                    }}
                  >
                    <Eye />
                  </td>
                </tr>
              ))
            )
          }
        </tbody>
      </Table> */}
      <ReferralTypeModal
        modal={modal}
        toggle={toggle}
        selectedInstitution={selectedInstitution}
        loadReferralTypes={loadReferralTypes}
        getInstitutions={getInstitutions}
        selectedReferralType={selectedReferralType}
      />
    </div>
  );
};

export default ReferralTable;
