import { Dialog } from 'primereact/dialog';
import styled from 'styled-components';

interface DialogTypes {
  backgroundColor: string;
}

export const DialogStyle = styled(Dialog)<DialogTypes>`
  .p-dialog-footer {
    background-color: ${(props) => props.backgroundColor};
  }
  .p-dialog-content {
    background-color: ${(props) => props.backgroundColor};
  }
  .p-dialog-header {
    background-color: ${(props) => props.backgroundColor};
  }
`;
