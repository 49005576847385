import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { ThemeContext } from 'styled-components';
import api from '../../services/api';
import { IAllotment } from '../../types/IAllotment';
import EffortList from '../EffortList';

const AllotmentValueModal = ({
  toggle, modal, loteId, allotment, getAllotment,
}: any) => {
  const [data, setData] = useState<IAllotment>();

  const {colors} = React.useContext(ThemeContext)

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} style={{backgroundColor: colors.container, color: colors.text}}>
          Valores e Empenhos
        </ModalHeader>
        <ModalBody style={{backgroundColor: colors.container, color: colors.text}}>
          <EffortList allotment={allotment} getAllotment={getAllotment} />
        </ModalBody>
      </Modal>
    </>
  );
};

export default AllotmentValueModal;
