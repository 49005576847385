import React, { useState, createContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import api from '../services/api';
import { IUser } from '../types/IUser';

import { useAuth } from '../hooks/auth';

interface UserContextType {
  users: IUser[];
  getUsers: () => void;
  setUsers: (values: any) => void;
  loading: boolean;
}

interface IProps {
  children: React.ReactNode
}

export const UserContext = createContext({} as UserContextType);

export const UsersProvider: React.FC<IProps> = ({ children }: IProps) => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [loading, setLoading] = useState(false);

  const { signed } = useAuth();

  async function getUsers() {
    try {
      if (signed) {
        const response = await api.get('/users');
        setUsers(() => response.data);
      }
    } catch (error) {
      /* toast('Não foi possível carregar os usuários', {
        position: 'bottom-center',
      }); */
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setLoading(true);
    getUsers();
  }, []);

  return (
    <UserContext.Provider value={{
      users, loading, setUsers, getUsers,
    }}
    >
      {children}
    </UserContext.Provider>
  );
};
