import React, { useContext, useEffect, useState } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import { SchoolContext } from '../../context/schoolContext';
import { ISchool } from '../../types/ISchool';
import styled, { ThemeContext } from 'styled-components';

interface TypesAutoComplete {
  backgroundColor: string;
  backgroundColorHover: string;
}

const AutoCompleteStyled = styled(AutoComplete)<TypesAutoComplete>`
  min-width: 250px;
  .p-button.p-autocomplete-dropdown.p-button-icon-only {
    background-color: ${(props) => props.backgroundColor};
    border: none;
  }
`;

const SchoolSearchInput = ({
  selectedSchool,
  setSelectedSchool,
  schools,
}: any) => {
  const [filteredSchool, setFilteredSchool] = useState<any>();
  const { setSchools, loading } = useContext(SchoolContext);

  let schools_dropdown = schools.map((school: any) => ({
    value: school,
    label: school.name,
  }));

  const { colors } = React.useContext(ThemeContext);

  const searchSchool = (event: any) => {
    const filteredSchools = schools_dropdown.filter((school: any) =>
      String(school.label).toLowerCase().includes(event.query.toLowerCase()),
    );

    setFilteredSchool(filteredSchools);
  };

  useEffect(() => {
    setSchools(schools);
    schools_dropdown = schools.map((school: any) => ({
      value: school,
      label: school.name,
    }));
  }, [schools]);

  return (
    <div className="flex justify-end">
      <AutoCompleteStyled
        style={{
          width: '30%',
          marginTop: '20px',
        }}
        
        field="label"
        dropdown
        dropdownIcon={
          loading ? (
            <i className="pi pi-spinner pi-spin" />
          ) : (
            <i className="pi pi-chevron-down" />
          )
        }
        panelClassName='panelSchool'
        forceSelection
        value={selectedSchool}
        suggestions={filteredSchool}
        inputStyle={{ backgroundColor: colors.container, color: colors.text }}
        backgroundColor="#4CD07D"
        backgroundColorHover="#FFF"
        completeMethod={searchSchool}
        onChange={(e) => {
          setSelectedSchool(e.value);
        }}
        placeholder="Selecione uma escola..."
      />
    </div>
  );
};

export default SchoolSearchInput;
