import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import InstitutionTable from '../../components/InstitutionTable';

import NewServiceCallType from '../../components/NewServiceCallType';
import ReferralTable from '../../components/ReferralTypeTable';
import ServiceCallTypesList from '../../components/ServiceCallTypesList';
import { useAuth } from '../../hooks/auth';

const ConfigPage = ({ showSidebar, setSidebar, toggleTheme }: any) => {
  const [allowedActions, setAllowedActions] = useState({
    acoes: {
      create: true,
      update: true,
      read: true,
      delete: true,
    },
  });

  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      const temp = user?.profile?.functionalities
        ?.filter((func: any) => func.active && func.link == '/schools')
        .map(({ acoes }: any) => ({ acoes }))[0];
      setAllowedActions(temp);
    }
  }, [user]);
  return (
    <>
      <Header
        title="Configurações"
        icon="fa-solid fa-gear"
        showSidebar={showSidebar}
      setSidebar={setSidebar}
      toggleTheme={toggleTheme}
      />
      <div className="grid mt-4">
        <NewServiceCallType allowedActions={allowedActions} />
      </div>
      <div
        style={{
          marginTop: '30px',
        }}
      >
        <ServiceCallTypesList allowedActions={allowedActions} />
      </div>
    </>
  );
};
export default ConfigPage;
