import styled, {keyframes} from 'styled-components';

export const SidebarContainer = styled.div`
  background-color: #36b37e;
  // overflow: scroll;
  min-height: 100vh;
  max-height: 100vh;
  display: none;
  width: 240px;
  top: 0;
  bottom: 0;
  .nav-item {
    height: 56px;
    padding: 10px;
    vertical-align: auto;
    position: relative;
    cursor: pointer;
    margin-bottom: 10px;
  }
    @media only screen and (min-width: 1240px) {
      display: block;
    }
  }

  .nav-item::before {
    content: '';
    display: block;
    height: 56px;
    width: 3px;
    background-color: none;
    position: absolute;
    left: 0;
    transition: all 0.3s;
  }

  .nav-item:hover:before {
    background-color: #dde2ff;
    transition: 100;
  }

  .nav-link {
    color: white;
    padding: 15px 10px 10px 10px;
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 16px;
  }

  .nav-link svg {
    margin-right: 5px;
  }
`;

const downs = keyframes`  
  from { transform: translateY(5px) }
  to { transform: translateY(0px) }
`;

export const SidebarItensContainer = styled.div`
  display: grid;
  flex-direction: column;
  align-items: left;
  overflow-y: scroll;
  max-height: 60vh;

  .animation-down {
    animation: ${downs} 300ms infinite;
    background-color: #FFF;
  }
`;

interface TypesContainer {
  backgroundColor: string
}

export const Container = styled.div<TypesContainer>`
  min-height: 100vh;
  width: 100%;
  padding: 20px;
  background-color: ${props => props.backgroundColor};
  max-height: 100vh;
  overflow-y: scroll;

  @media(min-height: 650px) {
    overflow-y: hidden;
  }
`