/* eslint-disable */
import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { BsCalendar } from 'react-icons/bs';
import { Table } from 'reactstrap';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import { Eye } from '../../assets/svg/Eye';
import AllotmentRegister from '../AllotmentRegister';
import { IAllotment } from '../../types/IAllotment';
import TimelineModal from '../TimelineModal';
import { Tooltip } from 'primereact/tooltip';
import { DataTableStyle } from '../SchoolsTable/styles';
import { Column } from 'primereact/column';
import { ThemeContext } from 'styled-components';
import { classNames } from 'primereact/utils';
import { Ripple } from 'primereact/ripple';
import { InputTextStyle } from '../ProfilesTable/styles';

const AllotmentList = () => {
  const [data, setData] = useState();
  const [modal, setModal] = useState(false);
  const [allotments, setAllotments] = useState<IAllotment[]>([]);
  const [selectedAllotment, setSelectedAllotment] =
    useState<IAllotment | null>();
  const [loading, setLoading] = useState(false);


  const { colors } = React.useContext(ThemeContext);

  const toggle = () => {
    setModal(!modal);
  };

  async function loadAllotments() {
    try {
      const response = await api.get('/allotment');
      setAllotments(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const pagination = {
    layout: 'PrevPageLink PageLinks NextPageLink',
    PrevPageLink: (options: any) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          style={{ borderRadius: '8px' }}
          disabled={options.disabled}
        >
          <span className="p-3">Anterior</span>
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options: any) => {
      return (
        <button
          type="button"
          className={options.className}
          style={{ borderRadius: '8px' }}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-3">Próximo</span>
          <Ripple />
        </button>
      );
    },
    PageLinks: (options: any) => {
      if (
        (options.view.startPage === options.page &&
          options.view.startPage !== 0) ||
        (options.view.endPage === options.page &&
          options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { 'p-disabled': true });

        return (
          <span
            className={className}
            style={{ userSelect: 'none', borderRadius: '3px' }}
          >
            ...
          </span>
        );
      }

      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          style={{ marginRight: '10px' }}
        >
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
  };

  const options = React.useCallback(
    (rowData: any) => (
      <div className="flex vertical-align-middle gap-2">
        <Link
          className="text-decoration-none"
          to={{
            pathname: `lotes/lote/cronogramas/${rowData.id}`,
            state: rowData.id,
          }}
        >
          <div
            style={{
              cursor: 'pointer',
              marginTop: '5px',
            }}
          >
            <Tooltip content="Cronograma" position="top" target=".cronograma" />
            <i className="fa-regular fa-calendar icon-cronograma cronograma"></i>
          </div>
        </Link>
        <Link
          className="text-decoration-none"
          to={{
            pathname: `lotes/lote/${rowData.id}`,
            state: rowData.id,
          }}
        >
          <div
            style={{
              cursor: 'pointer',
            }}
          >
            <Tooltip
              content="Visualizar"
              position="top"
              target=".icon-visualizar"
            />
            <i className="fa-solid fa-eye icon-visualizar"></i>
          </div>
        </Link>
        <div>
          <Tooltip
            content="Configurar"
            position="top"
            target=".icon-configuracao"
          />
          <i
            onClick={() => {
              setSelectedAllotment(rowData);
              toggle();
            }}
            className="fa-solid fa-gear icon-configuracao"
          ></i>
        </div>
      </div>
    ),
    [],
  );

  useEffect(() => {
    setLoading(true);
    loadAllotments();
  }, []);

  const optionsFilter: any = {
    name: { placeholder: 'Nome' },
    'allotment_type.type': { placeholder: 'Tipo de lote' },
    'responsavel.full_name': { placeholder: 'Responsável' },
    available_value: { placeholder: 'Valor disponível' },
  };

  const filterTemplate = (options: any) => {
    return (
      <span className="p-input-icon-right">
        <i className="pi pi-search" />
        <InputTextStyle
          color={colors.text}
          placeholder={optionsFilter[options?.field].placeholder}
          onChange={(e) => options.filterApplyCallback(e.target.value)}
          style={{
            backgroundColor: colors.background,
            color: colors.text,
            border: 'none',
          }}
        />
      </span>
    );
  };

  const HeaderInFilter = ({ label }: any) => (
    <p className="text-center" style={{ fontSize: '1rem', color: colors.text }}>
      {label}
    </p>
  );

  return (
    <>
      <div className="flex justify-end">
        <Button
          className="px-3 py-2 btn-cadastro border-0 mt-2 mb-2"
          onClick={() => {
            setSelectedAllotment(null);
            toggle();
          }}
          icon="fa-solid fa-plus"
          label="Cadastrar Lote"
        />
      </div>
      <DataTableStyle
        tableClassName='header-filter'
        value={allotments}
        showGridlines
        className="mt-2 drop-shadow-md"
        size="small"
        stripedRows
        dataKey="id"
        paginator
        scrollable
        scrollDirection="vertical"
        scrollHeight="50vh"
        rows={10}
        filterDisplay="row"
        rowsPerPageOptions={[5, 10, 25]}
        paginatorTemplate={pagination}
        responsiveLayout="scroll"
        loading={loading}
        backgroundColor={colors.container}
        backgroundButtonPaginate={colors.btn_color}
        colorButtonPaginate={colors.text}
        backgroundButtonPaginateActive={colors.icon_visu}
        colorButtonPaginateActive={'#FFF'}
        rowStripedColor={colors.stripedRow}
        rowsColor={colors.row}
        colorIcon={colors.secundary}
      >
        <Column
          field="name"
          headerClassName="pl-3 py-3"
          headerStyle={{
            backgroundColor: colors.background,
            color: colors.text,
            borderRadius: '5px 0 0 0',
          }}
          filter
          filterMatchMode="contains"
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          filterPlaceholder="Nome"
          filterHeaderStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          bodyStyle={{ color: colors.text }}
          header="Nome"
          style={{ minWidth: '12rem' }}
          showClearButton={false}
          showFilterMenu={false}
          filterElement={filterTemplate}
          sortable
        />
        <Column
          field="allotment_type.type"
          headerClassName="pl-3 py-3"
          headerStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          bodyStyle={{ color: colors.text }}
          filter
          filterMatchMode="contains"
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          filterPlaceholder="Tipo do lote"
          filterHeaderStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          header="Tipo do lote"
          showClearButton={false}
          showFilterMenu={false}
          filterElement={filterTemplate}
          style={{ minWidth: '12rem' }}
          sortable
        />
        <Column
          field="responsavel.full_name"
          headerClassName="pl-3 py-3"
          headerStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          bodyStyle={{ color: colors.text }}
          filter
          filterMatchMode="contains"
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          filterPlaceholder="Reponsável"
          filterHeaderStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          header="Responsável"
          showClearButton={false}
          showFilterMenu={false}
          filterElement={filterTemplate}
          style={{ minWidth: '12rem' }}
          sortable
        />
        <Column
          field="available_value"
          headerClassName="pl-3 py-3"
          headerStyle={{
            backgroundColor: colors.background,
            color: colors.text,
            borderRadius: '0 5px 0 0',
          }}
          filter
          filterMatchMode="contains"
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          filterPlaceholder="Valor Disponível"
          filterHeaderStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          bodyStyle={{ color: colors.text }}
          header="Valor Disponível"
          showClearButton={false}
          showFilterMenu={false}
          filterElement={filterTemplate}
          style={{ minWidth: '12rem' }}
          sortable
        />
        <Column
          headerClassName="pl-3 py-3"
          headerStyle={{
            backgroundColor: colors.background,
            color: colors.text,
            borderRadius: '0 5px 0 0',
          }}
          filterHeaderStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          bodyStyle={{ color: colors.text }}
          showClearButton={false}
          showFilterMenu={false}
          body={options}
          header="Opções"
          filter
          filterElement={<HeaderInFilter label="Opções" />}
          style={{ minWidth: '7rem' }}
          sortable
        />
      </DataTableStyle>
      {/* <Table>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Tipo do Lote</th>
            <th>Responsável</th>
            <th>Valor Disponível</th>
            <th>Opções</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <div
              style={{
                position: 'absolute',
                left: '50%',
                right: '50%',
              }}
            >
              <ReactLoading type="bars" color="#36B37E" />
            </div>
          ) : (
            allotments.map((allotment: any) => (
              <tr key={allotment.id}>
                <td>{allotment.name}</td>
                <td>{allotment.allotment_type?.type}</td>
                <td>{allotment.responsavel.full_name}</td>
                <td>{allotment.available_value}</td>
                <td className="flex vertical-align-middle gap-2">
                  <Link
                    className="text-decoration-none"
                    to={{
                      pathname: `/lotes/lote/cronogramas/cronograma/${allotment.id}`,
                      state: allotment.id,
                    }}
                  >
                    <div
                      style={{
                        cursor: 'pointer',
                        marginTop: '5px',
                      }}
                    >
                      <Tooltip
                        content="Cronograma"
                        position="top"
                        target=".cronograma"
                      />
                      <i className="fa-regular fa-calendar icon-cronograma cronograma"></i>
                    </div>
                  </Link>
                  <Link
                    className="text-decoration-none"
                    to={{
                      pathname: `lotes/lote/${allotment.id}`,
                      state: allotment.id,
                    }}
                  >
                    <div
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <Tooltip
                        content="Visualizar"
                        position="top"
                        target=".icon-visualizar"
                      />
                      <i className="fa-solid fa-eye icon-visualizar"></i>
                    </div>
                  </Link>
                  <div>
                    <Tooltip
                      content="Configurar"
                      position="top"
                      target=".icon-configuracao"
                    />
                    <i
                      onClick={() => {
                        setSelectedAllotment(allotment);
                        toggle();
                      }}
                      className="fa-solid fa-gear icon-configuracao"
                    ></i>
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table> */}
      <AllotmentRegister
        toggle={toggle}
        modal={modal}
        loadAllotments={loadAllotments}
        selectedAllotment={selectedAllotment}
        setSelectedAllotment={setSelectedAllotment}
      />
    </>
  );
};

export default AllotmentList;
