import React from 'react';
import Header from '../../components/Header';
import UsersTable from '../../components/UsersTable';

const UsersPage = ({ showSidebar, setSidebar, toggleTheme }: any) => (
  <>
    <Header
      title="Usuários"
      icon="fa-solid fa-user"
      showSidebar={showSidebar}
      setSidebar={setSidebar}
      toggleTheme={toggleTheme}
    />
    <div>
      <UsersTable />
    </div>
  </>
);

export default UsersPage;
