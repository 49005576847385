import { Column } from 'primereact/column';
import { Ripple } from 'primereact/ripple';
import { classNames } from 'primereact/utils';
import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { useParams } from 'react-router-dom';
import { Table } from 'reactstrap';
import { ThemeContext } from 'styled-components';
import api from '../../services/api';
import { ICompany } from '../../types/ICompany';
import { ISchoolAllotment } from '../../types/ISchoolAllotment';
import { price_to_number } from '../../utils/price_to_number';
import { DataTableStyle } from '../SchoolsTable/styles';

const FinancialStatementList = () => {
  const [data, setData] = useState<ISchoolAllotment[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalValue, setTotalValue] = useState<any>();

  const { id } = useParams<any>();

  const { colors } = React.useContext(ThemeContext);

  const loadCompany = async () => {
    try {
      const { data } = await api.get(`/company/${id}`);
      setData(data?.school_allotment);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const pagination = {
    layout: 'PrevPageLink PageLinks NextPageLink',
    PrevPageLink: (options: any) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          style={{ borderRadius: '8px' }}
          disabled={options.disabled}
        >
          <span className="p-3">Anterior</span>
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options: any) => {
      return (
        <button
          type="button"
          className={options.className}
          style={{ borderRadius: '8px' }}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-3">Próximo</span>
          <Ripple />
        </button>
      );
    },
    PageLinks: (options: any) => {
      if (
        (options.view.startPage === options.page &&
          options.view.startPage !== 0) ||
        (options.view.endPage === options.page &&
          options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { 'p-disabled': true });

        return (
          <span
            className={className}
            style={{ userSelect: 'none', borderRadius: '3px' }}
          >
            ...
          </span>
        );
      }

      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          style={{ marginRight: '10px' }}
        >
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
  };

  useEffect(() => {
    setLoading(true);
    loadCompany();
  }, [id]);

  return (
    <>
      {/* <Table striped>
        <thead>
          <tr>
            <th>Escola</th>
            <th>Município</th>
            <th>Lote</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody>
          {
            loading ? (
              <div style={{
                position: 'absolute',
                left: '50%',
                right: '50%',
              }}
              >
                <ReactLoading type="bars" color="#36B37E" />
              </div>
            ) : (
              data.map((item: any) => (
                <tr key={item.id}>
                  <td>{item?.escola?.name}</td>
                  <td>{item?.escola?.city}</td>
                  <td>{item?.allotment?.name}</td>
                  <td>{item?.value}</td>
                </tr>
              ))
            )
          }
        </tbody>
      </Table> */}
      <DataTableStyle
        value={data}
        showGridlines
        className="mt-4"
        size="small"
        stripedRows
        dataKey="id"
        paginator
        scrollable
        scrollDirection="vertical"
        scrollHeight="50vh"
        rows={10}
        rowsPerPageOptions={[5, 10, 25]}
        paginatorTemplate={pagination}
        responsiveLayout="scroll"
        loading={loading}
        backgroundColor={colors.container}
        backgroundButtonPaginate={colors.btn_color}
        colorButtonPaginate={colors.text}
        backgroundButtonPaginateActive={colors.icon_visu}
        colorButtonPaginateActive={'#FFF'}
        rowStripedColor={colors.stripedRow}
        rowsColor={colors.row}
        colorIcon={colors.secundary}
      >
        <Column
          field="escola.name"
          headerClassName="pl-3 py-3"
          headerStyle={{
            backgroundColor: colors.background,
            color: colors.text,
            borderRadius: "5px 0 0 0"
          }}
          filterMatchMode="contains"
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          filterPlaceholder="Lote"
          filterMenuStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          bodyStyle={{ color: colors.text }}
          header="Nome"
          style={{ minWidth: '12rem' }}
          sortable
        />
        <Column
          field="escola.city"
          headerClassName="pl-3 py-3"
          headerStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          bodyStyle={{ color: colors.text }}
       
          filterMatchMode="contains"
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          filterPlaceholder="Lote"
          filterMenuStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          header="Cidade"
          style={{ minWidth: '12rem' }}
          sortable
        />
        <Column
          field="allotment.name"
          headerClassName="pl-3 py-3"
          headerStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          bodyStyle={{ color: colors.text }}
    
          filterMatchMode="contains"
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          filterPlaceholder="Lote"
          filterMenuStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          header="Lote"
          style={{ minWidth: '12rem' }}
          sortable
        />
        <Column
          field="value"
          headerClassName="pl-3 py-3"
          headerStyle={{
            backgroundColor: colors.background,
            color: colors.text,
            borderRadius: "0 5px 0 0"
          }}
          filterMatchMode="contains"
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          filterPlaceholder="Lote"
          filterMenuStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          bodyStyle={{ color: colors.text }}
          header="Valor"
          style={{ minWidth: '12rem' }}
          sortable
        />
      </DataTableStyle>
    </>
  );
};

export default FinancialStatementList;
