/* eslint-disable */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import api from '../../services/api';
import { cep_mask } from '../../utils/cep_mask';
import { cnpj_mask } from '../../utils/cnpj_mask';
import { phone_mask } from '../../utils/phone_mask';
import { ThemeContext } from 'styled-components';
import { DialogStyle } from './CompanyModal.styled';

const INITIAL_STATE = {
  corporate_name: '',
  fantasy_name: '',
  cnpj: '',
  email: '',
  phone: '',
  occupation_area: '',
  city: '',
  state: '',
  cep: '',
  description: '',
};


const CompanyModal = ({
  allowedActions,
  modal,
  toggle,
  company,
  getCompanies,
}: any) => {
  const [data, setData] = useState(INITIAL_STATE);

  const { colors } = React.useContext(ThemeContext);

  function handleSubmit() {
    if (!company) {
      toast
        .promise(api.post('/company', data), {
          pending: 'Cadastrando...',
          success: 'Empresa cadastrada com sucesso',
          error: 'Ocorreu um erro ao cadastrar a empresa',
        })
        .finally(getCompanies());
    } else {
      toast
        .promise(api.patch(`/company/${company.id}`, data), {
          pending: 'Alterando...',
          success: 'Empresa alterada com sucesso',
          error: 'Ocorreu um erro ao alterar a empresa',
        })
        .finally(getCompanies());
    }
  }

  function deleteCompany() {
    if (company) {
      toast.promise(api.delete(`/company/${company.id}`), {
        pending: 'Excluindo...',
        success: 'Empresa excluída com sucesso',
        error: 'Ocorreu um erro ao excluir a empresa',
      });
    }
  }

  async function checkCep(e: any) {
    const cep = e.target.value.replace(/\D/g, '');
    await axios
      .get(`https://viacep.com.br/ws/${cep}/json/`)
      // @ts-nocheck
      .then((res) => {
        if (res.data.erro == true) {
          toast.error('Preencha um cep válido', {
            position: 'top-right',
          });
        } else {
          setData({ ...data, city: res.data.localidade, state: res.data.uf });
        }
      });
  }


  const footerModal = () => (
    <div className="flex justify-between gap-2 mt-3">
      {!company ? (
        <Button
          className="btn-cadastro h-10 font-bold"
          onClick={() => {
            handleSubmit();
            setData(INITIAL_STATE);
            toggle();
          }}
        >
          Cadastrar
        </Button>
      ) : (
        <>
          {allowedActions.acoes.update && (
            <Button
              className="btn-atualizar h-10 font-bold"
              onClick={handleSubmit}
            >
              Alterar
            </Button>
          )}{' '}
          {allowedActions.acoes.delete && (
            <Button
              className="btn-apagar h-10 font-bold"
              onClick={deleteCompany}
            >
              Excluir
            </Button>
          )}
        </>
      )}
    </div>
  );


  useEffect(() => {
    if (company == null) {
      setData(INITIAL_STATE);
    } else {
      setData(company);
    }
  }, [company]);
  return (
    <>
      <DialogStyle
        visible={modal}
        onHide={toggle}
        footer={footerModal}
        closable={true}
        backgroundColor={colors.container}
        contentStyle={{
          color: colors.text,
          height: '80vh',
          maxHeight: "500px",
          minWidth: '340px',
        }}
        header="Informações da empresa"
        headerStyle={{
          backgroundColor: colors.container,
          color: colors.text,
          minWidth: '340px',
          width: '30vw',
          maxWidth: '500px',
        }}
      >
        <ModalBody
          style={{ backgroundColor: colors.container, color: colors.text }}
        >
          <Form onSubmit={(e) => e.preventDefault()}>
              <FormGroup>
                <Label>Razão Social</Label>
                <Input
                  type="text"
                  value={data?.corporate_name}
                  onChange={(e) =>
                    setData({ ...data, corporate_name: e.target.value })
                  }
                  style={{
                    backgroundColor: colors.container,
                    color: colors.text,
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>Nome Fantasia</Label>
                <Input
                  type="text"
                  value={data?.fantasy_name}
                  onChange={(e) =>
                    setData({ ...data, fantasy_name: e.target.value })
                  }
                  style={{
                    backgroundColor: colors.container,
                    color: colors.text,
                  }}
                />
              </FormGroup>

              <FormGroup>
                <Label>CNPJ</Label>
                <Input
                  maxLength={18}
                  type="text"
                  value={data?.cnpj}
                  onChange={(e) =>
                    setData({ ...data, cnpj: cnpj_mask(e.target.value) })
                  }
                  style={{
                    backgroundColor: colors.container,
                    color: colors.text,
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>Telefone</Label>
                <Input
                  maxLength={15}
                  type="text"
                  value={data?.phone}
                  onChange={(e) =>
                    setData({ ...data, phone: phone_mask(e.target.value) })
                  }
                  style={{
                    backgroundColor: colors.container,
                    color: colors.text,
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  type="email"
                  value={data?.email}
                  onChange={(e) => setData({ ...data, email: e.target.value })}
                  style={{
                    backgroundColor: colors.container,
                    color: colors.text,
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>Descrição</Label>
                <Input
                  type="text"
                  value={data?.description}
                  onChange={(e) =>
                    setData({ ...data, description: e.target.value })
                  }
                  style={{
                    backgroundColor: colors.container,
                    color: colors.text,
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>Area de Ocupação</Label>
                <Input
                  type="text"
                  value={data?.occupation_area}
                  onChange={(e) =>
                    setData({ ...data, occupation_area: e.target.value })
                  }
                  style={{
                    backgroundColor: colors.container,
                    color: colors.text,
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>CEP</Label>
                <Input
                  maxLength={9}
                  type="text"
                  value={data?.cep}
                  onBlur={checkCep}
                  onChange={(e) =>
                    setData({ ...data, cep: cep_mask(e.target.value) })
                  }
                  style={{
                    backgroundColor: colors.container,
                    color: colors.text,
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>Cidade</Label>
                <Input
                  type="text"
                  value={data?.city}
                  onChange={(e) => setData({ ...data, city: e.target.value })}
                  style={{
                    backgroundColor: colors.container,
                    color: colors.text,
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>Estado</Label>
                <Input
                  type="text"
                  value={data?.state}
                  onChange={(e) => setData({ ...data, state: e.target.value })}
                  style={{
                    backgroundColor: colors.container,
                    color: colors.text,
                  }}
                />
              </FormGroup>
          </Form>
        </ModalBody>
      </DialogStyle>
    </>
  );
};

export default CompanyModal;
