/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InstitutionContext } from '../../context/institutionContext';
import { SchoolContext } from '../../context/schoolContext';
import { ProfileContext } from '../../context/profileContext';
import { UserContext } from '../../context/userContext';
import api from '../../services/api';
import { cpf_mask } from '../../utils/cpf_mask';
import { phone_mask } from '../../utils/phone_mask';
import { IUser } from '../../types/IUser';
import { ThemeContext } from 'styled-components';
import { DropdownStyle } from './styles';

const UserModal = ({ allowedActions, modal, toggle, user }: any) => {
  const { schools } = useContext(SchoolContext);
  const { profiles, loadProfiles } = useContext(ProfileContext);

  const [checked, setChecked] = useState(true);

  const { colors } = React.useContext(ThemeContext);

  const { institutions } = useContext(InstitutionContext);
  const { users, setUsers, getUsers } = useContext(UserContext);
  const [data, setData] = useState<IUser>({
    id: 0,
    cpf: '',
    email: '',
    full_name: '',
    phone: '',
    password: '',
    school_id: null,
    institution_id: null,
    profile_id: 0,
  });

  function handleSubmit() {
    if (!user) {
      if (data.institution_id === 0 || data.institution_id === null)
        delete data.institution_id;

      // @ts-ignore
      delete data.id;
      toast
        .promise(
          api.post('/auth/signup', {
            ...data,
            institution_id: checked ? data.institution_id : null,
            school_id: !checked ? data.school_id : null,
          }),
          {
            pending: 'Cadastrando....',
            success: 'Usuário cadastrado',
            error: 'Ocorreu um erro ao cadastrar o usuário',
          },
        )
        .then(({ data }) => {
          getUsers();
        });
    } else {
      toast
        .promise(
          api.patch(`/users/${user.cpf}`, {
            ...data,
            institution_id: checked ? data.institution_id : null,
            school_id: !checked ? data.school_id : null,
          }),
          {
            pending: 'Alterando....',
            success: 'Usuário Alterado',
            error: 'Ocorreu um erro ao alterar o usuário',
          },
        )
        .then(({ data }) => {
          getUsers();
        });
    }
  }

  function deleteUser() {
    if (user) {
      toast.promise(api.delete(`/users/${user.cpf}`), {
        pending: 'Deletando....',
        success: 'Usuário Deletado',
        error: 'Ocorreu um erro ao deletar o usuário',
      });
    }
  }

  useEffect(() => {
    loadProfiles();

    if (user == null) {
      setData({
        id: 0,
        cpf: '',
        email: '',
        full_name: '',
        phone: '',
        password: '',
        school_id: 0,
        institution_id: 0,
        profile_id: 0,
      });
      setChecked(true);
    } else {
      setChecked(!user.school_id);
      setData(user);
    }
  }, [user]);

  const schoolSelect = schools?.map((schools) => ({
    label: schools.name,
    value: schools.id,
  }));

  const instituitionSelect = institutions?.map((instituition) => ({
    label: instituition.name,
    value: instituition.id,
  }));

  return (
    <>
      <Dialog
        header="Cadastrar Novo Usuário"
        contentStyle={{ backgroundColor: colors.container, color: colors.text, minWidth: "340px", maxWidth: "500px" }}
        headerStyle={{ backgroundColor: colors.container, color: colors.text, minWidth: "340px", maxWidth: "500px" }}
        visible={modal}
        onHide={toggle}
      >
        <ModalBody className="mt-2">
          <Form className="p-2" onSubmit={(e) => e.preventDefault()}>
            <div className="grid grid-cols-2 gap-2 max-[480px]:grid-cols-1">
              <FormGroup>
                <Label>Nome Completo</Label>
                <Input
                  type="text"
                  value={data?.full_name}
                  onChange={(e) =>
                    setData({ ...data, full_name: e.target.value })
                  }
                  style={{
                    backgroundColor: colors.container,
                    color: colors.text,
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>CPF</Label>
                <Input
                  maxLength={14}
                  type="text"
                  value={data?.cpf}
                  onChange={(e) =>
                    setData({ ...data, cpf: cpf_mask(e.target.value) })
                  }
                  style={{
                    backgroundColor: colors.container,
                    color: colors.text,
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>E-mail</Label>
                <Input
                  type="email"
                  value={data?.email}
                  onChange={(e) => setData({ ...data, email: e.target.value })}
                  style={{
                    backgroundColor: colors.container,
                    color: colors.text,
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label>Telefone</Label>
                <Input
                  maxLength={15}
                  type="text"
                  value={data?.phone}
                  onChange={(e) =>
                    setData({ ...data, phone: phone_mask(e.target.value) })
                  }
                  style={{
                    backgroundColor: colors.container,
                    color: colors.text,
                  }}
                />
              </FormGroup>

              <FormGroup>
                <Label>Função</Label>
                <Input
                  type="select"
                  value={data?.profile_id}
                  onChange={(e) =>
                    setData({ ...data, profile_id: Number(e.target.value) })
                  }
                  style={{
                    backgroundColor: colors.container,
                    color: colors.text,
                  }}
                >
                  <option>-</option>
                  {profiles.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </Input>
              </FormGroup>

              <FormGroup>
                <Label>Senha</Label>
                <Input
                  type="password"
                  value={data?.password}
                  onChange={(e) =>
                    setData({ ...data, password: e.target.value })
                  }
                  style={{
                    backgroundColor: colors.container,
                    color: colors.text,
                  }}
                />
              </FormGroup>
            </div>

            <div style={{ display: 'flex', marginBottom: 10 }}>
              <FormGroup check>
                <Input
                  name="radio1"
                  type="radio"
                  // @ts-ignore
                  checked={checked}
                  onClick={() => setChecked(true)}
                  style={{
                    backgroundColor: colors.container,
                    color: colors.text,
                    borderColor: colors.text,
                  }}
                />{' '}
                <Label check>Instituição</Label>
              </FormGroup>
              <FormGroup check disabled style={{ marginLeft: 10 }}>
                <Input
                  name="radio1"
                  type="radio"
                  onClick={() => setChecked(false)}
                  style={{
                    backgroundColor: colors.container,
                    color: colors.text,
                    borderColor: colors.text,
                  }}
                  checked={!checked}
                />{' '}
                <Label check>Escola</Label>
              </FormGroup>
            </div>

            <Row>
              {checked && (
                <FormGroup>
                  <Label>Instituição</Label>
                  <DropdownStyle
                    filter
                    filterBy="label"
                    optionLabel="label"
                    optionValue="value"
                    className="w-full"
                    value={data?.institution_id}
                    onChange={(e) =>
                      setData({
                        ...data,
                        institution_id: Number(e.target.value),
                      })
                    }
                    backgroundColor={colors.container}
                    color={colors.text}
                    hoverBorder={colors.secundary}
                    options={instituitionSelect}
                  />
                </FormGroup>
              )}

              {!checked && (
                <FormGroup>
                  <Label>Selecione a escola</Label>
                  <DropdownStyle
                    filter
                    filterBy="label"
                    optionLabel="label"
                    optionValue="value"
                    className="w-full"
                    value={data.school_id}
                    onChange={(e) => {
                      setData({ ...data, school_id: Number(e.target.value) });
                    }}
                    backgroundColor={colors.container}
                    color={colors.text}
                    hoverBorder={colors.secundary}
                    options={schoolSelect}
                  />
                </FormGroup>
              )}
            </Row>
            <div className="flex justify-between gap-2">
              {' '}
              {!user ? (
                <Button
                  className="px-3 py-2 btn-cadastro border-0"
                  onClick={() => {
                    handleSubmit();
                    toggle();
                  }}
                >
                  Cadastrar
                </Button>
              ) : (
                <>
                  {allowedActions.acoes.update && (
                    <Button
                      className="px-3 py-2 btn-atualizar border-0"
                      onClick={() => {
                        handleSubmit();
                        toggle();
                      }}
                    >
                      Alterar
                    </Button>
                  )}{' '}
                  {allowedActions.acoes.delete && (
                    <Button
                      className="px-3 py-2 btn-apagar border-0"
                      onClick={() => {
                        deleteUser();
                        toggle();
                      }}
                    >
                      Excluir
                    </Button>
                  )}
                </>
              )}
            </div>
          </Form>
        </ModalBody>
      </Dialog>
    </>
  );
};

export default UserModal;
