import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import {
  Form, Input, InputGroup, Label,
} from 'reactstrap';
import { Button } from "primereact/button";
import api from '../../services/api';

import { ServiceTypesContext } from '../../context/serviceCallTypesContext';
import { ThemeContext } from 'styled-components';

const NewServiceCallType = ({ allowedActions }: any) => {
  const [type, setType] = useState("");

  const { types, setTypes } = useContext(ServiceTypesContext);

  const {colors} = React.useContext(ThemeContext)

  const createType = () => {
    toast.promise(api.post('/service-call-type', {
      type,
    }), {
      pending: "Cadastrando Tipo...",
      success: "Tipo cadastrado com sucesso",
      error: "Ocorreu um erro ao cadastrar o tipo de chamado",
    }).then(({ data }) => {
      setTypes([...types, { ...data, service_call_subtype: [] }]);
    });
  };

  return (
    <>
      <div className="justify-self-end mx-2">
        {allowedActions.acoes.create && (
          <Form onSubmit={(e) => e.preventDefault()}>
            <Label className="font-bold" style={{color: colors.text}}>Cadastrar Tipos de Chamado</Label>
            <InputGroup
              className="w-full"
            >
              <Input
                required
                type="text"
                value={type}
                onChange={(e) => setType(e.target.value)}
              />
              <Button
                className="px-3 py-2 btn-cadastro-group border-0"
                onClick={() => {
                  createType();
                  setType('');
                }}
              >
                Cadastrar
              </Button>
            </InputGroup>
          </Form>
        )}
      </div>
    </>
  );
};

export default NewServiceCallType;
