import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import styled, { ThemeProvider } from 'styled-components';
import usePersistedState from './utils/usePersistedState';
import light from './styles/themes/light';
import dark from './styles/themes/dark';

import './assets/tailwind/index.css';
import 'react-toastify/dist/ReactToastify.css';
import { InstitutionsProvider } from './context/institutionContext';
import { SchoolsProvider } from './context/schoolContext';
import { ServiceTypesProvider } from './context/serviceCallTypesContext';
import { UsersProvider } from './context/userContext';
import { ProfilesProvider } from './context/profileContext';

import AppProvider from './hooks';
import Routes from './routes';
import { SchoolTypesProvider } from './context/schoolTypesContext';
import { TimelineProvider } from './context/timelineContext';

function App() {
  const [theme, setTheme] = usePersistedState('theme', light);

  const toggleTheme = React.useCallback((title: any) => {
    setTheme(
      title === 'dark'
        ? { ...dark, font: { ...dark.font } }
        : { ...light, font: { ...light.font } },
    );
  }, []);

  return (
    <BrowserRouter>
      <AppProvider>
        <ProfilesProvider>
          <SchoolsProvider>
            <SchoolTypesProvider>
              <UsersProvider>
                <ServiceTypesProvider>
                  <InstitutionsProvider>
                    <TimelineProvider>
                      <ThemeProvider theme={theme}>
                        <Routes toggleTheme={toggleTheme} />
                      </ThemeProvider>
                      <ToastContainer theme="colored" />
                    </TimelineProvider>
                  </InstitutionsProvider>
                </ServiceTypesProvider>
              </UsersProvider>
            </SchoolTypesProvider>
          </SchoolsProvider>
        </ProfilesProvider>
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;
