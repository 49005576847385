/* eslint-disable */
// @ts-nocheck
import React, { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { BiTrash } from 'react-icons/bi';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { Dialog } from 'primereact/dialog';
import { useHistory } from 'react-router-dom';
import { InstitutionContext } from '../../context/institutionContext';
import { ServiceTypesContext } from '../../context/serviceCallTypesContext';
import api from '../../services/api';
import { ISubtype } from '../../types/ISubtype';
import { IReferralType } from '../ReferralTypeTable';

import { useAuth } from '../../hooks/auth';
import { ThemeContext } from 'styled-components';

const ServiceCallForwardingModal = ({
  modal,
  toggle,
  loadHistory,
  loadReferrals,
  service_call_id,
}: any) => {
  const [status, setStatus] = useState({});
  const [priority, setPriority] = useState({});
  const [data, setData] = useState<ISubtype[]>();
  const [referralType, setReferralType] = useState<IReferralType[]>([]);
  const [typeSubtype, setTypeSubtype] = useState({
    type: 0,
    subtype: 0,
  });
  const [referral, setRefferal] = useState({
    description: '',
    institution_id: 0,
    referral_type_id: 0,
    service_call_id: Number(service_call_id),
  });

  const [images, setImages] = useState([]);

  const { colors } = useContext(ThemeContext);

  const { user } = useAuth();

  const { institutions } = useContext(InstitutionContext);

  const { types } = useContext(ServiceTypesContext);

  const history = useHistory();

  function handleServiceCallType() {
    toast.promise(api.patch(`service-call/${service_call_id}`, typeSubtype), {
      pending: 'Alterando classificação do chamado...',
      success: 'Classificação alterada com sucesso',
      error: 'Ocorreu um erro ao alterar a classificação',
    });
  }

  function changeStatus() {
    toast
      .promise(
        api.patch(`service-call/${service_call_id}`, {
          ...status,
          ...typeSubtype,
          ...priority,
        }),
        {
          pending: 'Alterando status do chamado...',
          success: 'Status alterado com sucesso',
          error: 'Ocorreu um erro ao alterar o status',
        },
      )
      .then(() => {
        loadReferrals();
      });
  }

  function changePriority() {
    toast.promise(api.patch(`service-call/${service_call_id}`, priority), {
      pending: 'Alterando prioridade do chamado...',
      success: 'prioridade alterada com sucesso',
      error: 'Ocorreu um erro ao alterar prioridade',
    });
  }

  async function createReferral() {
    try {
      toast
        .promise(
          api.post(`referrals`, {
            ...referral,
            institution_id_origem: user.institution_id,
          }),
          {
            pending: `Encaminhando chamado...`,
            success: 'Chamado encaminhado com sucesso',
            error: 'Ocorreu um erro ao encaminhar chamado',
          },
        )
        .then(() => {
          toast
            .promise(
              api.patch(`service-call/${service_call_id}`, {
                institution_id: referral.institution_id,
              }),
              {
                pending: 'Alterando status do chamado...',
                success: 'Status alterado com sucesso',
                error: 'Ocorreu um erro ao alterar o status',
              },
            )
            .then(async () => {
              if (images.length > 0) {
                toast.success('Fazendo Upload do(s) anexo(s)...', {
                  position: 'top-right',
                });

                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < images.length; i++) {
                  const formData = new FormData();
                  formData.append('file', images[i].file);

                  // eslint-disable-next-line no-await-in-loop
                  await api.post(`upload/${service_call_id}`, formData);
                }

                history.goBack();
              } else {
                toggle();
                history.goBack();
              }
            });
        });
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    async function getSubtypes() {
      const { data } = await api.get(
        `/service-call-subtype/${typeSubtype.type}`,
      );
      setData(data);
    }

    async function getReferralTypes() {
      const { data } = await api.get(
        `/referrals-type/${referral.institution_id}`,
      );

      setReferralType(data);
    }

    getReferralTypes();
    getSubtypes();
  }, [typeSubtype.type, referral.institution_id]);

  return (
    <Dialog
      header="Encaminhamento do chamado"
      visible={modal}
      onHide={toggle}
      contentStyle={{
        backgroundColor: colors.container,
        color: colors.text,
        minWidth: '340px',
        width: '50vw',
        maxWidth: '500px',
      }}
      headerStyle={{
        backgroundColor: colors.container,
        color: colors.text,
        minWidth: '340px',
        width: '50vw',
        maxWidth: '500px',
      }}
    >
      <ModalBody>
        <Form onSubmit={(e) => e.preventDefault()}>
          <FormGroup>
            <Label>Descrição</Label>
            <Input
              type="textarea"
              style={{
                marginBottom: 10,
                backgroundColor: 'transparent',
                color: colors.text,
              }}
              rows={5}
              onChange={(e) =>
                setRefferal({ ...referral, description: e.target.value })
              }
            />
            <Label>Instituição</Label>
            <Input
              style={{
                marginBottom: 10,
                backgroundColor: 'transparent',
                color: colors.text,
              }}
              type="select"
              onChange={(e) =>
                setRefferal({
                  ...referral,
                  institution_id: Number(e.target.value),
                })
              }
            >
              <option>-</option>
              {institutions?.map((institution) => (
                <option value={institution.id}>{institution.name}</option>
              ))}
            </Input>
            <Label>Tipo de Encaminhamento</Label>
            <Input
              style={{
                marginBottom: 20,
                backgroundColor: 'transparent',
                color: colors.text,
              }}
              type="select"
              onChange={(e) =>
                setRefferal({
                  ...referral,
                  referral_type_id: Number(e.target.value),
                })
              }
            >
              <option>-</option>
              {referralType?.map((item) => (
                <option value={item.id}>{item.description}</option>
              ))}
            </Input>
          </FormGroup>

          <div>
            {images.map((item) => (
              <>
                <FormGroup>
                  <Label>Anexo</Label>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Input
                      className="inputfile"
                      onChange={(e) => {
                        setImages(
                          images.map((file) =>
                            file.id === item.id
                              ? { ...file, file: e.target.files[0] }
                              : file,
                          ),
                        );
                      }}
                      type="file"
                    />
                    <BiTrash
                      style={{
                        fontSize: 30,
                        marginLeft: 10,
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        setImages(images.filter((file) => file.id !== item.id))
                      }
                    />
                  </div>
                </FormGroup>

                <div style={{ marginBottom: 20 }}>
                  <label style={{ fontWeight: 'bold', color: colors.text }}>
                    Arquivo anexado:
                  </label>
                  <td style={{ textAlign: 'justify', color: '#626262' }}>
                    <a
                      style={{ textDecoration: 'none' }}
                      className="url"
                      href={item.file && window.URL.createObjectURL(item.file)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.file ? item.file.name : 'Sem anexo'}
                    </a>
                  </td>
                </div>
              </>
            ))}
            <div>
              <Button
                type="button"
                className="bg-blue-500 hover:bg-blue-400 border-none"
                onClick={() => {
                  setImages([
                    ...images,
                    {
                      id: Date.now(),
                      file: null,
                    },
                  ]);
                }}
              >
                Adicionar anexo
              </Button>
            </div>
          </div>
          <div style={{ display: 'grid' }}>
            <Button
              onClick={() => {
                createReferral();
                setRefferal({
                  ...referral,
                  service_call_id: Number(service_call_id),
                });
              }}
              className="px-6 btn-cadastro py-2 border-0"
              style={{ justifySelf: 'end' }}
            >
              Salvar
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Dialog>
  );
};

export default ServiceCallForwardingModal;
