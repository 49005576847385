import React, { useContext, useState } from 'react';
import { ThemeContext } from 'styled-components';
import CardDash from '../CardDashboard';

const SchoolDashboard = ({ selectedSchool }: any) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const dataDash: any = '';

  const { colors } = useContext(ThemeContext);

  return (
    <>
      <h1
        style={{
          color: colors.text,
          fontSize: '20px',
        }}
      >
        {selectedSchool.name}
      </h1>
      <div className="grid grid-cols-3 px-2 gap-2 max-[450px]:grid-cols-1 max-[850px]:grid-cols-2">
        <CardDash
          label="Quantidade de Salas"
          icon="fa-solid fa-chalkboard-user text-xl"
          value={
            loading ? (
              <i className="fa-solid fa-arrows-rotate pi-spin" />
            ) : (
              selectedSchool?.classrooms
            )
          }
          colorIcon="#609AF8"
          classNameCard="border-2 border-blue-400"
          classNameBoxIcon="bg-blue-100 border-2 border-blue-400"
          classNameLabel="text-blue-400"
          classNameValue="text-blue-500"
          labelDescricao="Quantidade de salas"
        />
        <CardDash
          label="Quantidade de Banheiros"
          icon="fa-solid fa-toilet text-xl"
          value={
            loading ? (
              <i className="fa-solid fa-arrows-rotate pi-spin" />
            ) : (
              selectedSchool.bathrooms
            )
          }
          colorIcon="#6E7781"
          classNameCard="border-2 border-neutra-500"
          classNameBoxIcon="bg-neutra-200 border-2 border-neutra-500"
          classNameLabel="text-neutra-500"
          classNameValue="text-neutra-500"
          labelDescricao="Quantidade de banheiros"
        />
        <CardDash
          label="Área do Imóvel"
          icon="fa-solid fa-tape text-xl"
          value={
            loading ? (
              <i className="fa-solid fa-arrows-rotate pi-spin" />
            ) : (
              `${selectedSchool.school_area} m²`
            )
          }
          colorIcon="#36B37E"
          classNameCard="border-2 border-green-400"
          classNameBoxIcon="bg-green-100 border-2 border-green-400"
          classNameLabel="text-green-400"
          classNameValue="text-green-500"
          labelDescricao="Área do Imóvel"
        />
        <CardDash
          label="Possui Cozinha ?"
          icon="fa-solid fa-kitchen-set text-md"
          value={
            loading ? (
              <i className="fa-solid fa-arrows-rotate pi-spin" />
            ) : selectedSchool.dining_hall ? (
              <p>Sim</p>
            ) : (
              <p>Não</p>
            )
          }
          colorIcon={''}
          classNameCard={`border-2  ${
            !selectedSchool?.dining_hall
              ? ' opacity-70 border-slate-400'
              : 'border-primario-500'
          }`}
          classNameBoxIcon={`bg-slate-100 border-2 ${
            !selectedSchool?.dining_hall
              ? ' opacity-70 text-slate-500 border-slate-400'
              : 'border-primario-500 text-primario-500'
          }`}
          classNameLabel={`${
            !selectedSchool?.dining_hall
              ? 'text-slate-400'
              : 'text-primario-500'
          }`}
          classNameValue={`${
            !selectedSchool?.dining_hall
              ? ' opacity-70 text-slate-400'
              : 'text-primario-500'
          }`}
          labelDescricao="Possuí Cozinha ?"
        />
        <CardDash
          label="Possui Internet ?"
          icon="fa-solid fa-wifi text-xl"
          value={
            loading ? (
              <i className="fa-solid fa-arrows-rotate pi-spin" />
            ) : selectedSchool.internet_connection ? (
              <p>Sim</p>
            ) : (
              <p>Não</p>
            )
          }
          colorIcon={``}
          classNameCard={`border-2  ${
            !selectedSchool?.internet_connection
              ? ' opacity-70 border-slate-400'
              : 'border-secundario-200'
          }`}
          classNameBoxIcon={`bg-slate-100 border-2 ${
            !selectedSchool?.internet_connection
              ? ' opacity-70 text-slate-500 border-slate-400'
              : 'border-secundario-200 text-secundario-200'
          }`}
          classNameLabel={`${
            !selectedSchool?.internet_connection
              ? 'text-slate-400'
              : 'text-secundario-200'
          }`}
          classNameValue={`${
            !selectedSchool?.internet_connection
              ? ' opacity-70 text-slate-400'
              : 'text-secundario-200'
          }`}
          labelDescricao="Possuí Internet ?"
        />
        <CardDash
          label="Possui Quadra ?"
          icon="fa-solid fa-futbol text-xl"
          value={
            loading ? (
              <i className="fa-solid fa-arrows-rotate pi-spin" />
            ) : selectedSchool.sports_court ? (
              <p>Sim</p>
            ) : (
              <p>Não</p>
            )
          }
          colorIcon={``}
          classNameCard={`border-2  ${
            !selectedSchool?.sports_court
              ? ' opacity-70 border-slate-400'
              : 'border-yellow_black'
          }`}
          classNameBoxIcon={`bg-slate-100 border-2 ${
            !selectedSchool?.sports_court
              ? ' opacity-70 text-slate-500 border-slate-400'
              : 'border-yellow_black text-yellow_black'
          }`}
          classNameLabel={`${
            !selectedSchool?.sports_court
              ? 'text-slate-400'
              : 'text-yellow_black'
          }`}
          classNameValue={`${
            !selectedSchool?.sports_court
              ? ' opacity-70 text-slate-400'
              : 'text-yellow_black'
          }`}
          labelDescricao="Possuí Quadra ?"
        />
      </div>
    </>
  );
};

export default SchoolDashboard;
