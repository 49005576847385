/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { ThemeContext } from 'styled-components';

const CardDash = ({
  label,
  icon,
  classNameCard,
  colorIcon,
  value,
  classNameBoxIcon,
  classNameLabel,
  classNameValue,
  labelDescricao,
}) => {
  const { colors } = React.useContext(ThemeContext);

  return (
    <div
      className="grid card-info"
    >
      <div className="col-12 md:col-6 lg:col-3">
        <div style={{ backgroundColor: `${colors.container}` }} className={` shadow-2 p-3 border-round ${classNameCard}`}>
          <div className="flex justify-content-between mb-3">
            <div>
              <span
                className={`block font-medium mb-3 ${classNameLabel}`}
              >
                {label}
              </span>
              <div
                className={`font-medium text-xl ${classNameValue}`}
              >
                {value}
              </div>
            </div>
            <div
              className={`flex align-items-center justify-content-center border-round ${classNameBoxIcon}`}
              style={{ width: '2.5rem', height: '2.5rem' }}
            >
              <i className={`${icon}`} style={{ color: colorIcon }} />
            </div>
          </div>
          <span style={{ color: colors.text }} >{labelDescricao}</span>
        </div>
      </div>
    </div>
  );
};

export default CardDash;
