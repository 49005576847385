import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { ThemeContext } from 'styled-components';
import api from '../../services/api';
import { money_mask } from '../../utils/money_mask';

const EffortModal = ({
  modal,
  toggle,
  allotmentId,
  getAllotment,
  selectedEffort,
}: any) => {
  const [data, setData] = useState({
    allotment_id: allotmentId,
    value: '',
  });

  const { colors } = React.useContext(ThemeContext);

  async function createEffort() {
    toast
      .promise(api.post(`/effort`, data), {
        pending: 'Cadastrando Empenho',
        success: 'Empenho cadastrado',
        error: 'Ocorreu um erro ao cadastrar o empenho',
      })
      .then(() => getAllotment());
  }

  async function deleteEffort(id: number) {
    toast
      .promise(api.delete(`/effort/${id}`), {
        pending: 'Removendo Empenho',
        success: 'Empenho removido',
        error: 'Ocorreu um erro ao remover o empenho',
      })
      .then(() => getAllotment())
      .then(toggle());
  }

  useEffect(() => {
    if (selectedEffort) {
      setData({ ...data, value: selectedEffort?.value });
    } else {
      setData({ ...data, value: '' });
    }
  }, [selectedEffort]);
  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader
          toggle={toggle}
          style={{ backgroundColor: colors.container, color: colors.text }}
        >
          Cadastro de empenho
        </ModalHeader>
        <ModalBody
          style={{ backgroundColor: colors.container, color: colors.text }}
        >
          <Form>
            <Label>Valor</Label>
            <div
              style={{
                display: 'flex',
                gap: '10px',
              }}
            >
              <Input
                type="text"
                value={data?.value}
                onChange={(e) =>
                  setData({ ...data, value: money_mask(e.target.value) })
                }
              />
              {!selectedEffort ? (
                <Button color="primary" onClick={createEffort}>
                  Cadastrar
                </Button>
              ) : (
                <>
                  {/* <Button
                    color="warning"
                    onClick={createEffort}
                  >
                    Alterar
                  </Button> */}
                  <Button
                    color="danger"
                    onClick={() => {
                      deleteEffort(selectedEffort.id);
                    }}
                  >
                    Deletar
                  </Button>
                </>
              )}
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EffortModal;
