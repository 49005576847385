import React from 'react';
import Header from '../../components/Header';
import ProfilesTable from '../../components/ProfilesTable';
import { ProfilesProvider } from '../../context/profileContext';

const Schools = ({ showSidebar, setSidebar, toggleTheme }: any) => (
  <>
    <ProfilesProvider>
      <Header
        title="Perfis"
        icon="fa-solid fa-users-gear"
        showSidebar={showSidebar}
      setSidebar={setSidebar}
      toggleTheme={toggleTheme}
      />
      <ProfilesTable />
    </ProfilesProvider>
  </>
);

export default Schools;
