
import { Column } from 'primereact/column';
import { Ripple } from 'primereact/ripple';
import { classNames } from 'primereact/utils';
import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { Badge, Table } from 'reactstrap';
import { ThemeContext } from 'styled-components';
import { Eye } from '../../assets/svg/Eye';
import { DataTableStyle } from '../SchoolsTable/styles';
import { AutoComplete } from 'primereact/autocomplete';
import { priceFormatter } from '../../utils/currency_formatter';
import TimelineSchoolEditModal from '../TimelineSchoolEditModal';

const TimelineSchoolList = ({
  schoolAllotments, loading, timelineId, getSchoolAllotmentsInTimeline,
}: any) => {
  const [data, setData] = useState<any>();
  const [timelineSchoolModal, setTimelineSchoolModal] = useState(false);
  const [selectedTimelineSchool, setSelectedTimelineSchool] = useState<any>();


  const { colors } = React.useContext(ThemeContext);

  const colorStatus: any = {
    Aberto: {
      label: '#609AF8',
      background: '#CDDEFD',
    },
    'Em andamento': {
      label: '#F2D066',
      background: '#FAEDC4',
    },
    'Aguardando Retorno': {
      label: '#7C7C84',
      background: '#F1F5F9',
    },
    Finalizado: {
      label: '#006644',
      background: '#E3FCEF',
    },
  };

  const status = React.useCallback((rowData: any) => {
    return (
      <p
        className="px-4 rounded-full border-2"
        style={{
          backgroundColor: colorStatus[rowData?.status].background,
          borderColor: colorStatus[rowData?.status].label,
          color: colorStatus[rowData?.status].label,
        }}
      >
        {rowData?.status}
      </p>
    );
  }, []);

  const options = React.useCallback(
    (rowData: any) => (
      <>
        <div
          onClick={() => {
            setSelectedTimelineSchool(rowData)
            toggleTimelineSchoolModal()
          }}
        >

          <i className="fa-solid fa-eye icon-visualizar" />
        </div>
      </>
    ),
    [],
  );

  const pagination = {
    layout: 'PrevPageLink PageLinks NextPageLink',
    PrevPageLink: (options: any) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          style={{ borderRadius: '8px' }}
          disabled={options.disabled}
        >
          <span className="p-3">Anterior</span>
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options: any) => {
      return (
        <button
          type="button"
          className={options.className}
          style={{ borderRadius: '8px' }}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-3">Próximo</span>
          <Ripple />
        </button>
      );
    },
    PageLinks: (options: any) => {
      if (
        (options.view.startPage === options.page &&
          options.view.startPage !== 0) ||
        (options.view.endPage === options.page &&
          options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { 'p-disabled': true });

        return (
          <span
            className={className}
            style={{ userSelect: 'none', borderRadius: '3px' }}
          >
            ...
          </span>
        );
      }

      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          style={{ marginRight: '10px' }}
        >
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
  };


  const toggleTimelineSchoolModal = () => {
    setTimelineSchoolModal(!timelineSchoolModal);
  };

  useEffect(() => {
    setData(() => schoolAllotments?.filter((school: any) => (school?.timeline_id == Number(timelineId))));
  }, [schoolAllotments, timelineId]);

  return (
    <>
      <DataTableStyle
        value={schoolAllotments}
        size="small"
        dataKey="id"
        paginator
        loading={loading}
        scrollable
        filterLocale="BR"
        showGridlines
        scrollDirection="horizontal"
        scrollHeight="70vh"
        rows={10}
        rowsPerPageOptions={[5, 10, 25]}
        paginatorTemplate={pagination}
        style={{ backgroundColor: colors.background }}
        backgroundColor={colors.container}
        backgroundButtonPaginate={colors.btn_color}
        colorButtonPaginate={colors.text}
        backgroundButtonPaginateActive={colors.icon_visu}
        colorButtonPaginateActive={'#FFF'}
        rowStripedColor={colors.stripedRow}
        rowsColor={colors.row}
        colorIcon={colors.secundary}
      >
        <Column
          sortable
          field="escola.name"
          header="Escola"
          bodyStyle={{ color: colors.text }}
          headerStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          style={{ minWidth: '12rem', maxWidth: '12rem' }}
        />
        <Column
          sortable
          header="Município"
          field="escola.city"
          bodyStyle={{
            color: colors.text,
            display: 'flex',
            justifyContent: 'center',
          }}
          headerStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          style={{ minWidth: '10rem' }}
        />
        <Column
          sortable
          field="empresa.corporate_name"
          dataType="date"
          header="Empresa"
          bodyStyle={{ color: colors.text }}
          headerStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          style={{ minWidth: '12rem' }}
        />
        <Column
          sortable
          field="status"
          header="Status"
          bodyStyle={{
            color: colors.text,
            display: 'flex',
            justifyContent: 'center',
          }}
          headerStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          style={{ minWidth: '10rem' }}
          body={status}
        />
        <Column
          sortable
          field="date"
          dataType="date"
          header="Data de Início"
          bodyStyle={{ color: colors.text }}
          headerStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          style={{ minWidth: '12rem' }}
        />
        <Column
          sortable
          field="finish_date"
          dataType="date"
          header="Data Final"
          bodyStyle={{ color: colors.text }}
          headerStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          style={{ minWidth: '12rem' }}
        />
        <Column
          header="Opções"
          bodyStyle={{
            color: colors.text,
            display: 'flex',
            justifyContent: 'center',
          }}
          headerStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          style={{ minWidth: '5rem', maxWidth: '7rem' }}
          body={options}
        />
      </DataTableStyle>
      {/* <Table>
        <thead>
          <tr>
            <th>Escola</th>
            <th>Cidade</th>
            <th>Empresa</th>
            <th>Status</th>
            <th>Data de Início</th>
            <th>Data Final</th>
            <th>Valor</th>
            <th>Opções</th>
          </tr>
        </thead>
        <tbody>
          {
            loading ? (
              <div
                style={{
                  position: 'absolute',
                  left: '50%',
                  right: '50%',
                }}
              >
                <ReactLoading type="bars" color="#36B37E" />
              </div>
            ) : (
              data?.map((item: any) => (
                <tr key={item?.id}>
                  <td>{item?.escola?.name}</td>
                  <td>{item?.escola?.city}</td>
                  <td>{item?.empresa?.corporate_name}</td>
                  <td>
                    <Badge color={
                      (item?.status === 'Aberto' || item?.status === 'Em Aberto') ? 'primary'
                        : item?.status === 'Aguardando Retorno' ? 'secondary' : item?.status === 'Em Andamento' ? 'warning' : 'success'
                    }
                    >
                      {item?.status === 'Aberto' || item?.status === 'Em Aberto' ? 'Aberto' : item?.status}
                    </Badge>
                  </td>
                  <td>{item?.date}</td>
                  <td>{item?.finish_date}</td>
                  <td>
                    {item?.value}
                  </td>
                  <td>
                    <i
                      onClick={() => {
                        setSelectedTimelineSchool(item);
                        toggleTimelineSchoolModal();
                      }}
                      className="fa-solid fa-eye icon-visualizar"
                    />
                  </td>
                </tr>
              ))
            )

          }
        </tbody>
      </Table> */}
      <TimelineSchoolEditModal timelineSchoolModal={timelineSchoolModal} toggleTimelineSchoolModal={toggleTimelineSchoolModal} selectedTimelineSchool={selectedTimelineSchool} getSchoolAllotmentsInTimeline={getSchoolAllotmentsInTimeline} />

    </>
  );
};

export default TimelineSchoolList;
