import React from 'react';
import Header from '../../components/Header';
import LotsRegister from '../../components/AllotmentRegister';
import AllotmentList from '../../components/AllotmentList';

const Allotment = ({showSidebar, setSidebar, toggleTheme}: any) => {
  return (
  <>
    <Header
      title="Lotes"
      icon="fa-solid fa-cart-flatbed"
      showSidebar={showSidebar}
      setSidebar={setSidebar}
      toggleTheme={toggleTheme}
    />

    <AllotmentList />
  </>
)};

export default Allotment;
