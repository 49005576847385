import React, { useState } from 'react';
import FinancialStatementList from '../../components/FinancialStatementList';
import Header from '../../components/Header';

const FinancialStatement = ({showSidebar, setSidebar, toggleTheme}: any) => {
  const [data, setData] = useState();

  return (
    <>
      <Header
        backPage
        linkBack="/empresas"
        title="Levantamento Financeiro"
        showSidebar={showSidebar}
        setSidebar={setSidebar}
        toggleTheme={toggleTheme}
      />

      <FinancialStatementList />
    </>
  );
};

export default FinancialStatement;
