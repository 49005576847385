import React from 'react';
import Header from '../../components/Header';
import InstitutionTable from '../../components/InstitutionTable';
import { useAuth } from '../../hooks/auth';

const InstitutionPage = ({ showSidebar, setSidebar, toggleTheme }: any) => {
  const { user } = useAuth();

  const [allowedActions, setAllowedActions] = React.useState({
    acoes: {
      create: true,
      update: true,
      read: true,
      delete: true,
    },
  });

  React.useEffect(() => {
    if (user) {
      const temp = user?.profile?.functionalities
        ?.filter((func: any) => func.active && func.link == '/instituicoes')
        .map(({ acoes }: any) => ({ acoes }))[0];
      setAllowedActions(temp);
    }
  }, [user]);

  return (
    <>
      <Header
        title="Instituições"
        icon="fa-solid fa-building-columns"
        showSidebar={showSidebar}
        setSidebar={setSidebar}
        toggleTheme={toggleTheme}
      />
      <InstitutionTable allowedActions={allowedActions} />
    </>
  );
};

export default InstitutionPage;
