/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import {
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
} from 'reactstrap';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import api from '../../services/api';
import { ProfileContext } from '../../context/profileContext';

import { SidebarData } from '../Sidebar/Sidebar.data';
import { ThemeContext } from 'styled-components';

const ProfileModal = ({ modal, toggle, profile }: any) => {
  const { loadProfiles } = useContext(ProfileContext);

  const { colors } = React.useContext(ThemeContext);

  const [data, setData] = useState<any>({
    name: '',
    functionalities: SidebarData.map((item) => ({ ...item, active: false })),
  });

  function handleSubmit() {
    if (!profile) {
      toast
        .promise(
          api.post('/profile', {
            ...data,
            functionalities: JSON.stringify(data.functionalities),
          }),
          {
            pending: 'Cadastrando...',
            success: 'Perfil cadastrado com sucesso',
            error: 'Ocorreu um erro ao cadastrar o perfil',
          },
        )
        .then(() => {
          loadProfiles();
        });
    } else {
      toast
        .promise(
          api.patch(`/profile/${profile.id}`, {
            ...data,
            functionalities: JSON.stringify(data.functionalities),
          }),
          {
            pending: 'Alterando...',
            success: 'Perfil alterado com sucesso',
            error: 'Ocorreu um erro ao alterar o perfil',
          },
        )
        .then(() => {
          loadProfiles();
        });
    }
  }

  function deleteSchool() {
    if (profile) {
      toast
        .promise(api.delete(`/profile/${profile.id}`), {
          pending: 'Excluindo...',
          success: 'Perfil excluído com sucesso',
          error: 'Ocorreu um erro ao excluir o perfil',
        })
        .then(() => {
          toggle();
          loadProfiles();
        });
    }
  }

  useEffect(() => {
    if (profile == null) {
      setData({
        name: '',
        functionalities: SidebarData.map((item) => ({
          ...item,
          active: false,
        })),
      });
    } else {
      const aux = SidebarData.map((item: any) => String(item.link)).filter(
        (x) =>
          !profile.functionalities
            .map((profile: any) => String(profile.link))
            .includes(x),
      );
      const newFunctionalities: any = SidebarData.filter((item) =>
        aux.includes(item.link),
      );

      setData({
        ...profile,
        functionalities: [...profile.functionalities, ...newFunctionalities],
      });
    }
  }, [profile]);

  return (
    <>
      <Dialog
        headerStyle={{
          backgroundColor: colors.container,
          color: colors.text,
          minWidth: '340px',
          width: '30vw',
          maxWidth: '500px',
        }}
        contentStyle={{
          backgroundColor: colors.container,
          color: colors.text,
          minWidth: '340px',
          width: '30vw',
          maxWidth: '500px',
        }}
        header="Informações Do Perfil"
        visible={modal}
        onHide={toggle}
      >
        <ModalBody>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Row className="align-items-center">
              <FormGroup>
                <Label>Nome</Label>
                <Input
                  type="text"
                  value={data?.name}
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                  style={{backgroundColor: colors.container, color: colors.text}}
                />
              </FormGroup>
            </Row>

            <Row>
              {data.functionalities.map((item: any) => (
                <>
                  <div>
                    <FormGroup style={{ marginBottom: 10 }} switch>
                      <Input
                        type="switch"
                        checked={item.active}
                        onClick={() =>
                          setData({
                            ...data,
                            functionalities: data.functionalities.map(
                              (func: any) =>
                                func.title == item.title
                                  ? { ...func, active: !func.active }
                                  : func,
                            ),
                          })
                        }
                      />
                      <Label check>{item.title}</Label>
                    </FormGroup>

                    {item.active && (
                      <div>
                        <Input
                          style={{ marginBottom: 10, marginRight: 5 }}
                          type="checkbox"
                          checked={item.acoes.create}
                          onClick={() =>
                            setData({
                              ...data,
                              functionalities: data.functionalities.map(
                                (func: any) =>
                                  func.title == item.title
                                    ? {
                                        ...func,
                                        acoes: {
                                          ...func.acoes,
                                          create: !func.acoes.create,
                                        },
                                      }
                                    : func,
                              ),
                            })
                          }
                        />
                        <Label check>Criar</Label>

                        <Input
                          style={{
                            marginLeft: 10,
                            marginBottom: 10,
                            marginRight: 5,
                          }}
                          type="checkbox"
                          checked={item.acoes.update}
                          onClick={() =>
                            setData({
                              ...data,
                              functionalities: data.functionalities.map(
                                (func: any) =>
                                  func.title == item.title
                                    ? {
                                        ...func,
                                        acoes: {
                                          ...func.acoes,
                                          update: !func.acoes.update,
                                        },
                                      }
                                    : func,
                              ),
                            })
                          }
                        />
                        <Label check>Editar</Label>

                        <Input
                          style={{
                            marginLeft: 10,
                            marginBottom: 10,
                            marginRight: 5,
                          }}
                          type="checkbox"
                          checked={item.acoes.read}
                          onClick={() =>
                            setData({
                              ...data,
                              functionalities: data.functionalities.map(
                                (func: any) =>
                                  func.title == item.title
                                    ? {
                                        ...func,
                                        acoes: {
                                          ...func.acoes,
                                          read: !func.acoes.read,
                                        },
                                      }
                                    : func,
                              ),
                            })
                          }
                        />
                        <Label check>Listar</Label>

                        <Input
                          style={{
                            marginLeft: 10,
                            marginBottom: 10,
                            marginRight: 5,
                          }}
                          type="checkbox"
                          checked={item.acoes.delete}
                          onClick={() =>
                            setData({
                              ...data,
                              functionalities: data.functionalities.map(
                                (func: any) =>
                                  func.title == item.title
                                    ? {
                                        ...func,
                                        acoes: {
                                          ...func.acoes,
                                          delete: !func.acoes.delete,
                                        },
                                      }
                                    : func,
                              ),
                            })
                          }
                        />
                        <Label check>Deletar</Label>
                      </div>
                    )}
                  </div>
                </>
              ))}
            </Row>
            <div className="flex justify-between gap-2">
              {!profile ? (
                <Button
                  className="px-3 py-2 btn-cadastro border-0"
                  onClick={() => {
                    handleSubmit();
                    setData({
                      name: '',
                      functionalities: SidebarData.map((item) => ({
                        ...item,
                        active: false,
                      })),
                    });
                    toggle();
                  }}
                >
                  Cadastrar
                </Button>
              ) : (
                <>
                  <Button
                    className="px-3 py-2 btn-atualizar border-0"
                    color="warning"
                    onClick={handleSubmit}
                  >
                    Alterar
                  </Button>{' '}
                  <Button
                    className="px-3 py-2 btn-apagar border-0"
                    color="danger"
                    onClick={deleteSchool}
                  >
                    Excluir
                  </Button>
                </>
              )}
            </div>
          </Form>
        </ModalBody>
      </Dialog>
    </>
  );
};

export default ProfileModal;
