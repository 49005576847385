import React, {
  createContext, ReactNode, useEffect, useState,
} from 'react';
import api from '../services/api';
import { ITimeline } from '../types/ITimeline';

interface TimelineContextType {
  timelines: ITimeline[] | undefined;
  timeline: ITimeline | undefined;
  getTimeline: () => void;
  getTimelineById: (id: number) => void;
  setTimeline: (values: any) => void;
  setTimelines: (values: any) => void;
  setLoading: any;
  loading: boolean
}

interface IProps {
  children: ReactNode;
}

export const TimelineContext = createContext({} as TimelineContextType);

export const TimelineProvider: React.FC<IProps> = ({ children }: IProps) => {
  const [timelines, setTimelines] = useState<ITimeline[]>();
  const [timeline, setTimeline] = useState<ITimeline>();
  const [loading, setLoading] = useState(false);

  async function getTimeline() {
    try {
      const { data } = await api.get(`/timeline`);

      setTimelines(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function getTimelineById(id: number) {
    try {
      if (id) {
        setLoading(true);
        const { data } = await api.get(`/timeline/allotment/${id}`);
        setTimeline(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    setLoading(true);
    getTimeline();
  }, []);
  return (
    <TimelineContext.Provider value={{
      timelines, loading, getTimeline, setTimelines, getTimelineById, timeline, setTimeline, setLoading,
    }}
    >
      {children}
    </TimelineContext.Provider>
  );
};
