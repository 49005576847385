import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../components/Header';
import TimelineList from '../../components/TimelineList';
import api from '../../services/api';

const Timeline = ({showSidebar, setSidebar, toggleTheme}: any) => {
  const [data, setData] = useState();
  const [timeline, setTimeline] = useState();

  const { lote_id } = useParams<any>();

  return (
    <>
      <Header
        backHistoryPage
        title="Cronogramas"
        icon="fa-regular fa-calendar"
        showSidebar={showSidebar}
        setSidebar={setSidebar}
        toggleTheme={toggleTheme}
      />

      <TimelineList selectedAllotment={lote_id} />
    </>
  );
};

export default Timeline;
