import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import Header from '../../components/Header/index';
import ServiceCallTable from '../../components/ServiceCallTable';

import { useAuth } from '../../hooks/auth';

const ServiceCallPage = ({ showSidebar, setSidebar, toggleTheme }: any) => {
  const { user } = useAuth();

  const [allowedActions, setAllowedActions] = useState({
    acoes: {
      create: true,
      update: true,
      read: true,
      delete: true,
    },
  });

  useEffect(() => {
    if (user) {
      const temp = user?.profile?.functionalities
        ?.filter((func: any) => func.active && func.link == '/chamados')
        .map(({ acoes }: any) => ({ acoes }))[0];
      setAllowedActions(temp);
    }
  }, [user]);

  return (
    <>
      <Header
        title="Chamados"
        icon="fa-solid fa-headset"
        showSidebar={showSidebar}
        setSidebar={setSidebar}
        toggleTheme={toggleTheme}
      />
      <div className="mt-4">
        {allowedActions.acoes.create && (
          <div className="flex justify-content-end">
            <Link
              to="chamados/new_service_call"
              className="text-decoration-none"
            >
              <Button
                className="btn-cadastro py-2"
                icon="fa-solid fa-plus"
                label="Novo chamado"
              />
            </Link>
          </div>
        )}
        <ServiceCallTable allowedActions={allowedActions} />
      </div>
    </>
  );
};

export default ServiceCallPage;
