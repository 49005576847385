/* eslint-disable react/jsx-filename-extension */
import React from 'react';

const CardValues = ({
  label,
  icon,
  classNameCard,
  colorIcon,
  value,
  classNameBoxIcon,
  classNameLabel,
  classNameValue,
}: any) => (
  <div className="grid hover:-translate-y-1 duration-200">
    <div className="col-12 md:col-6 lg:col-3">
      <div
        className={`surface-0 shadow-2 py-2 pl-3 pr-2 border-round ${classNameCard}`}
      >
        <div className="flex justify-content-between mb-1">
          <div>
            <span className={`block font-medium mb-1 ${classNameLabel}`}>
              {label}
            </span>
            <div className={`font-medium text-xl ${classNameValue}`}>
              {value}
            </div>
          </div>
          <div
            className={`flex align-items-center justify-content-center border-round ${classNameBoxIcon}`}
            style={{ width: '2.5rem', height: '2.5rem' }}
          >
            <i className={`${icon}`} style={{ color: colorIcon }} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default CardValues;
