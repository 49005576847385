/* eslint-disable */
import { Dialog } from 'primereact/dialog';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Form,
  Input,
  Label,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
} from 'reactstrap';
import { ThemeContext } from 'styled-components';
import { UserContext } from '../../context/userContext';
import api from '../../services/api';
import { IAllotmentType } from '../../types/IAllotmentType';
import { IEffort } from '../../types/IEffort';
import { cities } from '../../utils/city.data';
import { priceFormatter } from '../../utils/currency_formatter';
import { money_mask } from '../../utils/money_mask';
import AllotmentCityList from '../AllotmentCityList';

interface IAllotment {
  name: string;
  responsavel_id: number | undefined;
  valor: string;
  allotment_type_id: number | undefined;
  allotment_type: IAllotmentType | null;
  effort: IEffort | null;
}

// const INITITAL_STATE = {
//   name: "",
//   responsavel_id: undefined,
//   valor: "",
//   allotment_type_id: undefined,
// };

const AllotmentRegister = ({
  modal,
  toggle,
  loadAllotments,
  selectedAllotment,
  setSelectedAllotment,
}: any) => {
  const [data, setData] = useState<IAllotment | any>();
  const [allotmentCity, setAllotmentCity] = useState({});
  const [loading, setLoading] = useState(false);
  const [allotmentCities, setAllotmentCities] = useState([]);
  const [allotmentTypes, setAllotmentTypes] = useState([]);

  const { users } = useContext(UserContext);

  const { colors } = React.useContext(ThemeContext);

  function createAllotment() {
    // const allotmentValue = data.valor.replace('R$ ', "").replaceAll(".", "").replace(",", ".");
    toast
      .promise(api.post(`/allotment`, data), {
        pending: 'Cadastrando Lote....',
        error: 'Ocorreu um erro ao cadastrar o lote',
        success: 'Lote cadastrado com sucesso',
      })
      .then(() => {
        loadAllotments();
      })
      .finally(toggle());
  }

  function deleteAllotment(id: any) {
    toast
      .promise(api.delete(`/allotment/${id}`), {
        pending: 'Deletando Lote....',
        error: 'Ocorreu um erro ao deletar o lote',
        success: 'Lote deletado com sucesso',
      })
      .then(() => {
        loadAllotments();
      })
      .finally(toggle());
  }

  function addCity() {
    toast
      .promise(api.post('/allotment-city', allotmentCity), {
        pending: 'Adicionando Cidade ...',
        error: 'Ocorreu um erro ao adicionar cidade ao lote',
        success: 'Cidade cadastrada',
      })
      .then(() => {
        loadAllotmentCities();
        setAllotmentCity({ ...allotmentCity, name: '' });
      });
  }

  async function loadAllotmentCities() {
    try {
      if (selectedAllotment) {
        setLoading(true);
        const response = await api.get(
          `/allotment-city/${selectedAllotment.id}`,
        );
        setAllotmentCities(response.data);
      } else {
        setAllotmentCities([]);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }

  async function loadAllotmentTypes() {
    try {
      setLoading(true);
      const { data } = await api.get(`/allotment-type`);
      setAllotmentTypes(data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }

  function updateAllotment(id: any) {
    // const allotmentValue = data?.valor?.replace('R$ ', "").replaceAll(".", "").replace(",", ".");
    toast
      .promise(api.patch(`/allotment/${id}`, data), {
        pending: 'Salvando Lote....',
        error: 'Ocorreu um erro ao cadastrar o lote',
        success: 'Lote salvo com sucesso',
      })
      .then(() => {
        loadAllotments();
      })
      .finally(toggle());
  }

  useEffect(() => {
    loadAllotmentCities();

    loadAllotmentTypes();
    if (selectedAllotment) {
      setData({
        ...data,
        name: selectedAllotment.name,
        responsavel_id: selectedAllotment.responsavel_id,
        valor: selectedAllotment.valor,
        allotment_type_id: selectedAllotment?.allotment_type?.id,
        allotment_type: selectedAllotment?.allotment_type,
        effort: selectedAllotment?.effort,
      });
      setAllotmentCity({
        ...allotmentCity,
        allotment_id: selectedAllotment.id,
      });
    } else {
      setData({
        name: ' ',
        responsavel_id: 0,
        valor: ' ',
        allotment_type_id: null,
        effort: null,
        allotment_type: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users, selectedAllotment?.id, selectedAllotment]);
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Dialog
          className="min-w-[340px] max-w-[800px]"
          header="Cadastro de Lote"
          visible={modal}
          onHide={toggle}
          contentStyle={{
            backgroundColor: colors.container,
            color: colors.text,
          }}
          headerStyle={{
            backgroundColor: colors.container,
            color: colors.text,
          }}
        >
          <ModalBody className="px-4">
            <Row>
              <Col>
                <Form
                  className="grid grid-cols-2 gap-3:"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div>
                    <Label>Nome do Lote</Label>
                    <Input
                      type="text"
                      value={data?.name}
                      onChange={(e) =>
                        setData({ ...data, name: e.target.value })
                      }
                      style={{
                        backgroundColor: colors.container,
                        color: colors.text,
                      }}
                    />
                  </div>
                  {selectedAllotment ? (
                    <div>
                      <Label>Tipo do Lote</Label>
                      <Input
                        type="select"
                        value={data?.allotment_type_id}
                        onChange={(e) =>
                          setData({
                            ...data,
                            allotment_type_id: Number(e.target.value),
                          })
                        }
                        style={{
                          backgroundColor: colors.container,
                          color: colors.text,
                        }}
                      >
                        <option> </option>
                        {allotmentTypes.map((item: any) => (
                          <option value={item?.id}>{item?.type}</option>
                        ))}
                      </Input>
                    </div>
                  ) : null}
                  <div>
                    <Label>Responsável</Label>
                    <Input
                      type="select"
                      value={data?.responsavel_id}
                      onChange={(e) =>
                        setData({
                          ...data,
                          responsavel_id: Number(e.target.value),
                        })
                      }
                      style={{
                        backgroundColor: colors.container,
                        color: colors.text,
                      }}
                    >
                      <option> </option>
                      {users.map((user) => (
                        <option value={user.id}>{`${user.full_name}`}</option>
                      ))}
                    </Input>
                  </div>
                  <div>
                    <Label>Valor Inicial</Label>
                    <Input
                      type="text"
                      value={data?.valor}
                      onChange={(e) =>
                        setData({
                          ...data,
                          valor: money_mask(e.target.value),
                        })
                      }
                      style={{
                        backgroundColor: colors.container,
                        color: colors.text,
                      }}
                    />
                  </div>
                  {selectedAllotment ? (
                    <>
                      <div className="col-span-2 flex gap-4">
                        <div className="w-full">
                          <Label>Município</Label>
                          <Input
                            type="select"
                            onChange={(e) =>
                              setAllotmentCity({
                                ...allotmentCity,
                                name: e.target.value,
                              })
                            }
                            style={{
                              backgroundColor: colors.container,
                              color: colors.text,
                            }}
                          >
                            <option> </option>
                            {cities.map((city) => (
                              <option value={city.name}>{city.name}</option>
                            ))}
                          </Input>
                        </div>
                        <Button
                          className="px-3 py-2 text-sm btn-adicionar border-0"
                          size="sm"
                          style={{
                            marginTop: '35px',
                          }}
                          onClick={addCity}
                        >
                          Adicionar
                        </Button>
                      </div>
                    </>
                  ) : null}
                  <div className="col-span-2">
                    <AllotmentCityList
                      loading={loading}
                      setAllotmentCities={setAllotmentCities}
                      allotmentCities={allotmentCities}
                      loadAllotmentCities={loadAllotmentCities}
                    />
                  </div>
                  <div className="col-span-2">
                    {!selectedAllotment ? (
                      <Button
                        style={{
                          marginTop: '10px',
                          marginBottom: '20px',
                        }}
                        className="px-3 py-2 btn-cadastro border-0"
                        onClick={createAllotment}
                      >
                        Salvar
                      </Button>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          gap: '20px',
                        }}
                        className="justify-between"
                      >
                        <Button
                          style={{
                            marginTop: '10px',
                            marginBottom: '20px',
                          }}
                          className="px-3 py-2 btn-atualizar border-0"
                          onClick={() => {
                            updateAllotment(selectedAllotment.id);
                          }}
                        >
                          Alterar
                        </Button>
                        <Button
                          style={{
                            marginTop: '10px',
                            marginBottom: '20px',
                          }}
                          className="px-3 py-2 btn-apagar border-0"
                          onClick={(e) => {
                            e.preventDefault();
                            if (
                              window.confirm(
                                `Confirme a exclusão do lote ${selectedAllotment.id}`,
                              )
                            )
                              deleteAllotment(selectedAllotment.id);
                          }}
                        >
                          Deletar
                        </Button>
                      </div>
                    )}
                  </div>
                </Form>
              </Col>
            </Row>
          </ModalBody>
        </Dialog>
      </div>
    </>
  );
};

export default AllotmentRegister;
