/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { Button } from 'primereact/button';
import ReactLoading from 'react-loading';
import api from '../../services/api';
import ResourcesModal from '../ResourcesModal';
import { IResource } from '../../types/IResource';
import { Eye } from '../../assets/svg/Eye';
import { Tooltip } from 'primereact/tooltip';
import { ThemeContext } from 'styled-components';

const ResourcesList = () => {
  const [data, setData] = useState<IResource[]>([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedResource, setSelectedResource] = useState<IResource | null>();

  const {colors} = React.useContext(ThemeContext)

  const toggle = () => {
    setModal(!modal);
  };

  async function getResources() {
    try {
      setLoading(true);
      const { data } = await api.get('/resource');
      setData(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getResources();
  }, []);
  return (
    <>
      <div className='flex justify-end mt-3'>
        <Button
          className="px-3 py-2 btn-cadastro border-0"
          onClick={() => {
            setSelectedResource(null);
            toggle();
          }}
          icon="fa-solid fa-plus"
          label='Cadastrar Recurso'
        />
      </div>
      <Table style={{color: colors.text}}>
        <thead>
          <tr>
            <th>Nome do recurso</th>
            <th>valor</th>
            <th>Opções</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <div
              style={{
                position: 'absolute',
                left: '50%',
                right: '50%',
              }}
            >
              <ReactLoading type="bars" color="#36B37E" />
            </div>
          ) : (
            data.map((resource) => (
              <tr key={resource.id}>
                <td>{resource.name}</td>
                <td>{resource.available_value}</td>
                <td>
                  <div
                    onClick={() => {
                      setSelectedResource(resource);
                      toggle();
                    }}
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <Tooltip
                      position="top"
                      content="Visualizar"
                      target=".icon-visualizar"
                    />
                    <i className="fa-solid fa-eye icon-visualizar"></i>
                  </div>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      <ResourcesModal
        modal={modal}
        toggle={toggle}
        getResources={getResources}
        selectedResource={selectedResource}
      />
    </>
  );
};

export default ResourcesList;
