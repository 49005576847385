// @ts-nocheck
/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, CardBody, CardText, Table } from 'reactstrap';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import { HiCurrencyDollar } from 'react-icons/hi';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import api from '../../services/api';
import AllotmentSchoolRegister from '../AllotmentSchoolRegister';
import Header from '../Header';
import { ISchoolTypes } from '../../types/ISchoolTypes';
import AllotmentValueModal from '../AllotmentValueModal';
import { price_to_number } from '../../utils/price_to_number';
import { priceFormatter } from '../../utils/currency_formatter';
import CardValues from './CardValues';
import './AllotmentInfo.css';
import { DataTableStyle } from '../SchoolsTable/styles';
import { Column } from 'primereact/column';
import { classNames } from 'primereact/utils';
import { Ripple } from 'primereact/ripple';
import { ThemeContext } from 'styled-components';
import { InputTextStyle } from '../ProfilesTable/styles';

interface ISchoolAllotment {
  id: number;
  empresa: {
    corporate_name: string;
  };
  escola: {
    city: string;
    name: string;
    school_type: ISchoolTypes;
  };
}

const AllotmentInfo = () => {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [schoolAllotment, setSchoolAllotment] = useState<ISchoolAllotment[]>(
    [],
  );
  const [effortModal, setEffortModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleEffortModal = () => {
    setEffortModal(!effortModal);
  };
  const { id } = useParams<any>();

  const { colors } = React.useContext(ThemeContext);

  async function getSchoolAllotment() {
    try {
      setLoading(true);
      const { data } = await api.get(`/allotment-school/${id}`);
      setSchoolAllotment(data);
    } catch (error) {
      /* toast('Não foi possível carregar o chamado', {
          position: 'bottom-center',
        }); */
    } finally {
      setLoading(false);
    }
  }

  async function deleteSchoolAllotment(id: number) {
    if (schoolAllotment) {
      toast
        .promise(api.delete(`/allotment-school/${id}`), {
          pending: 'Removendo Escola',
          success: 'Escola removida com sucesso',
          error: 'Ocorreu um erro ao remover a escola',
        })
        .then(() => {
          getSchoolAllotment();
        });
    }
  }

  async function getAllotment() {
    try {
      setLoading(true);
      const { data } = await api.get(`/allotment/${id}`);
      setData(data);
    } catch (error) {
      /* toast('Não foi possível carregar o chamado', {
          position: 'bottom-center',
        }); */
    } finally {
      setLoading(false);
    }
  }

  const options = React.useCallback(
    (rowData: any) => (
      <>
        <Tooltip content="Apagar" position="top" target=".fa-trash" />
        <i
          onClick={() => {
            deleteSchoolAllotment(rowData.id);
          }}
          className="fa-solid fa-trash text-red-500 hover:text-white hover:bg-red-500 px-2 py-1 rounded-full cursor-pointer text-lg duration-150"
        ></i>
      </>
    ),
    [],
  );

  const pagination = {
    layout: 'PrevPageLink PageLinks NextPageLink',
    PrevPageLink: (options: any) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          style={{ borderRadius: '8px' }}
          disabled={options.disabled}
        >
          <span className="p-3">Anterior</span>
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options: any) => {
      return (
        <button
          type="button"
          className={options.className}
          style={{ borderRadius: '8px' }}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-3">Próximo</span>
          <Ripple />
        </button>
      );
    },
    PageLinks: (options: any) => {
      if (
        (options.view.startPage === options.page &&
          options.view.startPage !== 0) ||
        (options.view.endPage === options.page &&
          options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { 'p-disabled': true });

        return (
          <span
            className={className}
            style={{ userSelect: 'none', borderRadius: '3px' }}
          >
            ...
          </span>
        );
      }

      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          style={{ marginRight: '10px' }}
        >
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
  };

  useEffect(() => {
    getAllotment();
    getSchoolAllotment();
  }, [id]);

  const optionsFilter: any = {
    'escola.city': {
      placeholder: 'Municipio',
    },
    'escola.school_type.name': {
      placeholder: 'Tipo',
    },
    'escola.name': {
      placeholder: 'Escola',
    },
    'empresa.corporate_name': {
      placeholder: 'Empresa',
    },
  };

  const filterTemplate = (options: any) => {
    return (
      <span className="p-input-icon-right">
        <i className="pi pi-search" />
        <InputTextStyle
          color={colors.text}
          placeholder={optionsFilter[options?.field].placeholder}
          onChange={(e) => options.filterApplyCallback(e.target.value)}
          style={{
            backgroundColor: colors.background,
            color: colors.text,
            border: 'none',
          }}
        />
      </span>
    );
  };

  const HeaderInFilter = ({ label }: any) => (
    <p className="text-center" style={{ fontSize: '1rem', color: colors.text }}>
      {label}
    </p>
  );

  return (
    <>
      <Header backPage linkBack="/lotes" title={`${data?.name}`} />
      <div className="mt-2 flex justify-content-end gap-3">
        <Button
          className="px-3 py-2 text-sm bg-blue-400 hover:bg-blue-300 font-bold border-0 justify-self-end"
          onClick={() => setEffortModal(true)}
        >
          Empenhos
        </Button>
        <Button
          className="px-3 py-2 text-sm btn-cadastro font-bold border-0 justify-self-end"
          onClick={toggle}
          icon="fa-solid fa-plus"
          label="Adicionar Escola"
        />
      </div>
      <div className="container-allotmentinfo">
        {/* <Tooltip className="tooltip-card" target=".info-card">
          <>
            <Card
              inverse
              className="my-2 hover bg-green-500 border-0"
              style={{
                height: '7rem',
                width: '18rem',
                cursor: 'pointer',
              }}
            >
              <CardBody
                className="text-left"
                style={{
                  marginTop: '8px',
                }}
                onClick={toggleEffortModal}
              >
                <CardText>
                  <strong>Valor Inicial:</strong>
                  {data?.valor}
                </CardText>
                <CardText>
                  <strong>Empenhos:</strong>{' '}
                  {priceFormatter.format(
                    price_to_number(data?.total_value) -
                      price_to_number(data?.valor),
                  )}
                </CardText>
                <CardText>
                  <strong>Valor Disponível:</strong>
                  {data?.available_value}
                </CardText>
              </CardBody>
            </Card>
          </>
        </Tooltip> */}
        <div className="grid grid-cols-3 max-[1100px]:grid-rows-3 max-[1100px]:grid-cols-1 gap-2 px-2 mt-3">
          <CardValues
            label="Valor Inicial"
            icon="fa-solid fa-money-bills text-xl"
            value={
              loading ? (
                <i className="fa-solid fa-arrows-rotate pi-spin" />
              ) : (
                data?.valor
              )
            }
            colorIcon="#4CD07D"
            classNameCard="border-2 border-green-400 h-20"
            classNameBoxIcon="bg-green-100 border-2 border-green-400"
            classNameLabel="text-green-400"
            classNameValue="text-green-500"
            labelDescricao="Chamados em aberto"
          />
          <CardValues
            label="Empenhos"
            icon="fa-solid fa-stamp text-xl"
            value={
              loading ? (
                <i className="fa-solid fa-arrows-rotate pi-spin" />
              ) : (
                data?.total_value
              )
            }
            colorIcon="#4CD07D"
            classNameCard="border-2 border-green-400 h-20"
            classNameBoxIcon="bg-green-100 border-2 border-green-400"
            classNameLabel="text-green-400"
            classNameValue="text-green-500"
            labelDescricao="Chamados em aberto"
          />
          <CardValues
            label="Valor Disponível"
            icon="fa-solid fa-wallet text-xl"
            value={
              loading ? (
                <i className="fa-solid fa-arrows-rotate pi-spin" />
              ) : (
                data?.available_value
              )
            }
            colorIcon="#4CD07D"
            classNameCard="border-2 border-green-400 h-20"
            classNameBoxIcon="bg-green-100 border-2 border-green-400"
            classNameLabel="text-green-400"
            classNameValue="text-green-500"
            labelDescricao="Chamados em aberto"
          />
        </div>
        {/* <span className="px-4 max-[1100px]:w-full py-1 rounded font-bold bg-blue-500 ">
          Valor Inicial: <span>{data?.valor}</span>
        </span> */}
        {/* <span className="px-4 max-[1100px]:w-full py-1 rounded font-bold bg-blue-500">
          Empenhos: <span>{data?.total_value}</span>
        </span>
        <span className="px-4 max-[1100px]:w-full py-1 rounded font-bold bg-blue-500">
          Valor Disponível: <span>{data?.available_value}</span>
        </span> */}
        {/* <i className="fa-solid fa-circle-info info-card text-2xl ml-2 text-green-500" />
        <span className="text-gray-700" style={{ paddingTop: '.2rem' }}>
          Informações do lote
        </span> */}

        <DataTableStyle
          value={schoolAllotment}
          size="small"
          dataKey="id"
          tableClassName="header-filter"
          loading={loading}
          scrollable
          filterLocale="BR"
          paginator
          showGridlines
          scrollDirection="horizontal"
          scrollHeight="40vh"
          rows={10}
          filterDisplay="row"
          rowsPerPageOptions={[5, 10, 25]}
          paginatorTemplate={pagination}
          style={{ backgroundColor: colors.background }}
          backgroundColor={colors.container}
          backgroundButtonPaginate={colors.btn_color}
          colorButtonPaginate={colors.text}
          backgroundButtonPaginateActive={colors.icon_visu}
          colorButtonPaginateActive={'#FFF'}
          rowStripedColor={colors.stripedRow}
          rowsColor={colors.row}
          colorIcon={colors.secundary}
        >
          <Column
            sortable
            filter
            filterMatchMode="contains"
            showFilterMatchModes={false}
            showFilterMenuOptions={false}
            filterPlaceholder="Município"
            bodyStyle={{
              color: colors.text,
            }}
            headerStyle={{
              backgroundColor: colors.background,
              color: colors.text,
            }}
            filterHeaderStyle={{
              backgroundColor: colors.background,
              color: colors.text,
            }}
            filterElement={filterTemplate}
            field="escola.city"
            // body={school}
            header="Município"
            showClearButton={false}
            showFilterMenu={false}
            style={{ minWidth: '12rem' }}
          />
          <Column
            sortable
            filter
            filterMatchMode="contains"
            showFilterMatchModes={false}
            showFilterMenuOptions={false}
            filterPlaceholder="Tipo"
            bodyStyle={{
              color: colors.text,
            }}
            headerStyle={{
              backgroundColor: colors.background,
              color: colors.text,
            }}
            filterHeaderStyle={{
              backgroundColor: colors.background,
              color: colors.text,
            }}
            field="escola.school_type.name"
            // body={school}
            header="Tipo"
            showClearButton={false}
            showFilterMenu={false}
            filterElement={filterTemplate}
            style={{ minWidth: '12rem' }}
          />
          <Column
            sortable
            filter
            filterMatchMode="contains"
            showFilterMatchModes={false}
            showFilterMenuOptions={false}
            filterPlaceholder="Escola"
            bodyStyle={{
              color: colors.text,
            }}
            headerStyle={{
              backgroundColor: colors.background,
              color: colors.text,
            }}
            filterHeaderStyle={{
              backgroundColor: colors.background,
              color: colors.text,
            }}
            field="escola.name"
            // body={school}
            header="Escola"
            showClearButton={false}
            showFilterMenu={false}
            filterElement={filterTemplate}
            style={{ minWidth: '12rem' }}
          />
          <Column
            sortable
            filter
            filterMatchMode="contains"
            showFilterMatchModes={false}
            showFilterMenuOptions={false}
            filterPlaceholder="Empresa"
            bodyStyle={{
              color: colors.text,
            }}
            headerStyle={{
              backgroundColor: colors.background,
              color: colors.text,
            }}
            filterHeaderStyle={{
              backgroundColor: colors.background,
              color: colors.text,
            }}
            field="empresa.corporate_name"
            // body={school}
            header="Empresa"
            showClearButton={false}
            showFilterMenu={false}
            filterElement={filterTemplate}
            style={{ minWidth: '12rem' }}
          />
          <Column
            bodyClassName="flex justify-content-center"
            bodyStyle={{
              color: colors.text,
            }}
            headerStyle={{
              backgroundColor: colors.background,
              color: colors.text,
            }}
            filterHeaderStyle={{
              backgroundColor: colors.background,
              color: colors.text,
            }}
            body={options}
            filter
            filterElement={<HeaderInFilter label="Opções" />}
            header="Opções"
            showClearButton={false}
            showFilterMenu={false}
            style={{ minWidth: '5rem', maxWidth: '7rem' }}
          />
        </DataTableStyle>
      </div>
      <AllotmentSchoolRegister
        toggle={toggle}
        modal={modal}
        loteId={id}
        getSchoolAllotment={getSchoolAllotment}
        allotmentCities={data?.city}
      />
      <AllotmentValueModal
        allotment={data}
        getAllotment={getAllotment}
        toggle={toggleEffortModal}
        modal={effortModal}
        loteId={id}
      />
    </>
  );
};

export default AllotmentInfo;
