/* eslint-disable prefer-const */
import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from '../services/api';
import { ISchool } from '../types/ISchool';

import { useAuth } from '../hooks/auth';

interface SchoolContextType {
  schools: ISchool[];
  getSchools: () => void;
  setSchools: (values: any) => void;
  loading: boolean;
}

interface IProps {
  children: ReactNode;
}

export const SchoolContext = createContext({} as SchoolContextType);

export const SchoolsProvider: React.FC<IProps> = ({ children }: IProps) => {
  let [schools, setSchools] = useState<ISchool[]>([]);
  const [loading, setLoading] = useState(false);

  const { signed } = useAuth();

  async function getSchools() {
    try {
      if (signed) {
        const response = await api.get('/schools');
        setSchools(() => response.data);
      }
    } catch (err) {
      /* toast('Não foi possível carregar as escolas', {
        position: 'bottom-center',
      }); */
    } finally {
      setLoading(false);
    }
  }
  // for (let i = 0; i < schools.length; i++) {
  //   if (schools[i].name.includes('EME')) {
  //     schools.splice(i, 1);
  //   }
  // }
  schools = schools.filter((school) => !school.name.split(" ")[0].includes("EME"))

  useEffect(() => {
    setLoading(true);
    getSchools();
  }, []);

  return (
    <SchoolContext.Provider
      value={{
        schools,
        loading,
        getSchools,
        setSchools,
      }}
    >
      {children}
    </SchoolContext.Provider>
  );
};
