/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import ReactLoading from 'react-loading';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import api from '../../services/api';
import { ICompany } from '../../types/ICompany';
import { ISchoolTypes } from '../../types/ISchoolTypes';
import { cities } from '../../utils/city.data';
import { ThemeContext } from 'styled-components';

const AllotmentSchoolRegister = ({
  toggle,
  modal,
  loteId,
  getSchoolAllotment,
  allotmentCities,
}: any) => {
  const [data, setData] = useState({
    empresa_id: 0,
    escola_id: 0,
    lote_id: Number(loteId),
  });
  const [school, setSchool] = useState([]);
  const [city, setCity] = useState('');
  const [companies, setCompanies] = useState<ICompany[]>();
  const [schoolTypes, setSchoolTypes] = useState<ISchoolTypes[]>();
  const [schoolTypeId, setSchoolTypeId] = useState(0);
  const [loading, setLoading] = useState(false);

  const { colors } = React.useContext(ThemeContext);

  async function getCompanies() {
    try {
      const { data } = await api.get('/company');
      setCompanies(data);
    } catch (error) {
      console.error(error);
    }
  }

  async function getSchools(city: string, type: number) {
    try {
      setLoading(true);
      const { data } = await api.get(`schools/available`, {
        params: {
          city,
          type_id: type,
        },
      });
      setSchool(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  function createSchoolAllotment() {
    toast
      .promise(api.post('/allotment-school', data), {
        pending: `Adicionando escola ao lote ${loteId}`,
        success: 'Escola adicionada',
        // error: 'Ocorreu um erro ao adicionar escola',
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          type: "error",
        })
      })
      .then(toggle())
      .then(() => {
        getSchoolAllotment();
      });
  }

  async function getSchoolTypes() {
    try {
      const { data } = await api.get('/school-type');
      setSchoolTypes(data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getCompanies();
    getSchoolTypes();
  }, []);
  return (
    <>
      <Dialog
        header="Adicionar Escola ao lote"
        onHide={toggle}
        visible={modal}
        contentStyle={{
          backgroundColor: colors.container,
          color: colors.text,
          minWidth: '340px',
        }}
        headerStyle={{
          backgroundColor: colors.container,
          color: colors.text,
          minWidth: '340px',
        }}
      >
        <ModalBody>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Row>
              <Col>
                <Label>Município</Label>
                <Input
                  type="select"
                  onChange={(e) => {
                    setCity(e.target.value);
                    getSchools(e.target.value, schoolTypeId);
                  }}
                >
                  <option> </option>
                  {allotmentCities?.map((city: any) => (
                    <option key={city.name} value={city.name}>
                      {city.name}
                    </option>
                  ))}
                </Input>
              </Col>
              <Col>
                <Label>Tipo Escola</Label>
                <Input
                  type="select"
                  onChange={(e) => {
                    // eslint-disable-next-line no-unused-expressions
                    setSchoolTypeId(Number(e.target.value));
                    getSchools(city, Number(e.target.value));
                  }}
                >
                  <option> </option>
                  {schoolTypes?.map((schoolType) => (
                    <option value={schoolType.id}>{schoolType.name}</option>
                  ))}
                </Input>
              </Col>
            </Row>
            <Row>
              <Col>
                <Label>Escola</Label>

                <Input
                  type="select"
                  onChange={(e) =>
                    setData({ ...data, escola_id: Number(e.target.value) })
                  }
                >
                  <option value=""> </option>
                  {loading ? (
                    <option>
                      <ReactLoading type="bars" color="#36B37E" />
                    </option>
                  ) : (
                    school?.map((school: any) => (
                      <option
                        style={{
                          overflow: 'visible',
                        }}
                        value={school.id}
                      >
                        {' '}
                        {school.name}{' '}
                      </option>
                    ))
                  )}
                </Input>
              </Col>
              <Col>
                <Label>Empresa responsável</Label>
                <Input
                  type="select"
                  onChange={(e) =>
                    setData({ ...data, empresa_id: Number(e.target.value) })
                  }
                >
                  <option> </option>
                  {companies?.map((company) => (
                    <option value={company.id}>
                      {company.corporate_name} - {company.cnpj}
                    </option>
                  ))}
                </Input>
              </Col>
            </Row>
            <div
              style={{
                marginTop: '20px',
              }}
            >
              <Button
                className="px-3 py-2 text-sm bg-green-400 border-0"
                onClick={createSchoolAllotment}
              >
                Adicionar
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Dialog>
    </>
  );
};

export default AllotmentSchoolRegister;
