// @ts-nocheck
/* eslint-disable */
import { Column } from 'primereact/column';
import { Ripple } from 'primereact/ripple';
import { classNames } from 'primereact/utils';
import React, { useContext, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { Link } from 'react-router-dom';
import { Badge, Table, Button } from 'reactstrap';
import { ThemeContext } from 'styled-components';
import { Eye } from '../../assets/svg/Eye';
import { TimelineContext } from '../../context/timelineContext';
import { ITimeline } from '../../types/ITimeline';
import { DataTableStyle } from '../SchoolsTable/styles';
import TimelineModal from '../TimelineModal';

const TimelineList = ({ selectedAllotment }: any) => {
  const [timelineModal, setTimelineModal] = useState(false);
  const [selectedTimeline, setSelectedTimeline] = useState<ITimeline | null>();

  const { loading, timeline, getTimelineById } = useContext(TimelineContext);

  const { colors } = React.useContext(ThemeContext);

  async function removeSchoolAllotment(selectedSchoolAllotment: any) {
    toast
      .promise(
        api.patch(`/lotes/school/${selectedSchoolAllotment}`, {
          timeline_id: null,
        }),
        {
          pending: 'Removendo escola do cronograma',
          error: 'Ocorreu um erro ao remover a escola ao cronograma',
          success: 'Escola Removida',
        },
      )
      .then(() => {
        getTimelineById();
      });
  }

  const toggleTimelineModal = () => {
    setTimelineModal(!timelineModal);
  };

  const colorStatus = {
    Aberto: {
      label: '#609AF8',
      background: '#CDDEFD',
    },
    'Em Aberto': {
      label: '#609AF8',
      background: '#CDDEFD',
    },
    'Em andamento': {
      label: '#F2D066',
      background: '#FAEDC4',
    },
    'Aguardando Retorno': {
      label: '#7C7C84',
      background: '#F1F5F9',
    },
    Finalizado: {
      label: '#006644',
      background: '#E3FCEF',
    },
    "null":  {
      label: '#006644',
      background: '#E3FCEF',
    },
  };

  const status = React.useCallback((rowData: any) => {
    return (
      <p
        className="px-4 rounded-full bord er-2"
        style={{
          backgroundColor: colorStatus[rowData?.status].background,
          borderColor: colorStatus[rowData?.status].label,
          color: colorStatus[rowData?.status].label,
        }}
      >
        {rowData?.status}
      </p>
    );
  }, []);

  const options = React.useCallback((rowData: any) => {
    return (
      <>
        <Link
          to={`/lotes/lote/cronogramas/cronograma/${rowData?.id}`}
          state={rowData?.id}
          style={{
            cursor: 'pointer',
          }}
        >
          <i className="fa-solid fa-eye icon-visualizar" />
        </Link>
        <i
          onClick={() => {
            setSelectedTimeline(rowData);
            toggleTimelineModal();
          }}
          className="fa-solid fa-pen icon-editar"
        />
      </>
    );
  }, []);

  const pagination = {
    layout: 'PrevPageLink PageLinks NextPageLink',
    PrevPageLink: (options: any) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          style={{ borderRadius: '8px' }}
          disabled={options.disabled}
        >
          <span className="p-3">Anterior</span>
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options: any) => {
      return (
        <button
          type="button"
          className={options.className}
          style={{ borderRadius: '8px' }}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-3">Próximo</span>
          <Ripple />
        </button>
      );
    },
    PageLinks: (options: any) => {
      if (
        (options.view.startPage === options.page &&
          options.view.startPage !== 0) ||
        (options.view.endPage === options.page &&
          options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { 'p-disabled': true });

        return (
          <span
            className={className}
            style={{ userSelect: 'none', borderRadius: '3px' }}
          >
            ...
          </span>
        );
      }

      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          style={{ marginRight: '10px' }}
        >
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
  };

  useEffect(() => {
    getTimelineById(selectedAllotment);
  }, [selectedAllotment]);
  return (
    <>
      <div className="flex justify-end mb-3 mt-2">
        <Button
          className="btn-cadastro"
          style={{
            float: 'right',
            margin: '10px',
          }}
          onClick={() => {
            setSelectedTimeline(null);
            toggleTimelineModal();
          }}
        >
          Abrir Cronograma
        </Button>
      </div>
      <DataTableStyle
        value={timeline}
        size="small"
        dataKey="id"
        paginator
        loading={loading}
        scrollable
        filterLocale="BR"
        showGridlines
        scrollDirection="horizontal"
        scrollHeight="70vh"
        rows={10}
        rowsPerPageOptions={[5, 10, 25]}
        paginatorTemplate={pagination}
        style={{ backgroundColor: colors.background }}
        backgroundColor={colors.container}
        backgroundButtonPaginate={colors.btn_color}
        colorButtonPaginate={colors.text}
        backgroundButtonPaginateActive={colors.icon_visu}
        colorButtonPaginateActive={'#FFF'}
        rowStripedColor={colors.stripedRow}
        rowsColor={colors.row}
        colorIcon={colors.secundary}
      >
        <Column
          sortable
          field="id"
          header="ID"
          bodyStyle={{ color: colors.text }}
          headerStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          style={{ minWidth: '5rem', maxWidth: '5rem' }}
        />
        <Column
          sortable
          header="Status"
          bodyStyle={{
            color: colors.text,
            display: 'flex',
            justifyContent: 'center',
          }}
          headerStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          style={{ minWidth: '10rem' }}
          body={status}
        />
        <Column
          sortable
          field="start_date"
          dataType="date"
          header="Data de Início"
          bodyStyle={{ color: colors.text }}
          headerStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          style={{ minWidth: '12rem' }}
        />
        <Column
          sortable
          field="finish_date"
          dataType="date"
          header="Data Final"
          bodyStyle={{ color: colors.text }}
          headerStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          style={{ minWidth: '12rem' }}
        />
        <Column
          header="Opções"
          bodyStyle={{ color: colors.text }}
          headerStyle={{
            backgroundColor: colors.background,
            color: colors.text,
          }}
          style={{ minWidth: '5rem', maxWidth: '7rem' }}
          body={options}
        />
      </DataTableStyle>
      {/* <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Status</th>
            <th>Data de Início</th>
            <th>Data Final</th>
            <th>Opções</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <div
              style={{
                position: 'absolute',
                left: '50%',
                right: '50%',
              }}
            >
              <ReactLoading type="bars" color="#36B37E" />
            </div>
          ) : (
            timeline?.map((item: any) => (
              <tr key={item?.id}>
                <td>{item?.id}</td>
                <td>
                  <Badge
                    color={
                      item?.status === 'Aberto' || item?.status === 'Em Aberto'
                        ? 'primary'
                        : item?.status === 'Aguardando Retorno'
                          ? 'secondary'
                          : item?.status === 'Em Andamento'
                            ? 'warning'
                            : 'success'
                    }
                  >
                    {item?.status === 'Aberto' || item?.status === 'Em Aberto'
                      ? 'Aberto'
                      : item?.status}
                  </Badge>
                </td>
                <td>{item?.start_date}</td>
                <td>{item?.finish_date}</td>
                <td
                  style={{
                    display: 'flex',
                    gap: '15px',
                    alignItems: 'center',
                    verticalAlign: 'center',
                  }}
                >
                  <Link
                    to={`/lotes/lote/cronograma/${item?.id}`}
                    state={item?.id}
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <i className="fa-solid fa-eye icon-visualizar" />
                  </Link>
                  <i
                    onClick={() => {
                      setSelectedTimeline(item);
                      toggleTimelineModal();
                    }}
                    className="fa-solid fa-pen icon-editar"
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table> */}
      <TimelineModal
        toggleTimelineModal={toggleTimelineModal}
        timelineModal={timelineModal}
        selectedAllotment={selectedAllotment}
        selectedTimeline={selectedTimeline}
        getTimelineById={getTimelineById}
      />
    </>
  );
};

export default TimelineList;
