/* eslint-disable */
// @ts-nocheck
import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ListGroup, Table } from 'reactstrap';
// import { DataTable } from "primereact/datatable";
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { addLocale, locale, FilterMatchMode } from 'primereact/api';
import ReactLoading from 'react-loading';
// import { TabMenu } from './style';
import Header from '../../components/Header';

import ServiceCallDefinitionModal from '../../components/ServiceCallDefModal';
import ServiceCallForwardingModal from '../../components/ServiceCallForwardingModal';
import api from '../../services/api';
import {
  ContentContainer,
  Label,
  ServiceCallListItem,
  TabButton,
  TabContainer,
  TabMenuSty,
  TextInfo,
  DataTableStyle,
} from './style';
import { useAuth } from '../../hooks/auth';
import { dateFormatter } from '../../utils/date_formatter';
import { ThemeContext } from 'styled-components';
import { Ripple } from 'primereact/ripple';
import { classNames } from 'primereact/utils';

const ServiceCallInfo = ({ showSidebar, setSidebar, toggleTheme }: any) => {
  const [data, setData] = useState<any>();
  const [referrals, setReferrals] = useState();
  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState();
  const [active, setActive] = useState(0);
  const [modal, setModal] = useState(false);
  const [modalForwarding, setModalForwarding] = useState(false);

  const { user } = useAuth();
  const { colors } = useContext(ThemeContext);

  const [allowedActions, setAllowedActions] = useState({
    acoes: {
      create: true,
      update: true,
      read: true,
      delete: true,
    },
  });

  const baseURL = `${process.env.REACT_APP_API_URL}/upload/`;

  const toggle = () => {
    setModal(!modal);
  };

  const toggleForwarding = () => {
    setModalForwarding(!modalForwarding);
  };

  const { id } = useParams<any>();

  const files = data?.service_call_files.map((file: any) => file);

  const toggleTab = (e: any) => {
    const index = parseInt(e.target.id, 0);
    if (index !== active) {
      setActive(index);
    }
  };

  async function loadReferrals() {
    try {
      setLoading(true);
      const { data } = await api.get(`/referrals/${id}`);
      setReferrals(data);
    } catch (error) {
      /* toast('Não foi possível carregar os encaminhamentos', {
        position: 'bottom-center',
      }); */
    } finally {
      setLoading(false);
    }
  }

  async function loadHistory() {
    try {
      setLoading(true);
      const { data } = await api.get(`/history/${id}`);
      setHistory(data);
    } catch (error) {
      /* toast('Não foi possível carregar o monitoramento', {
        position: 'bottom-center',
      }); */
    } finally {
      setLoading(false);
    }
  }

  async function loadServiceCall() {
    try {
      const { data } = await api.get(`/service-call/getServiceCallByID/${id}`);
      setData(data);
    } catch (error) {
      /* toast('Não foi possível carregar o chamado', {
        position: 'bottom-center',
      }); */
    } finally {
      setLoading(false);
    }
  }

  async function loadReferrals() {
    try {
      const { data } = await api.get(`/referrals/${id}`);
      setReferrals(data);
    } catch (error) {
      /* toast('Não foi possível carregar os encaminhamentos', {
        position: 'bottom-center',
      }); */
    } finally {
      // setLoading(false);
    }
  }

  async function loadHistory() {
    try {
      // eslint-disable-next-line prefer-const
      const { data } = await api.get(`/history/${id}`);
      const handlingData = data.map((item) => {
        console.log(new Date(item.createdAt));
        return {
          ...item,
          createdAt: new Date(item.createdAt),
        };
      });
      setHistory(handlingData);
    } catch (error) {
      /* toast('Não foi possível carregar o monitoramento', {
        position: 'bottom-center',
      }); */
    } finally {
      // setLoading(false);
    }
  }

  useEffect(() => {
    setLoading(true);

    if (user) {
      const temp = user?.profile?.functionalities
        ?.filter((func: any) => func.active && func.link == '/chamados')
        .map(({ acoes }: any) => ({ acoes }))[0];
      setAllowedActions(temp);
    }

    loadHistory();
    loadReferrals();
    loadServiceCall();
  }, [id, user]);

  addLocale('BR', {
    firstDayOfWeek: 1,
    dayNames: [
      'domingo',
      'segunda',
      'terça',
      'quarta',
      'quinta',
      'sexta',
      'sábado',
    ],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    monthNamesShort: [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ],
    today: 'Hoje',
    clear: 'Limpar',
    apply: 'Aplicar',
    addRule: 'Adicionar filtro',
    removeRule: 'Remover filtro',
    matchAll: 'Todos corresponderem',
    matchAny: 'Qualquer um corresponder',
  });
  locale('BR');

  const formatDate = (value) => {
    const date = value.length != 0 ? new Date(value) : new Date();
    return date.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };
  const dateBodyTemplate = (rowData) => {
    let data = new Date(rowData.createdAt);
    data = `${
      data.getHours().toLocaleString().length < 2
        ? `0${data.getHours()}`
        : data.getHours()
    }:${
      data.getMinutes().toLocaleString().length < 2
        ? `0${data.getMinutes()}`
        : `${data.getMinutes()}`
    }`;
    return `${formatDate(rowData.createdAt)} - ${data}`;
  };

  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        locale="BR"
        dateFormat="dd/mm/yy"
        placeholder="dd/mm/yyyy"
        mask="99/99/9999"
      />
    );
  };

  const items = [
    { label: 'Dados Gerais', icon: 'pi pi-fw pi-list' },
    { label: 'Encaminhamentos', icon: 'fa-solid fa-phone-volume' },
    { label: 'Monitoramento', icon: 'fa-regular fa-rectangle-list' },
  ];

  const pagination = {
    layout: 'PrevPageLink PageLinks NextPageLink',
    PrevPageLink: (options: any) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          style={{ borderRadius: '8px', color: colors.text }}
          disabled={options.disabled}
        >
          <span className="p-3">Anterior</span>
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options: any) => {
      return (
        <button
          type="button"
          className={options.className}
          style={{ borderRadius: '8px', color: colors.text }}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-3">Próximo</span>
          <Ripple />
        </button>
      );
    },
    PageLinks: (options: any) => {
      if (
        (options.view.startPage === options.page &&
          options.view.startPage !== 0) ||
        (options.view.endPage === options.page &&
          options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { 'p-disabled': true });

        return (
          <span
            className={className}
            style={{ userSelect: 'none', borderRadius: '3px' }}
          >
            ...
          </span>
        );
      }

      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          style={{ marginRight: '10px' }}
        >
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
  };

  return (
    <>
      <Header
        backHistoryPage
        title={`Chamado ${data?.id ? `#${data?.id}` : ''}`}
        showSidebar={showSidebar}
        setSidebar={setSidebar}
        toggleTheme={toggleTheme}
      />
      <div className="mt-2" style={{ backgroundColor: colors.container }}>
        <TabMenuSty
          columns="1fr 1fr 1fr"
          model={items}
          activeIndex={active}
          onTabChange={(e) => setActive(e.index)}
          backgroundColor={colors.container}
          color={colors.text}
        />
      </div>

      <div>
        {loading ? (
          <div
            style={{
              height: '50vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ReactLoading type="spin" height="5%" width="5%" color="#36B37E" />
          </div>
        ) : (
          <ContentContainer
            style={{
              border: '1px solid #DEE2E6',
              borderWidth: '0px 1.5px 1.5px 1.5px',
              fontFamily: 'Roboto',
              paddingTop: '.5rem',
              backgroundColor: colors.container,
              overflowY: 'scroll',
              maxHeight: '70vh',
            }}
            active={active === 0}
          >
            <ListGroup flush>
              <ServiceCallListItem backgroundColor={colors.container}>
                {data?.school ? (
                  <>
                    <Label color={colors.text}>Escola: </Label>
                    <TextInfo color={colors.text}>
                      {data?.school?.name}
                    </TextInfo>
                  </>
                ) : (
                  <>
                    <Label color={colors.text}>Instituição: </Label>
                    <TextInfo color={colors.text}>
                      {data?.institution?.name}
                    </TextInfo>
                  </>
                )}
              </ServiceCallListItem>
              <ServiceCallListItem backgroundColor={colors.container}>
                <Label color={colors.text}>Tipo de chamado: </Label>
                <TextInfo color={colors.text}>
                  {data?.classification === 'Aluguel'
                    ? `${data?.classification} (${data?.rent})`
                    : `${data?.classification}`}
                </TextInfo>
              </ServiceCallListItem>
              <ServiceCallListItem backgroundColor={colors.container}>
                <Label color={colors.text}>Descrição:</Label>

                <TextInfo color={colors.text}>
                  {data?.service_description}
                </TextInfo>
              </ServiceCallListItem>
              <ServiceCallListItem
                style={{ color: colors.text }}
                backgroundColor={colors.container}
              >
                <Label color={colors.text}>Anexo(s): </Label>
                {files?.length == 0
                  ? 'Sem anexo'
                  : files?.map((file) => (
                      <a
                        target="_blank"
                        href={baseURL + file.filename}
                        rel="noreferrer"
                        style={{ color: colors.secundary }}
                      >
                        {file.filename}
                      </a>
                    ))}
              </ServiceCallListItem>
              <ServiceCallListItem backgroundColor={colors.container}>
                <Label color={colors.text}>Status: </Label>

                <TextInfo
                  className="px-3"
                  style={{
                    backgroundColor:
                      data?.status == 'Em Andamento'
                        ? '#ffab00'
                        : data?.status == 'Aberto'
                        ? '#236cd9'
                        : data?.status == 'Aguardando Retorno'
                        ? '#6C757D'
                        : '#36B37E',
                    borderRadius: '100px',
                    color: '#FFF',
                  }}
                >
                  {data?.status}
                </TextInfo>
              </ServiceCallListItem>
              <ServiceCallListItem backgroundColor={colors.container}>
                <Label color={colors.text}>Prioridade: </Label>

                <TextInfo color={colors.text}>{data?.priority}</TextInfo>
              </ServiceCallListItem>
              <ServiceCallListItem backgroundColor={colors.container}>
                <Label color={colors.text}>Responsável: </Label>

                <TextInfo color={colors.text}>{data?.user?.full_name}</TextInfo>
              </ServiceCallListItem>
              <ServiceCallListItem backgroundColor={colors.container}>
                <Label color={colors.text}>Classificação: </Label>

                <TextInfo color={colors.text}>
                  {data?.service_call_type?.type || '-'}
                </TextInfo>
              </ServiceCallListItem>
              <ServiceCallListItem backgroundColor={colors.container}>
                <Label color={colors.text}>Subclassificação: </Label>

                <TextInfo color={colors.text}>
                  {data?.service_call_subtype?.subtype || '-'}
                </TextInfo>
              </ServiceCallListItem>
              <ServiceCallListItem backgroundColor={colors.container}>
                <Label color={colors.text}>Recurso: </Label>

                <TextInfo color={colors.text}>
                  {data?.allotment
                    ? data?.allotment?.name
                    : data?.resource?.name || '-'}
                </TextInfo>
              </ServiceCallListItem>
              <ServiceCallListItem backgroundColor={colors.container}>
                <Label color={colors.text}>Valor: </Label>

                <TextInfo color={colors.text}>{data?.value || '-'}</TextInfo>
              </ServiceCallListItem>
            </ListGroup>
            {allowedActions?.acoes.update &&
              (data?.status !== 'Finalizado' || user.institution_id === 1) && (
                <div
                  style={{
                    backgroundColor: colors.container,
                  }}
                  className="pb-4 px-4 flex justify-end gap-1"
                >
                  <Button
                    className="btn-atualizar border-0 px-2 text-md"
                    onClick={() => {
                      toggle();
                    }}
                    style={{
                      marginTop: '20px',
                      height: '40px',
                    }}
                  >
                    Definir chamado
                  </Button>

                  <Button
                    className="btn-adicionar border-0 px-2 text-md"
                    onClick={() => {
                      toggleForwarding();
                    }}
                    style={{
                      marginTop: '20px',
                      height: '40px',
                    }}
                  >
                    Encaminhar chamado
                  </Button>
                </div>
                // eslint-disable-next-line indent
              )}
          </ContentContainer>
        )}
        <ContentContainer active={active === 1}>
          <DataTableStyle
            value={referrals}
            stripedRows
            style={{ border: '1px solid #DEE2E6' }}
            tableStyle={{
              backgroundColor: colors.container,
              color: colors.text,
            }}
            size="small"
            dataKey="id"
            loading={loading}
            paginator
            scrollable
            scrollDirection="vertical"
            scrollHeight="60vh"
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate={pagination}
            backgroundColor={colors.container}
            backgroundButtonPaginate={colors.btn_color}
            colorButtonPaginate={colors.text}
            backgroundButtonPaginateActive={colors.icon_visu}
            colorButtonPaginateActive={'#FFF'}
            rowStripedColor={colors.stripedRow}
            rowsColor={colors.row}
            rowsColorText={colors.text}
            colorIcon={colors.secundary}
            emptyMessage="Nenhum dado encontrado..."
          >
            <Column
              header="Descrição"
              field="description"
              sortable
              // filter
              filterMatchMode="contains"
              showFilterMatchModes={false}
              showFilterMenuOptions={false}
              style={{ minWidth: '10rem' }}
              bodyStyle={{
                backgroundColor: colors.container,
                color: colors.text,
              }}
              headerStyle={{
                backgroundColor: colors.container,
                color: colors.text,
              }}
              filterMenuStyle={{
                backgroundColor: colors.container,
                color: colors.text,
              }}
              filterPlaceholder="Descrição"
            />
            <Column
              header="Tipo"
              field="referral_type.description"
              sortable
              // filter
              filterMatchMode="contains"
              showFilterMatchModes={false}
              showFilterMenuOptions={false}
              style={{ minWidth: '10rem' }}
              bodyStyle={{
                backgroundColor: colors.container,
                color: colors.text,
              }}
              headerStyle={{
                backgroundColor: colors.container,
                color: colors.text,
              }}
              filterMenuStyle={{
                backgroundColor: colors.container,
                color: colors.text,
              }}
              filterPlaceholder="Tipo"
            />
            <Column
              header="Origem"
              field="institution_origem.name"
              sortable
              // filter
              filterMatchMode="contains"
              showFilterMatchModes={false}
              showFilterMenuOptions={false}
              style={{ minWidth: '10rem' }}
              bodyStyle={{
                backgroundColor: colors.container,
                color: colors.text,
              }}
              headerStyle={{
                backgroundColor: colors.container,
                color: colors.text,
              }}
              filterMenuStyle={{
                backgroundColor: colors.container,
                color: colors.text,
              }}
              filterPlaceholder="Origem"
            />
            <Column
              header="Destino"
              field="institution.name"
              sortable
              // filter
              filterMatchMode="contains"
              showFilterMatchModes={false}
              showFilterMenuOptions={false}
              style={{ minWidth: '10rem' }}
              bodyStyle={{
                backgroundColor: colors.container,
                color: colors.text,
              }}
              headerStyle={{
                backgroundColor: colors.container,
                color: colors.text,
              }}
              filterMenuStyle={{
                backgroundColor: colors.container,
                color: colors.text,
              }}
              filterPlaceholder="Destino"
            />
          </DataTableStyle>
        </ContentContainer>
        <ContentContainer active={active === 2}>
          <DataTableStyle
            value={history}
            stripedRows
            style={{ border: '1px solid #DEE2E6' }}
            size="small"
            dataKey="id"
            paginator
            scrollable
            scrollDirection="vertical"
            scrollHeight="50vh"
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            backgroundColor={colors.container}
            backgroundButtonPaginate={colors.btn_color}
            colorButtonPaginate={colors.text}
            backgroundButtonPaginateActive={colors.icon_visu}
            colorButtonPaginateActive={'#FFF'}
            rowStripedColor={colors.stripedRow}
            rowsColor={colors.row}
            rowsColorText={colors.text}
            colorIcon={colors.secundary}
            paginatorTemplate={pagination}
            emptyMessage="Nenhum dado encontrado..."
            loading={loading}
          >
            <Column
              sortable
              field="user.full_name"
              header="Usuário"
              // filter
              showFilterMenu
              style={{ minWidth: '10rem' }}
              showFilterMatchModes={false}
              showFilterMenuOptions={false}
              bodyStyle={{
                backgroundColor: colors.container,
                color: colors.text,
              }}
              headerStyle={{
                backgroundColor: colors.container,
                color: colors.text,
              }}
              filterMenuStyle={{
                backgroundColor: colors.container,
                color: colors.text,
              }}
              filterPlaceholder="Nome do usuário"
              filterMatchMode="contains"
            />
            <Column
              sortable
              field="description"
              header="Ação"
              // filter
              showFilterMenu
              showFilterMatchModes={false}
              showFilterMenuOptions={false}
              style={{ minWidth: '10rem' }}
              bodyStyle={{
                backgroundColor: colors.container,
                color: colors.text,
              }}
              headerStyle={{
                backgroundColor: colors.container,
                color: colors.text,
              }}
              filterMenuStyle={{
                backgroundColor: colors.container,
                color: colors.text,
              }}
              filterPlaceholder="Ação"
              filterMatchMode="contains"
            />
            <Column
              sortable
              field="createdAt"
              header="Data e hora"
              // filter
              locale="BR"
              dataType="date"
              body={dateBodyTemplate}
              filterMatchModeOptions={[
                { label: 'Esta data', value: FilterMatchMode.DATE_IS },
                { label: 'Sem essa data', value: FilterMatchMode.DATE_IS_NOT },
                {
                  label: 'Antes dessa data',
                  value: FilterMatchMode.DATE_BEFORE,
                },
                {
                  label: 'Depois dessa data',
                  value: FilterMatchMode.DATE_AFTER,
                },
              ]}
              filterElement={dateFilterTemplate}
              style={{ minWidth: '10rem' }}
              bodyStyle={{
                backgroundColor: colors.container,
                color: colors.text,
              }}
              headerStyle={{
                backgroundColor: colors.container,
                color: colors.text,
              }}
              filterMenuStyle={{
                backgroundColor: colors.container,
                color: colors.text,
              }}
            />
          </DataTableStyle>
        </ContentContainer>
      </div>
      <ServiceCallDefinitionModal
        modal={modal}
        toggle={toggle}
        loadServiceCall={loadServiceCall}
        service_call_id={id}
        allowedActions={allowedActions}
      />
      <ServiceCallForwardingModal
        modal={modalForwarding}
        toggle={toggleForwarding}
        loadReferrals={loadReferrals}
        loadHistory={loadHistory}
        service_call_id={id}
        allowedActions={allowedActions}
      />
    </>
  );
};

export default ServiceCallInfo;
