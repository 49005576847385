/* eslint-disable */
import React, { useState } from 'react';
import { Form, Input, InputGroup, ModalBody } from 'reactstrap';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import api from '../../services/api';
import { ThemeContext } from 'styled-components';

const EditSubtypeModal = ({
  openSubtypeModal,
  subtypeModal,
  selectedSubtype,
  loadSubtypes,
}: any) => {
  const [subtype, setSubtype] = useState('');

  const { colors } = React.useContext(ThemeContext);

  function editSubtype() {
    api
      .patch(`/service-call-subtype/${selectedSubtype.id}`, { subtype })
      .then(loadSubtypes());
  }

  return (
    <>
      <Dialog
        className="w-50"
        header="Editar Subtipo de Chamado"
        visible={subtypeModal}
        onHide={openSubtypeModal}
        headerStyle={{ backgroundColor: colors.container, color: colors.text }}
        contentStyle={{ backgroundColor: colors.container, color: colors.text }}
      >
        <ModalBody>
          <Form onSubmit={(e) => e.preventDefault()}>
            <InputGroup className="mt-2 px-2">
              <Input
                className="h-12"
                type="text"
                value={subtype}
                onChange={(e) => setSubtype(e.target.value)}
                placeholder="Editar o subtipo"
              />
            </InputGroup>
            <div className="mt-4 flex justify-end">
              <Button
                className="btn-atualizar font-bold"
                onClick={() => {
                  editSubtype();
                  openSubtypeModal();
                }}
                label="Editar"
              />
            </div>
          </Form>
        </ModalBody>
      </Dialog>
    </>
  );
};

export default EditSubtypeModal;
