import React, { useState } from 'react';
import { Button, Table } from 'reactstrap';
import { Eye } from '../../assets/svg/Eye';
import { IEffort } from '../../types/IEffort';
import EffortModal from '../EffortModal';

const EffortList = ({ allotment, getAllotment }: any) => {
  const [modal, setModal] = useState(false);
  const [selectedEffort, setSelectedEffort] = useState<IEffort | null>();
  const toggle = () => {
    setModal(!modal);
  };

  return (
    <>
      <Button
        color="primary"
        style={{
          marginBottom: '10px',
        }}
        onClick={() => {
          setSelectedEffort(null);
          toggle();
        }}
      >
        Cadastrar Empenho
      </Button>
      <Table striped>
        <tbody>
          {
            allotment?.effort?.map((item: IEffort) => (
              <tr key={item.id}>
                <td>{item.value}</td>
                <td
                  onClick={() => {
                    setSelectedEffort(item);
                    toggle();
                  }}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <Eye />
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
      <EffortModal modal={modal} toggle={toggle} allotmentId={allotment?.id} getAllotment={getAllotment} selectedEffort={selectedEffort} />
    </>
  );
};

export default EffortList;
