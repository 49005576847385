import React from 'react';
import Header from '../../components/Header';

const ReportsPage = ({showSidebar, setSidebar, toggleTheme}: any) => (
  <>
    <Header title="Relatorios" showSidebar={showSidebar}
      setSidebar={setSidebar}
      toggleTheme={toggleTheme}/>

    <h1
      style={{
        textAlign: 'center',
        marginTop: '40px',
        textTransform: 'uppercase',
      }}
    >
      Página em desenvolvimento ...
    </h1>
  </>
);

export default ReportsPage;
