/* eslint-disable */
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Form, Input, Label } from 'reactstrap';
import { ThemeContext } from 'styled-components';
import api from '../../services/api';

const INITIAL_STATE = {
  allotment_id: null || 0,
  start_date: '',
  finish_date: '',
  status: '',
};

const TimelineModal = ({
  selectedTimeline,
  timelineModal,
  toggleTimelineModal,
  selectedAllotment,
  getTimelineById,
}: any) => {
  const [data, setData] = useState(INITIAL_STATE);

  const { colors } = React.useContext(ThemeContext);

  function handleSubmit() {
    toast
      .promise(api.post(`/timeline`, data), {
        pending: 'Cadastrando cronograma...',
        error: 'Ocorreu um erro ao cadastrar o cronograma',
        success: 'Cronograma cadastrado com sucesso',
      })
      .then(toggleTimelineModal())
      .then(() => {
        getTimelineById(Number(selectedAllotment?.allotment_id));
      });
  }

  function updateTimeline() {
    if (selectedTimeline) {
      toast
        .promise(api.patch(`/timeline/${selectedTimeline?.id}`, data), {
          pending: 'Cadastrando cronograma...',
          error: 'Ocorreu um erro ao cadastrar o cronograma',
          success: 'Cronograma cadastrado com sucesso',
        })
        .then(toggleTimelineModal())
        .then(() => {
          getTimelineById(Number(selectedAllotment?.allotment_id));
        });
    }
  }

  useEffect(() => {
    if (selectedAllotment) {
      setData({ ...data, allotment_id: Number(selectedAllotment) });
    }
  }, [selectedAllotment]);
  useEffect(() => {
    if (selectedTimeline) {
      setData({
        ...data,
        finish_date: selectedTimeline?.finish_date,
        start_date: selectedTimeline?.start_date,
        status: selectedTimeline?.status,
        allotment_id: Number(selectedTimeline?.allotment_id),
      });
    } else {
      setData(INITIAL_STATE);
    }
  }, [selectedTimeline]);
  return (
    <>
      <Dialog
        header="Cadastro de cronograma"
        visible={timelineModal}
        onHide={toggleTimelineModal}
        contentStyle={{ backgroundColor: colors.container, color: colors.text, minWidth: "340px", width: "40vw" }}
        headerStyle={{ backgroundColor: colors.container, color: colors.text, minWidth: "340px", width: "40vw" }}
      >
        <Form onSubmit={(e) => e.preventDefault()}>
          <Label>Data de Início:</Label>
          <Input
            type="date"
            onChange={(e) => setData({ ...data, start_date: e.target.value })}
            value={data?.start_date}
          />
          <Label>Data Final:</Label>
          <Input
            type="date"
            onChange={(e) => setData({ ...data, finish_date: e.target.value })}
            value={data?.finish_date}
          />
          <Label>Status:</Label>
          <Input
            value={data?.status}
            type="select"
            defaultValue={selectedTimeline?.status || null}
            onChange={(e) => setData({ ...data, status: e.target.value })}
          >
            <option value={selectedTimeline?.status || null}>
              {selectedTimeline?.status || null}
            </option>
            <option value="Em Aberto">Em Aberto</option>
            <option value="Em Andamento">Em Andamento</option>
            <option value="Aguardando Retorno">Aguardando Retorno</option>
            <option value="Finalizado">Finalizado</option>
          </Input>
          <div className='flex flex-row-reverse justify-between mt-4'>
            {!selectedTimeline ? (
              <Button
                label="Cadastrar"
                className="btn-cadastro"
                onClick={handleSubmit}
              />
            ) : (
              <Button
                label="Alterar"
                className="btn-atualizar"
                onClick={updateTimeline}
              />
            )}
          </div>
        </Form>
      </Dialog>
    </>
  );
};

export default TimelineModal;
