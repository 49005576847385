import React from "react";
import Header from "../../components/Header";

const UserConfigPage = () => (
  <>
    <Header title="Configurações de Usuário" />
  </>
);

export default UserConfigPage;
