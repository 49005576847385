/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Form, Input, Label } from 'reactstrap';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { InstitutionContext } from '../../context/institutionContext';
import api from '../../services/api';
import ReferralTable from '../ReferralTypeTable';

const InstitutionModal = ({
  allowedActions,
  selectedInstitution,
  modal,
  toggle,
}: any) => {
  const [name, setName] = useState('');

  const { institutions, setInstitutions, getInstitutions } =
    useContext(InstitutionContext);

  async function updateInstitution() {
    toast
      .promise(api.patch(`/institution/${selectedInstitution.id}`, { name }), {
        pending: 'Alterando...',
        success: 'Órgão alterado com sucesso',
        error: 'Ocorreu um erro ao alterar o órgão',
      })
      .then(() => {
        getInstitutions();
      });
  }
  async function handleSubmit() {
    toast
      .promise(api.post('/institution', { name }), {
        pending: 'Cadastrando...',
        success: 'Órgão cadastrado com sucesso',
        error: 'Ocorreu um erro ao cadastrar o órgão',
      })
      .then(({ data }) => {
        getInstitutions();
        setInstitutions([...institutions, { ...data.data }]);
      });
    toggle();
  }

  useEffect(() => {
    if (selectedInstitution == null) {
      setName('');
    } else {
      setName(selectedInstitution.name);
    }
  }, [selectedInstitution]);

  const footer = (
    <div>
      {selectedInstitution.length === 0 ? (
        <Button
          label="Cadastrar"
          className="btn-cadastro"
          onClick={handleSubmit}
        />
      ) : (
        <Button
          label="Alterar"
          className="btn-atualizar"
          onClick={updateInstitution}
        />
      )}
    </div>
  );

  return (
    <>
      <Dialog
        footer={footer}
        className="w-50"
        header="Cadastro de orgão"
        visible={modal}
        onHide={toggle}
      >
        <Form onSubmit={e => e.preventDefault()}>
          <Label>Nome</Label>
          <Input
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </Form>
        {selectedInstitution && (
          <div className="mt-5">
            <ReferralTable
              getInstitutions={getInstitutions}
              allowedActions={allowedActions}
              selectedInstitution={selectedInstitution}
            />
          </div>
        )}
      </Dialog>
    </>
  );
};

export default InstitutionModal;
